*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}


button:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}




a {
  text-decoration: none !important;
  cursor: pointer !important;
  color: #0086ff;
}

ol,
ul {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

a:visited {
  color: #0086ff;
}

::selection {
  background-color: #f89927;
  color: #fff;
}

input:focus {
  border: 1px solid #4671C6 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  outline: none !important;
}

input:disabled {
  background: #f1f0f0 !important;
  opacity: .6;
}

textarea:disabled {
  background: #f1f0f0 !important;
  opacity: .6;
}

.select:disabled {
  background: #f1f0f0 !important;
  opacity: .6;
}

button:focus {
  outline: none;
}

.box-shadow-none {
  box-shadow: none !important;
}

* {
  letter-spacing: 0;
}

.w-100 {
  width: 100% !important;
}

a {
  text-decoration: none;
}

.MuiRadio-root {
  color: #0095da !important;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #2f2f2f;
  box-sizing: border-box;
  /* background-color: #fff !important; */
  background-color: #f0f7f7 !important; 
}

.font-family-monetserrat {
  font-family: 'Montserrat', sans-serif !important;
}

/********************************
********Common Classes***********/


.btn {
  display: inline-block !important;
  font-weight: 600;
  color: #2f2f2f;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 12px 16px;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  border-radius: 6px;
  font-family: 'montserrat', sans-serif;
  transition: color .15s ease-in-out,
  background-color .15s ease-in-out,
  border-color .15s ease-in-out,
  box-shadow .15s ease-in-out;
}
.scrollable-div__p1{
  height: 50vh;
  overflow-y: scroll;
}

.btn-sm {
  padding: 5px 12px;
  font-size: 12px;
  border-radius: 6px;
}


.btn-primary {
  background-color: #0086FF;
  color: #fff !important;
  border-color: #0086FF !important;
}

.btn-primary:hover {
  color: #fff;
  background-color: #03234D !important;
  border-color: #03234D !important;

}
.btn-primary-orange {
  background-color: #fea111;
  color: #fff !important;
  border-color: #fea111 !important;
}

.btn-primary-orange:hover {
  color: #fff;
  background-color: #3c2603 !important;
  border-color: #3c2603 !important;

}
.btn-pagintaion{
  background-color: #F5FAFF;
  color:#124d9b
}
.btn-pagintaion:hover{
  background-color: #124d9b;
  color:#fff
}

.btn-primary-v2 {
  color: #fff !important;
  background-color: #3e73ec !important;
  border-color: #3e73ec !important;
  font-weight: 500;
}

.btn-primary-v2 span[ class^="flaticon"] {
  color: #fff !important;
  margin: 0px 5px 0px 0px;
  vertical-align: middle;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.color-btn-new {
  color: #124D9B;
}

.cursor-pointer {
  cursor: pointer;
}

.color-blue-new {
  background-color: #0086FF !important;
}

.color-blue-new:hover {
  background-color: #03234D !important;
}

.divider{
  height: 1px;
  width: 100%;
  background-color: #f4f4f4;
}

.color-navy {
  background-color: #03234D !important;
}

.color-orange-btn {
  background-color: #FEA111 !important;
}

.color-orange-btn:hover {
  background-color: #EE940A !important;
}

.btn-danger {
  color: #fff !important;
  background-color: #ff4747 !important;
  border-color: #ff4747 !important;
}

.btn-danger:hover {
  color: #fff;
  background-color: #bd2d3c;
  border-color: #bd2d3c;
}


.btn-danger .MuiSvgIcon-root {
  vertical-align: text-bottom;
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn:focus,
.btn:hover {
  text-decoration: none;
}

.btn-secondary-outline {
  color: #6c757d !important;
  background-color: transparent !important;
  border-color: #6c757d !important;
}

.btn-secondary-outline:hover {
  background-color: #000 !important;
  border-color: #000 !important;
  color: #fff !important;
}

.btn-outline-grey {
  border-color: #cbcbcb !important;
  background-color: transparent !important;
  color: #2F2F2F !important;
}

.btn-outline-grey:hover {
  background-color: #F4F4F4 !important;
  border-color: #cbcbcb !important;
  color: #2F2F2F !important;
}
.btn-outline-grey.btn-disabled{
  color: #d3d3d3!important;
  border: 1px solid #d3d3d3!important;
}
.btn-outline-grey.btn-disabled:hover{
  cursor: not-allowed;
  border: 1px solid #d3d3d3!important;
  background-color: #d3d3d3!important;
  color: #fff!important;
}

.btn-outline-green {
  border-color: #0db79b !important;
  background-color: transparent !important;
  color: #0db79b !important;
}

.btn-outline-green:hover {
  border-color: #0db79b !important;
  background-color: #0db79b !important;
  color: #fff !important;
}

.btn-outline-red {
  color: #ff4747 !important;
  background-color: transparent !important;
  border-color: #ff4747 !important;
}

.btn-outline-red:hover {
  color: #fff !important;
  background-color: #ff4747 !important;
  border-color: #ff4747 !important;
}

.btn-outline-light {
  color: #fff !important;
  background-color: transparent !important;
  border-color: #fff !important;
}

.btn-outline-light:hover {
  color: #000 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

.btn-icon-flat {
  background-color: #f8f8f8 !important;
  color: #0096db;
  border-color: #dedede !important;
  padding: 5px 0px;
  position: relative;
}

.btn-icon-flat:hover {
  background-color: #e0dfdf !important;
}

.btn-outline-primary {
  border-color: #0086ff !important;
  color: #0086ff;
  background-color: transparent;
}
.btn-outline-primary:hover {
  border-color: #0086ff !important;
  color: #fff;
  background-color: #0086ff;
}

.btn-outline-primary:hover {
  border-color: #0086ff !important;
  color: #fff;
  background-color: #0086ff !important;
}


.btn-outline-dark-grey {
  border-color: #7f8389 !important;
  color: #454b54;
  background-color: transparent;
}

.btn-delete {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
}

.btn-delete:hover {
  background-color: #e0dfdf !important;
  color: #e9797e !important
}

.btn-delete:hover .MuiSvgIcon-root {
  color: #e9797e !important
}

.btn-cancel-blue {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 84px;
  border: 1px solid #9DC0EE;
  background: transparent;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.63px;
  padding: 8px 16px 8px 16px;
  color: #124D9B;
}

.btn-cancel-blue:hover {
  color: white;
  background-color: #0086FF;
}

.trigger-deploy-btn {
  color: white;
  border: none;
  justify-content: center;
  height: 40px;
  width: 168px;
  border-radius: 6px;
  background: #0086FF;
  gap: 6px;
  font-size: 12px;
  padding: 11px 16px 11px 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  

}

.trigger-deploy-btn:hover {
  color: white;
  background-color: #03234D;

}

.btn-hollow {
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #DBDBDB;
  background: #FFF;
}

.btn-hollow-delete:hover {
  background: #C11212;
}

.btn-hollow-delete:hover img {
  filter: brightness(0) invert(1);
}

.blue124D9B {
  color: #124D9B !important;
}

.btn-hollow-action:hover {
  background: #124D9B;
}

.btn-hollow-action:hover img {
  filter: brightness(0) invert(1);
}

.color-bold-red {
  color: #C11212 !important;
}

.f0n .m-0 {
  margin: 0px !important;
}

.container-margin {
  margin: 20px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}
.mb-6 {
  margin-bottom: 5px !important;
}


.mb-8 {
  margin-bottom: 8px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-22 {
  margin-bottom: 2.2rem !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-48 {
  margin-bottom: 48px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.ml-3 {
  margin-left: 3px!important;
}

.ml-4 {
  margin-left: 4px;
}

.ml-2 {
  margin-left: 2px;
}

.ml-5 {
  margin-left: .5rem!important;
}

.ml-6 {
  margin-left: 6px !important;
}

.mr-6 {
  margin-right: 6px !important;
}

.mr-30 {
  margin-right: 30px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-10 {
  margin-left: 1rem;
}

.ml-12 {
  margin-left: 12px;
}

.ml-15 {
  margin-left: 1.5rem;
}

.ml-16 {
  margin-left: 16px;
}

.ml-20 {
  margin-left: 2rem !important;
}

.mr-2 {
  margin-right: .2rem;
}

.mr-3 {
  margin-right: .3rem;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 1px !important;
}

.mr-5 {
  margin-right: .5rem;
}

.mr-8 {
  margin-right: .8rem;
}

.mr-10 {
  margin-right: 1rem;
}

.mr-12 {
  margin-right: 12px;
}

.mr-15 {
  margin-right: 1.5rem;
}

.mr-20 {
  margin-right: 2rem;
}

.m-20 {
  margin: 20px;
}

.mt-2 {
  margin-top: 2px !important
}

.mt-5 {
  margin-top: 5px !important
}

.mt-8 {
  margin-top: 8px !important
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-32 {
  margin-top: 32px !important
}

.m-rl-20 {
  margin: 0px 20px;
}

.mtb-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mtb-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mtb-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.gap-5 {
  gap: 5px !important;
}

.margin-top-bottom-3px {
  margin: 2px 0px;
}

.color-header-new {
  color: #2F2F2F !important;
}

.color-subheader-new {
  color: #505050 !important;
}

.font-style-normal {
  font-style: normal !important;
}

.line-height-normal {
  line-height: normal !important;
}

/********************************
********Common Classes Ends Here***********/
.primaryHeading {
  font-size: 20px;
  color: black;
  font-weight: 300;
}

.sidenavWizzard {
  background-color: #fff;
}

.MuiTab-root {
  margin: 15px 10px !important;
  border-radius: 30px 30px 30px 30px !important;
  background-color: #f8f8f8 !important;
}

.makeStyles-root-54 {
  /* border-right: 1px solid #b7b7b7; */
}

.PrivateTabIndicator-colorSecondary-179 {
  background-color: #fff !important;
}

.MuiTab-root.Mui-selected {
  margin: 15px 0px !important;
  border-radius: 30px 0px 0px 30px !important;
  background-color: #fff !important;
}

.sidenavWizzard>.count {
  width: 30px;
  height: 30px;
  border: 1px solid #000;
  text-align: center;
  margin: auto;
  border-radius: 50%;
  padding-top: 3px;
}

/*Top nav*/
.makeStyles-MuiAvatarColorDefault-7 {
  color: #fff;
  width: 40px !important;
  height: 40px !important;
  font-weight: 800;
  font-size: 16px;
  background-color: #6d84a1;
}

.MuiIconButton-root {
  padding: 6px !important;
}

.MuiCheckbox-root {
  padding: 5px !important;
}

span.MuiTab-wrapper {
  height: 30px !important;
  width: 30px !important;
  border: 1px solid #ccc !important;
  border-radius: 50% !important;
}

input {
  display: block;
  margin: 0px;
  position: relative;
  width: 100%;
  line-height: 2;
  border-radius: 4px;
  border: 1px solid #b7b7b7;
  background-color: #fff;
  padding: 5px 14px;
  color: #5e5e5e;
  font-size: 12px;
  font-weight: 400;
  /* max-height: 50px; */
}

.border-outline-none {
  border: none;
  outline: none;
}

.height-20 {
  height: 20px !important;
}

.width-20 {
  width: 20px !important;
}


input::placeholder {
  color: #D1CCCC !important;
}

textarea::placeholder {
  color: #D1CCCC !important;
}

.purple-text {
  color: #c294f1;
}

.purple-text .flaticon-thumb-up-button {
  color: #c294f1;
  margin: 0px 5px;
}

.MuiPaper-outlined {
  border: 1px solid #b7b7b7 !important;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.input-chip-checkbox input[type="checkbox"],
.input-square-checkbox input[type="checkbox"] {
  display: none;
}

.input-chip-checkbox {
  padding-top: 10px;
}

.input-component-mb-0 .input-component {
  margin-bottom: 0px !important;
}

.input-component-dcoker-mb-0 .input-component {
  margin-bottom: 0px !important;
  width: fit-content !important;
}

.input-component-pb-0 .input-component {
  padding-bottom: 0px !important;
}

.input-chip-checkbox input[type="checkbox"]+label {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-size: 12px;
  cursor: pointer;
  border-radius: 20px;
  color: #0086ff;
  background-color: #fff;
  /* border:solid 2px #7d7d7d; */
  padding: 4px 12px;
  display: inline-block;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 2px;
  text-align: center;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 13%);

}

.input-chip-checkbox input[type="checkbox"]:checked+label {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  background-color: #0086ff;
  color: white;
}

/* input[type="checkbox"]{
    display:inline!important;
} */
.checkbox-grid {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 13%);
}

.checkbox-grid .input-component {
  margin-bottom: 0px;

}

.checkbox-grid .input-square-checkbox {
  padding-top: 0px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
}

.checkbox-grid .input-square-checkbox .input-checkbox-group {
  border-right: 1px solid #cccccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 10px 5px;
  width: 110px;
  cursor: pointer;
}

.input-switch-f8 .card-header {
  background-color: #f8f8f8 !important;
  width: 182px;
}

.checkbox-grid .input-square-checkbox .input-checkbox-group .input-count {
  padding: 3px 2px;
  color: #888787;
  display: inline-block;
  /* margin-right: 3px;
	margin-left: 3px; */
}

.checkbox-grid .input-square-checkbox .input-checkbox-group.bg-success .input-count {
  color: #fff;
}

.checkbox-grid .input-square-checkbox .input-checkbox-group.bg-failed .input-count {
  color: #fff;
}

.checkbox-grid .input-square-checkbox .input-checkbox-group.bg-initiated .input-count {
  color: #fff;
}

.checkbox-grid .input-square-checkbox .input-checkbox-group.bg-revoked .input-count {
  color: #fff;
}

.checkbox-grid .input-square-checkbox .input-checkbox-group.bg-running .input-count {
  color: #fff;
}

.input-checkbox-group .material-icons {
  font-size: 20px;
  vertical-align: middle;
}

.input-checkbox-group .success-mi {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #54f198;
}

.input-checkbox-group .success-mi .material-icons {
  color: #fff;
}

.input-checkbox-group.bg-success .success-mi {
  background-color: #fff
}

.input-checkbox-group.bg-success .success-mi .material-icons {
  color: #54f198;
}

.input-checkbox-group .failed-mi {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fb4747;
}

.input-checkbox-group .failed-mi .material-icons {
  color: #fff;
}

.input-checkbox-group.bg-failed .failed-mi {
  background-color: #fff
}

.input-checkbox-group.bg-failed .failed-mi .material-icons {
  color: #fb4747;
}

.input-checkbox-group .running-mi {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0086ff;
}

.input-checkbox-group .running-mi .material-icons {
  color: #fff;
}

.input-checkbox-group.bg-running .running-mi {
  background-color: #fff
}

.input-checkbox-group.bg-running .running-mi .material-icons {
  color: #0086ff;
}

.input-checkbox-group .initiated-mi {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #004085;
}

.input-checkbox-group .initiated-mi .material-icons {
  color: #fff;
}

.input-checkbox-group.bg-initiated .initiated-mi {
  background-color: #fff;
}

.input-checkbox-group.bg-initiated .initiated-mi .material-icons {
  color: #004085;
}

.input-checkbox-group .revoked-mi {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c294f1;
}

.input-checkbox-group .revoked-mi .material-icons {
  color: #fff;
}

.input-checkbox-group.bg-revoked .revoked-mi {
  background-color: #fff;
}

.input-checkbox-group.bg-revoked .revoked-mi .material-icons {
  color: #c294f1;
}

.input-checkbox-group .success-material-icon .material-icons {
  color: #54f198;
}

.checkbox-grid .input-square-checkbox .input-checkbox-group:last-child {
  border-right: 0px !important;
}

.input-square-checkbox input[type="checkbox"]+label {
  width: 25px;
  height: 25px;
  padding: 0px !important;
  border-radius: 19px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;

}

.input-square-checkbox input[type="checkbox"].success+label {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 12px;
  display: inline-block;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 2px;
  text-align: center;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #fff;
  color: #69e09c;
}

.input-square-checkbox input[type="checkbox"].success:checked+label {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: #69e09c;
  background-color: #fff;
  border: solid 2px #fff;
}

.input-square-checkbox input[type="checkbox"].revoked+label {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 12px;
  display: inline-block;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 2px;
  text-align: center;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #fff;
  color: #c294f1;
}

.input-square-checkbox input[type="checkbox"].revoked:checked+label {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: #c294f1;
  background-color: #fff;
  border: solid 2px #fff;
}

.input-square-checkbox input[type="checkbox"]:checked+.input-checkbox-group {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  background-color: #0086ff;
  color: white;
}

.input-square-checkbox input[type="checkbox"].failed+label {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 12px;
  display: inline-block;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 2px;
  text-align: center;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #fff;
  color: #ff5252;
}

.input-square-checkbox input[type="checkbox"].failed:checked+label {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: #ff5252;
  background-color: #fff;
  border: solid 2px #fff;
}

.input-square-checkbox input[type="checkbox"].running+label {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 12px;
  display: inline-block;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 2px;
  text-align: center;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #fff;
  color: #0086ff;
}

.input-square-checkbox input[type="checkbox"].running:checked+label {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: #0086ff;
  background-color: #fff;
  border: solid 2px #fff;
}

.input-square-checkbox input[type="checkbox"].initiated+label {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 12px;
  display: inline-block;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 2px;
  text-align: center;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #fff;
  color: #004085;
}

.input-square-checkbox input[type="checkbox"].initiated:checked+label {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: #004085;
  background-color: #fff;
  border: solid 2px #fff;
}

.formControll .MuiFormControlLabel-label {
  font-size: 14px !important;
}

.MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 14px !important;
  font-weight: 500;
  color: #2f2f2f;
}

.Mui-disabled {
  background-color: #f1f0f0 !important;
}

.border-none {
  border: none !important
}

.br-0 {
  border-radius: 0px !important;
}

.br-4 {
  border-radius: 4px !important;
}

.br-8 {
  border-radius: 8px !important;
}

.border-2px-blue {
  border: 2px solid #0086ff !important
}

.border-bottom {
  border-bottom: 1px solid #dedede !important;
}

.border-bottom:last-child {
  border-bottom: none !important;
}

.border-top {
  border-top: 1px solid #dedede;
}

.border-left {
  border-left: 1px solid #dedede;
}


/*Stepper css*/
.space-minimum {
  display: flex;
  width: auto !important;
  margin: 0 !important;
  background-color: #f6f8f8;
}

.md-pd-40 {
  padding: 24px 40px !important;
}

.md-stepper-horizontal {
  display: flex;
  width: 100% !important;
  margin: 0 auto;
  background-color: #f6f8f8;
}

.md-stepper-wrapper .md-stepper-horizontal {
  display: flex;
  width: 80% !important;
  margin: 0 auto;
  background-color: #f6f8f8;
}

.md-stepper-horizontal .md-step {
  width: inherit;
  position: relative;
  padding: 24px;
}

.md-stepper-horizontal .md-step:active {
  border-radius: 15% / 75%;
}

.md-stepper-horizontal .md-step:first-child:active {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.md-stepper-horizontal .md-step:last-child:active {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.md-stepper-horizontal .md-step:hover .md-step-circle {
  background-color: #f9f9f9;
}

.md-stepper-horizontal .md-step:first-child .md-step-bar-left,
.md-stepper-horizontal .md-step:last-child .md-step-bar-right {
  display: none;
}

.md-stepper-horizontal .md-step:first-child .md-step-bar-logs-left,
.md-stepper-horizontal .md-step:last-child .md-step-bar-logs-right {
  display: none;
}

.md-stepper-horizontal .md-step .md-step-circle {
  width: 30px;
  height: 30px;
  margin: 0 auto;
  border: 2px solid#ccc;
  border-radius: 50%;
  text-align: center;
  font-size: 9px;
  font-weight: 400;
  color: #000;
}

.md-stepper-horizontal .md-step.active:hover {
  cursor: pointer;
}

.md-stepper-horizontal .md-step.active .md-step-circle.disable {
  border: 3px solid #80808096;
}

.md-stepper-horizontal .md-step.active .md-step-circle.current {
  border: 3px solid #0096db;
}

.md-stepper-wrapper .md-stepper-horizontal .md-step.active .md-step-circle.current {
  border: 3px solid transparent;
}


.md-stepper-horizontal .md-step.active .md-step-circle.success {
  border: 3px solid #62E187;
}

.md-stepper-horizontal .md-step.active .md-step-circle.skipped {
  border: 3px solid #AEAEAE;
}

.md-stepper-horizontal .md-step.active .md-step-circle.fail {
  border: 3px solid #ff8969!important;
  border: 0;
}

.md-stepper-horizontal .md-step.active .md-step-circle.log-fail {
  border: 0;
}

.md-stepper-horizontal .md-step.active .md-step-circle.log-success {
  border: 0;
}

.md-stepper-horizontal .md-step.active .md-step-circle.log-running {
  border: 0;
}

.md-stepper-horizontal .md-step.active .md-step-circle.log-revoked {
  border: 0;
}


.md-stepper-horizontal .md-step.active .md-step-circle.purple {
  border: 3px solid #c294f1;
}

.md-stepper-horizontal .md-step.active .md-step-circle.yellow {
  border: 3px solid #ffc436;
}

.md-stepper-horizontal .md-step.active .md-step-circle.default {
  border: 3px solid #0096db;
}

.md-stepper-horizontal .md-step.active .md-step-circle.running {
  border: 3px solid #0086ff;
}

.md-stepper-horizontal .md-step.active .md-step-circle.stopped {
  border: 3px solid #e9797e;
}

.md-stepper-horizontal .md-step.active .md-step-circle.running-only {
  border: 3px solid #0088ff57;
  border-top-color: #0086ff;
  animation: single-div-spin 1s infinite linear;
}

.md-stepper-wrapper .md-stepper-horizontal .md-step.active .md-step-circle{
  border: 3px solid transparent;
}

.md-stepper-horizontal .md-step.active .md-step-circle {
  border: 3px solid #e7e7e7;
}

.md-stepper-horizontal .md-step.active .md-step-circle.pending {
  border: 3px solid #ff8787;
}

.md-stepper-horizontal .md-step.active .md-step-circle.revoked {
  border: 3px solid #c294f1;
}


.md-step-circle .flaticon-warning-sign {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff8787 !important;
  height: 100%;
  margin: 0;
}

.md-step-circle .flaticon-schedule-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffc436 !important;
  height: 100%;
  margin: 0;
}

.md-step-circle .flaticon-thumb-up-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c294f1 !important;
  height: 100%;
  margin: 0;
}

.md-step-circle .flaticon-warning-sign::before {
  font-size: 14px !important;
}

.md-stepper-horizontal .md-step.done .md-step-circle:before {
  font-family: 'FontAwesome', sans-serif;
  font-weight: 100;
  content: "\f00c";
}

.md-stepper-horizontal .md-step.done .md-step-circle *,
.md-stepper-horizontal .md-step.editable .md-step-circle * {
  display: none;
}

.md-stepper-horizontal .md-step.editable .md-step-circle {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.md-stepper-horizontal .md-step .md-step-title {
  margin-top: 5px;
  font-size: 11px;
  font-weight: 400;
}

.md-stepper-horizontal .md-step .md-step-title,
.md-stepper-horizontal .md-step .md-step-optional {
  text-align: center;
  color: rgba(0, 0, 0, .26);
}

.md-stepper-horizontal .md-step.active .md-step-title {
  font-weight: 400;
  line-height: 1.2;
  color: rgba(0, 0, 0, .87);
}

.md-stepper-horizontal .md-step.active.done .md-step-title,
.md-stepper-horizontal .md-step.active.editable .md-step-title {
  font-weight: 400;
}

.md-stepper-horizontal .md-step .md-step-optional {
  font-size: 9px;
}

.md-stepper-horizontal .md-step.active .md-step-optional {
  color: rgba(0, 0, 0, .54);
}

.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 5px solid #e7e7e7;
}

.md-stepper-wrapper .md-stepper-wrapper .md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-wrapper .md-stepper-wrapper .md-stepper-horizontal .md-step .md-step-bar-right {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 3px solid #cacaca;
}

.md-stepper-wrapper .md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-wrapper .md-stepper-horizontal .md-step .md-step-bar-right {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 3px solid #e7e7e7;
}

.md-stepper-horizontal .md-step .md-step-bar-logs-left,
.md-stepper-horizontal .md-step .md-step-bar-logs-right {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 5px solid #e7e7e7;
}

.md-stepper-horizontal .md-step [ class^="md-step-bar-"].current {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 5px solid #0096db;
}
.md-stepper-wrapper .md-stepper-horizontal .md-step [ class^="md-step-bar-"].current {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 3px solid #0086ff;
}

.md-stepper-horizontal .md-step [ class^="md-step-bar-"].disable {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 5px solid #80808096;
}

.md-stepper-horizontal .md-step [ class^="md-step-bar-"].success {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 5px solid #62E187;
}
.md-stepper-wrapper .md-stepper-horizontal .md-step [ class^="md-step-bar-"].success {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 3px solid #30CE83!important;
}
.md-stepper-wrapper .md-stepper-horizontal .md-step.active .md-step-circle.success{
border: 3px solid transparent!important;
}
.md-stepper-horizontal .md-step [ class^="md-step-bar-"].skipped {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 5px solid #AEAEAE;
}

.md-stepper-horizontal .md-step [ class^="md-step-bar-"].fail {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 5px solid #ff8969;
}

.md-stepper-horizontal .md-step [ class^="md-step-bar-"].purple {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 5px solid #c294f1;
}

.md-stepper-horizontal .md-step [ class^="md-step-bar-"].yellow {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 5px solid #ffc436;
}

.md-stepper-wrapper .md-stepper-horizontal .md-step .md-step-bar-left, 
.md-stepper-wrapper .md-stepper-horizontal .md-step .md-step-bar-right{
  border-top: 3px solid #cacaca;
}

/* new logs design css */
.md-stepper-horizontal .md-step [ class^="md-step-bar-logs"].current {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 5px solid #0096db;
}

.md-stepper-horizontal .md-step [ class^="md-step-bar-logs"].default {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 2px solid #407BCA
}

.md-stepper-horizontal .md-step [ class^="md-step-bar-logs"].disable {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 5px solid #80808096;
}

.md-stepper-horizontal .md-step [ class^="md-step-bar-logs"].success {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 2px solid #62E187;
}

.md-stepper-horizontal .md-step [ class^="md-step-bar-logs"].skipped {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 2px solid #AEAEAE;
}

.md-stepper-horizontal .md-step [ class^="md-step-bar-logs"].fail {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 2px solid #E1941D;
}

.md-stepper-horizontal .md-step [ class^="md-step-bar-logs"].purple {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 2px solid #c294f1;
}

.md-stepper-horizontal .md-step [ class^="md-step-bar-logs"].yellow {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 5px solid #ffc436;
}





.md-stepper-horizontal .md-step .md-step-bar-right {
  right: 0;
  left: 50%;
  margin-left: 15px;
}

.md-stepper-horizontal .md-step .md-step-bar-logs-right {
  right: 0;
  left: 50%;
  margin-left: 30px;
}


.md-stepper-horizontal .md-step .md-step-bar-left {
  left: 0;
  right: 50%;
  margin-right: 15px;
}

.md-stepper-horizontal .md-step .md-step-bar-logs-left {
  left: 0;
  right: 50%;
  margin-right: 30px;
}
.md-stepper-wrapper .md-stepper-horizontal .md-step .md-step-bar-right {
  right: 0;
  left: 55%;
  margin-left: 15px;
}

.md-stepper-wrapper .md-stepper-horizontal .md-step .md-step-bar-left{
  left: 0;
  right: 55%;
  margin-right: 15px;
}

.md-step-title-step {
  margin-left: auto;
  margin-top: 5px;
  margin-right: auto;
  width: fit-content;
  font-size: 12px !important;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #505050;
}

.md-step-title-active {
  color: #2F2F2F;
  padding-bottom: 10px;
  border-bottom: 4px solid #0086FF;
}



.md-step-log {
  padding: 24px 24px 0px !important;
}

/* ------------ */
.md-step-log-new {
  padding: 16px 24px 0px !important;
}

.md-stepper-horizontal-new {
  width: 100%;
  align-items: start;
  display: flex;
  justify-content: center;
}

.md-stepper-horizontal-new::-webkit-scrollbar {
  display: none;
}

.md-step-title-step {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: auto;
  margin-top: 5px;
  line-height: 1.2;
  margin-right: auto;
  width: fit-content;
  font-size: 12px !important;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #505050;
}

.makeStyles-mainBodyDiv-161 {
  z-index: -2 !important;
  margin-left: -1px;
}

.makeStyles-mainBodyDiv-315 {
  z-index: -2 !important;
  margin-left: -1px;
}

.MuiDivider-root {
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background-color: #dedede !important;
  margin-bottom: 20px;
}

.makeStyles-root-176 {
  justify-content: space-between;
}

.MuiButton-containedPrimary {
  color: #fff;
  background-color: #0db79b !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #0095da !important;
}

.PrivateRadioButtonIcon-root-294>.MuiSvgIcon-root {
  font-size: 2.5rem !important;
}

.PrivateRadioButtonIcon-root-442>.MuiSvgIcon-root {
  font-size: 2.5rem !important;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
}

.hr-text:before {
  content: "";
  background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(#818078), to(transparent));
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 34%;
  width: 100%;
  height: 1px;
}

.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;
  padding: 0 0.5em;
  line-height: 1.5em;
  font-size: 12px;
  background-color: #fcfcfa;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #0095da !important;
}

select:focus {
  border: 1px solid #4671C6 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  outline: none !important;
}

.select {
  width: 100%;
  /* height:50px; */
  display: block;
  margin: 0px;
  line-height: 2;
  border-radius: 4px;
  border: 1px solid #b7b7b7;
  padding: 5px 14px;
  color: #2f2f2f;
  font-size: 14px;
  font-weight: 500;
  font-family: 'montserrat', sans-serif;

  background: #fff url('/public/images/down-arrow.png') no-repeat right center;
  cursor: pointer;
  background-size: 15px 15px;
  background-origin: content-box;

  /* Hide default dropdown arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.MuiInputBase-input {
  font-family: 'montserrat', sans-serif !important;
}

/*Bullets online offline Style*/
.status-point {
  position: relative;
  display: flex;

}


.p-relative {
  position: relative !important;
}

.bullet {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  display: inline-block;
  margin: .25rem;
  box-sizing: content-box;
  position: absolute;
  left: 0;
  top: 30%;
}

.green {
  background-color: #69e09c;
}

.purple {
  background-color: #c294f1;
}

.blue {
  background-color: #0086ff;
}

.blue-text {
  color: #0086ff !important;
}

.bullet-with-border {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  display: inline-block;
  margin: .25rem;
  border: 3px solid #fff;
  box-sizing: content-box;
}

.failed {
  background-color: #ff8787;
}

.inqueue-text {
  color: #b1cde3;
  /* #ffc514; */
}

.yellow-text {
  color: #ffc514;
}

.yellow-text .flaticon-clock-with-white-face {
  color: #ffc514;
}

.status-div.red-text {
  color: #ffa785;
}

.green-text {
  color: #79e599;
}

.round-chip {
  display: flex;
  padding: 0px 8px 0px 5px;
  max-width: 120px;
  align-items: center;
  border-radius: 24px;
  justify-content: center;
  font-size: 10px !important;
  background-color: #0086ff;
  color: #fff;
  width: fit-content;
  min-height: 25px;
  text-transform: capitalize !important;
  margin: 0px 3px;
}

.round-chip .flaticon-circle-with-check-symbol {
  color: #fff;
  margin: 0px 3px !important;
}

.round-chip .flaticon-clock-with-white-face {
  color: #fff;
  margin: 0px 3px !important;
}

.round-chip .flaticon-round-error-symbol {
  color: #fff;
  margin: 0px 3px !important;
}

.round-chip .flaticon-thumb-up-button {
  color: #fff;
  margin: 0px 3px !important;
}

.bg-round-green {
  background-color: #69e09c !important;
}

.bg-round-yellow {
  background-color: #ffc514 !important;
}

.bg-round-red {
  background-color: #ff8787 !important;
}

.bg-round-purple {
  background-color: #c294f1 !important;
}

.bg-round-blue {
  background-color: #0086ff !important;
}

.bg-round-grey {
  background-color: #f4f4f4 !important;
}

.bg-f7-grey {
  background-color: #f7f7f7 !important;
}

.bg-f7-fc {
  background-color: #fcfcfc !important;
}

.bg-f7-f8 {
  background-color: #f8f8f8 !important;
}

.new-status-chip {
  display: flex;
  font-size: 12px;
  padding: 4px 6px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #EFFFF3;
  font-weight: 700;
  text-transform: uppercase;
}

.new-success {
  background: #EFFFF3;
  color: #129E5B;
  border: 1px solid #DFEDFF;
}

.new-running {
  background: #F5FAFF;
  color: #0086FF;
  border: 1px solid #DFEDFF;
}

.new-failed {
  background: #FFF3F3;
  color: #BA0000;
  border: 1px solid #FBE6E6;
}

.new-revoked {
  background: #F4F4F4;
  color: #626262;
  border: 1px solid #E6E6E6;
}

.new-inqueue {
  background: #FCF6E1;
  color: #FEA111;
  border: 1px solid #FCF6E1;
}

.bg-white .md-stepper-horizontal {
  background-color: #fff;
}

.scheduler-time {
  font-size: 30px;
  color: #727272;
}

.badge {
  display: inline-block;
  padding: .5em .4em .5em;
  font-size: 11px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  transition: color .15s ease-in-out,
    background-color .15s ease-in-out,
    border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
}

.badge-pill {
  padding-right: .6em;
  padding-left: .6em;
  padding-top: .75rem;
  padding-bottom: .6rem;
  border-radius: 13rem;
}

.badge-ellipsis {
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.chip-xs-small {
  font-size: 10px;
  background-color: transparent;
  color: transparent;
  line-height: 1.8;
  padding-left: .2rem;
  padding-right: .2rem;
  border-radius: 4px;
}

.badge-primary {
  color: #fff;
  background-color: #0086ff;
}

.badge-danger {
  color: #fff;
  background-color: #ff8787;
}

.badge-success {
  color: #fff;
  background-color: #69e09c;
}

.badge-warning {
  background-color: #ffc514;
  color: #fff;
}

.badge-artifact {
  background-color: #124d9b;
  color: #fff;
}

.badge-sq {
  border-radius: 6px;
}

.badge-dev {
  background-color: #30b4a6;
  color: #fff;
}

.badge-qa {
  background-color: #81a379;
  color: #fff;
}

.badge-uat {
  background-color: #f5a959;
  color: #fff;
}

.badge-staging {
  background-color: #6a6fcb;
  color: #fff;
}

.badge-prod {
  background-color: #a37983;
  color: #fff;
}

.badge-m {
  padding-right: .8em;
  padding-left: .8em;
  padding-top: .8rem;
  padding-bottom: .7rem;
  display: inline-block
}

.chip-with-icon {
  color: #000;
  border: none;
  cursor: default;
  height: 32px;
  display: inline-flex;
  outline: 0;
  padding: 0;
  font-size: 0.8125rem;
  box-sizing: border-box;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  white-space: nowrap;
  border-radius: 16px;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: #e0e0e0;
}

.chip-with-icon-label {
  overflow: hidden;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  text-overflow: ellipsis;
}

.chip-with-icon-before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
}

/*Bullets online offline styles ends Here*/
.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 2px solid transparent;
  border-radius: .25rem;
  font-size: 12px;
  height: fit-content;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  /* border-color: #76b7ff; */
  border-color: #b8daff;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-pipeline-v3 {
  height: 45px !important;
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #0c5460;
}

.alert-dismissible {
  padding-right: 4rem;
}

/* Error Styling */
.error-message {
  color: #cc0033;
  display: inline-block;
  font-size: 12px;
  position: relative;
  line-height: 15px;
  margin: 5px 0 0;
}

.input-group .error-message {
  display: none;
}

.error label {
  color: #cc0033;
}

.error {
  background-color: #fce4e4 !important;
  border: 1px solid #cc0033 !important;
  outline: none;
}

.error .error-message {
  display: inline-block;
}

/*Welcome Screen Style*/
.formDivInternal {

  width: 100%;
}

.formDiv {
  background-color: #fff;
  padding: 2rem;
  margin: 3rem 0rem;
  width: 100%;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px;
  border: 1px solid#dedede;
}

.formTag {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0rem;
  margin: 1rem 0px;
}

.mainHeading {
  font-size: 13px;
  font-weight: 500;
  margin: 0px .5rem;
}

.red {
  color: red
}

.InputLabel {
  font-size: 12px;
  font-weight: 400;

}

.section {
  margin: 1rem
}

.hint-text {
  color: #898989;
  font-size: 10px;
  font-weight: 400;
  margin-left: .5rem;
}

.field-icon {
  float: right;
  margin-left: 93%;
  margin-top: -36px;
  position: relative;
  z-index: 2;
}

.field-icon-login {
  float: right;
  margin-right: 40px;
  margin-top: -50px;
  position: relative;
  z-index: 2;
}

.text-green {
  font-size: 12px;
  line-height: 1.2;
  color: #0db79b;
}

.text-red {
  font-size: 12px;
  color: #FF6969 !important;
  line-height: 1.2;
}

.chip-small {
  background-color: #aeb1ba;
  color: #fff;
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.3rem .5rem;
  border-radius: 4px;
  margin: 0.3rem;
}

.text-red>.successIcon>.MuiSvgIcon-root {
  font-size: 18px !important;
}

.text-green>.FaildIcon>.MuiSvgIcon-root {
  font-size: 18px !important;
}

.successIcon {
  margin: .25rem 1rem;
}

.FaildIcon {
  margin: 0.25rem 1rem;
}

.floatLeft {
  display: flex;
  justify-content: flex-end;
}

.btn-buildpiper {
  display: inline-block;
  font-weight: 400;
  width: fit-content;
  height: fit-content;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid transparent;
  padding: 1rem 2rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-success-green {
  color: #fff;
  background-color: #0db79b;
  border-color: #0db79b;
}

.btn-success-outlined {
  color: #6ad598;
  border: 2px solid #6ad598 !important;
  font-weight: 400;
}

.btn-success-green:hover {
  color: #fff;
  background-color: #099b83;
  border-color: #099b83;
}

.btn-outline-success-green {
  color: #0db79b;
  background-color: transparent;
  background-image: none;
  border-color: #0db79b;
}

.btn-outline-success-green:hover {
  color: #fff;
  background-color: #0db79b;
  border-color: #0db79b;
}

.btn-outline-gray {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-gray:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-save {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-save-primary {
  background-color: #52a3e7 !important;
  color: #fff;
}

.btn-save-lg {
  padding: .75rem 1rem .7rem;
  min-width: 100px;
  font-size: 12px;
}

.btn-save-primary:hover {
  background-color: #4990ca !important;
  color: #fff;
}

.btn-Style {
  padding: 1rem;
  min-width: 180px;
  max-width: 200px;
  box-shadow: 1px 1px 5px 1px #ccc;
  color: #fff;
  background-color: #00bd9c;
  font-size: 12px;
  font-weight: 700;
  border: none;
  border-radius: 4px;
  margin: 1.5rem 0rem 0rem;
}

.btn-txt {
  height: 20px !important;
  width: 20px !important;
  padding: 0px;
  line-height: 1.2rem;
}

.btn-txt .flaticon-add-plus-button::before {
  font-size: 11px;
}

.empty-box .btn:hover {
  background-color: transparent;
  color: #0096db !important;
}
.btn-pagintaion{
  border: 1px solid #C7D7EC;
  background-color: #fff;
  color: #124d9b;
}
.btn-pagintaion:hover{
  border: #124d9b;
  background-color: #124d9b;
  color: #fff;
}


.btn-transparent {
  border-color: none !important;
  border:2px solid transparent;
  background-color: transparent !important;
  color: #2f2f2f;
}

.btn-transparent:hover {
  background-color: #4444441f!important ;
}
.btn-transparent-primary{
  color: #0086ff;
}
.btn-transparent-primary:hover {
  background-color: #e0e0e0 !important;
}

.text-primary {
  color: #25a5e0;
}

.btn-grey {
  display: inline-block;
  padding: 10px 5px;
  margin: .2rem;
  border: 1px solid #e5e5e5;
  background-color: #f8f8f8;
  border-radius: 4px;
}

.saas-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-outline-white {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
}

.artifact {
  color: #2F2F2F;
  
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.saas-btn.saas-btn-lg {
  padding: 1rem 1.75rem;
  line-height: 1.5;
}

.padding-control .MuiGrid-spacing-xs-2>.MuiGrid-item {
  padding: 0px 8px !important;
}

/*Welcome Screen Style Ends Here*/
/*Choose file button css*/
input[type=file]::-webkit-file-upload-button {
  background: #0945a1;
  height: 100%;
  border: none;
  border-radius: 4px 4px 0px 0px;
  position: relative;
  display: inline-block;
  padding: 10px;
  color: #fff;
}

input[type=file] {
  padding: 0px;
  border-radius: 4px;
}

.fromClass {
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 0;
  z-index: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
  width: 100%;
}

div[ class^="PrivateRadioButtonIcon-root"]>.MuiSvgIcon-root {
  font-size: 2.5rem !important;
}

.MuiFormGroup-row {
  margin-left: .5rem;
}

.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px;
  position: relative;
  z-index: 9;
  overflow: hidden;
  border: 1px solid #dedede;
}

.card-new {
  display: flex;
  align-items: start;
  flex-direction: column;
  height: 217px;
  padding: 0px 0px 16px 0px;
  border-radius: 4px;
  border: 1px solid #E6E6E6;
  gap: 14px;
  box-shadow: 0px 4px 4px 0px #00000014;
  background: white;
}


.border-controller .card-header {
  border-bottom: 0px solid #dedede;
}

.card-header {
  border-bottom: 1px solid #dedede;
  height: 50px;
  padding: 10px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-header-new {
  position: absolute;
  z-index: 999;
  width: 100%;
  top: 0px;
  border-bottom: 1px solid #dedede;
  height: 54px;
  padding: 16px 24px 16px 24px;
  background-color: #124D9B;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.card-header .heading {
  line-height: 1.5;
  font-size: 13px;
  color: #000;
}

.card-subheader {
  padding: 10px;
  border-bottom: 1px solid #dedede;
  background-color: #fcfcfc;
}

.card-subheader>.subheading {
  line-height: 1.5;
  font-size: 12px;
  color: #666;
}

.main .card-body {
  padding: 10px;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  border-radius: 0px 0px 8px 8px;
}

.card-body-new {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  height: fit-content;
  width: 100%;
  padding: 0px 16px;
  background-color: white !important;
}

.card-body-new-step {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  height: 65px;
  width: 100%;
  padding: 0px 16px;
  background-color: rgba(235, 245, 255, 0.6) !important;
}

.card-body {
  padding-bottom: 10px;

}

.card-body .InputLabel {
  font-size: 12px;
  font-weight: 400;
  color: #000;
}

.cardtext {
  display: flex;
  align-items: center;
}

.card-footer {
  border-top: 1px solid #dedede;
  padding: 1rem 1.5rem;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-part {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var #DFEDFF;
  background: #F5FAFF;
}

.play-icon-btn {
  padding: .5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 130px;
  max-width: 150px;
  box-shadow: rgb(204, 204, 204) 1px 1px 5px 1px;
  color: #000;
  background-color: #fff;
  font-size: 10px;
  font-weight: 400;
  border-radius: 26px;
}

.play-icon-btn>.MuiSvgIcon-root {
  color: rgb(254, 161, 16)
}

.task-name {
  font-size: 11px;
  color: #000;
  text-align: center;
  margin: 1rem 0rem;
}

.image-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.image-box img {
  height: 50px;
}

.card-count {
  position: absolute;
  background-color: #0095da;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  color: #fff;
  top: -1px;
  right: -1px;
  z-index: 99;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

a.btn-blue-navi:visited {
  color: #fff;
}

/*switch button*/
.switch {
  position: relative;
  display: block;
  vertical-align: top;
  width: 50px;
  height: 20px;
  background: linear-gradient(to bottom, #eeeeee, #FFFFFF 25px);
  background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF 25px);
  border-radius: 18px;
  box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  box-sizing: content-box;
}

.switch.ed-switch {
  width: 75px;
}

.switch.ed-switch .switch-label:after {
  left: 12px;
}

.switch.ed-switch .switch-input:checked~.switch-handle {
  left: 56px;
}
.switch-input-wrapper-v1
.switch-input:checked ~ .switch-label{
    background-color: #007EFF !important;
    border: none;
    padding: 5px 30px;
    top: -5px
}
.switch-input-wrapper-v1
.switch-input ~ .switch-label{
    background-color: #4d647b !important;
    border: none;
    padding: 5px 30px;
    top: -5px
}
.switch-input-wrapper-v1
.switch-input ~ .switch-handle {
    box-shadow: none;
    background: #fff;
    height: 24px;
    width: 24px;
    top: -2px;
    left: 4px;
}
.switch-input-wrapper-v1
.switch-input ~ .switch-label::before {
    top: 15px;
    font-size: 12px;
}
.switch-input-wrapper-v1 .switch-input:checked ~ .switch-handle{
  box-shadow: none;
  background: #fff;
  height: 24px;
  width: 24px;
  top: -2px;
  left: 33px;
}
.switch-input-wrapper-v1 .switch-label::after {
  left: 11px;
  top: 15.5px;
  font-size: 12px;
}
.switch-input-wrapper-v1 .input-component.f-direction-column{
  flex-direction: row;
}
.switch-input-wrapper-v1 .input-component{
  flex-direction: row;
}
.width-fix {
  width: 80px;
}

.switch.ed-switch .switch-input:checked~.switch-handle-fix {
  left: 63px;
}

.true-false-switch {
  width: 100px;
  height: 35px;
  border-radius: 0px;
  border-radius: 4px;
  overflow: hidden;

}

.true-false-switch .switch-input:checked~.switch-label {
  top: 0px;
  background: #F0F0F0 !important;

}

.true-false-switch .switch-input:checked~.switch-label::before {
  color: #fff !important;
}

.true-false-switch .switch-input:checked~.switch-label::after {
  color: #989898 !important;
}

.true-false-switch .switch-input:checked~.switch-handle {
  left: 50px;
  background: #6ee791;
}

.true-false-switch .switch-input~.switch-handle {
  background: #FA6565;
  color: #fff;
}

.true-false-switch .switch-label {
  border: none;
  background-color: #F0F0F0;
  color: #989898;
  top : 0px;

}

.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  box-sizing: content-box;
}

.switch-label {
  position: relative;
  display: block;
  height: inherit;
  top: -2px;
  font-size: 11px;
  font-weight: 500;
  background: #4d647b;
  border-radius: inherit;
  box-shadow: none;
  box-sizing: content-box;
  border: 1px solid #dedede;
}

.switch-label:before,
.switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
  box-sizing: content-box;
}

.switch-label:before {
  content: attr(data-off);
  right: 11px;
  top: 11px;
  font-weight: 500;
  color: #fff;
  /* text-shadow: 0 1px rgba(255, 255, 255, 0.5); */
}

.switch-label:after {
  content: attr(data-on);
  left: 7px;
  top: 11px;
  font-weight: 500;
  color: #FFFFFF;
  /* text-shadow: 0 1px rgba(0, 0, 0, 0.2); */
  display: none;
}

.true-false-switch .switch-label:before {
  content: attr(data-on);
  right: 11px;
  top: 11px;
  font-weight: 500;
  color: #989898;
  /* text-shadow: 0 1px rgba(255, 255, 255, 0.5); */
}

.true-false-switch .switch-label:after {
  content: attr(data-off);
  left: 7px;
  top: 11px;
  font-weight: 500;
  color: #fff;
  /* text-shadow: 0 1px rgba(0, 0, 0, 0.2); */
  display: block;
  z-index: 99;
}

.switch-input:checked~.switch-label {
  background: #0095da !important;
  position: relative;
  top: -2px;
}

.switch-input:checked~.switch-label:before {
  display: none;
  position: relative;
  top: -2px;
}

.switch-input:checked~.switch-label:after {
  display: block;
}

.true-false-switch .switch-label::before {
  /* right: 32px; */
}

.true-false-switch .switch-input:checked~.switch-label:before {
  display: block;
  position: absolute;
  top: 11px;
}

.switch-handle {
  position: absolute;
  top: 2px;
  left: 4px;
  width: 15px;
  height: 15px;
  background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
  background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
  border-radius: 100%;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-input:checked~.switch-handle {
  left: 32px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.true-false-switch .switch-label:before {
  z-index: 999;
}

.true-false-switch .switch-handle {
  border-radius: 0px;
  z-index: 9;
  box-shadow: none !important;
  top: 0px;
  height: 35px;
  width: 50px;
  left: 0px
}

.true-false-switch .switch-label::before,
.true-false-switch .switch-label::after {
  margin-top: 0em;
}

/* Transition
========================== */
.switch-label,
.switch-handle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}

.quantity {
  position: relative;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.quantity input {
  width: 100%;
  height: 42px;
  line-height: 1.65;
  float: left;
  display: block;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  border: 1px solid #b7b7b7;
}

.quantity input:focus {
  outline: 0;
}

.quantity-nav {
  float: left;
  position: relative;
  height: 42px;
}

.quantity-button {
  position: relative;
  cursor: pointer;
  border-left: 1px solid #eee;
  width: 20px;
  text-align: center;
  color: #333;
  font-size: 13px;
  line-height: 1.7;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.quantity-button.quantity-up {
  position: absolute;
  height: 50%;
  top: 0;
  border-bottom: 1px solid #eee;
}

.quantity-button.quantity-down {
  position: absolute;
  bottom: -1px;
  height: 50%;
}

/*  Range Slider  */
.range-slider {
  margin: 10px 0 0 0%;
  width: 100%;
}

.range-slider__range {
  -webkit-appearance: none;
  width: 80%;
  height: 10px;
  display: inline;
  border-radius: 5px;
  background: #0095da;
  outline: none;
  padding: 0;
  margin: 0;
}

.range-slider__range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #0095da;
  cursor: pointer;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out;
}

.range-slider__range::-webkit-slider-thumb:hover {
  background: #0095da;
}

.range-slider__range:active::-webkit-slider-thumb {
  background: #0095da;
}

.range-slider__range::-webkit-range-thumb {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  -moz-transition: .15s ease-in-out;
  transition: .15s ease-in-out;
  -webkit-transition: .15s ease-in-out;
}

.range-slider__range::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  -moz-transition: .15s ease-in-out;
  transition: .15s ease-in-out;
  -webkit-transition: .15s ease-in-out;
}

.range-slider__range::-moz-range-thumb:hover {
  background: #0095da;
}

.range-slider__range:active::-moz-range-thumb {
  background: #0095da;
}

.range-slider__range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 3px #fff, 0 0 0 6px #0095da;
}

.range-slider__value {
  display: inline-block;
  position: relative;
  width: 60px;
  color: #000;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: #fff;
  padding: 5px 10px;
  margin-left: 8px;
  border: 1px solid #b7b7b7;
}

.range-slider__value:after {
  position: absolute;
  top: 8px;
  left: -7px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: 7px solid #b7b7b7;
  border-bottom: 7px solid transparent;
  content: '';
}

::-moz-range-track {
  background: #d7dcdf;
  border: 0;
}

input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
}

/*  Range ends here Slider  */
/* Choose Avatar */
.chooseavatar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 1.5rem;
}

.avtar {
  text-transform: uppercase;
}

.imgbody {
  border: 1px solid #b7b7b7;
  padding: 10px 10px 0px;
  position: relative;
  border-radius: 4px;
  margin: 10px;
}

.imgbody img {
  position: relative;
  top: .7rem;
  height: 120px
}

/*file upload*/
.file-upload {
  background-color: #ffffff;

  /* margin: 0 auto; */
}

.file-upload-btn {
  width: 100%;
  margin: 0;
  color: #fff;
  background: #1FB264;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #15824B;
  transition: all .2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.multi-file-upload .image-upload-wrap::-webkit-scrollbar {
  display: none
}

.image-upload-wrap::-webkit-scrollbar {
  display: none
}

.file-upload-btn:hover {
  background: #1AA059;
  color: #ffffff;
  transition: all .2s ease;
  cursor: pointer;
}

.file-upload-btn:active {
  border: 0;
  transition: all .2s ease;
}

.file-upload-content {
  display: none;
  text-align: center;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  outline: none;
  opacity: 1;
  cursor: pointer;
  max-height: 39px;
  color: transparent;
  width: 92px;
  bottom: 0px;
  right: 0;
  border: none;
}

.file-upload-input::-webkit-file-upload-button {
  visibility: hidden;
}

.file-upload-input::before {
  content: 'Choose file';
  display: inline-block;
  background-color: #0096db;
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
  color: #fff;
}

.image-upload-wrap {
  margin-top: 10px;
  border: 2px dashed #d1d1d1;
  position: relative;
  height: 150px;
  overflow-y: scroll;
}

.image-dropping,
.image-upload-wrap:hover {
  background-color: #eee;
  border: 2px dashed #ffffff;
}

.image-title-wrap {
  padding: 0 15px 15px 15px;
  color: #222;
}

/* My File upload classes*/
.file-upload-container {
  background-color: #ffffff;
  margin: 0 auto;
}

.input-file-wraper {
  display: flex;
  height: 46px;
  padding: 10px 12px;
  justify-content: start;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #b7b7b7;
  background: #FFF;
  cursor: pointer;
}

.main-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.main-file-input {
  /* position: absolute; */
  margin: 0;
  padding: 0;
  outline: none;
  opacity: 1;
  cursor: pointer;
  max-height: 39px;
  color: transparent;
  width: 100%;
  bottom: 0px;
  left: 0;
  border: none;
  display: none;
}

.main-file-input:focus {
  border: transparent;
  outline: transparent;
}

.main-file-input::before {
  content: 'Choose Kubeconfig File';
  display: inline-block;
  background-color: transparent;
  /* border: 1px solid #999; */
  /* border-radius: 3px; */
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  user-select:none;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: #0086FF;
}

.drag-text {
  text-align: center;
  margin-top: 0rem;
}

.drag-text h3 {
  font-weight: 100;
  color: #666;
  padding: 15px 0;
}

.file-upload-image {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
  padding: 20px;
}

.icon_cd {
  width: 50px;
}

.remove-image {
  width: 200px;
  margin: 0;
  color: #fff;
  background: #8f9397;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #8f9397;
  transition: all .2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.remove-image:hover {
  background: #fff;
  color: #000;
  transition: all .2s ease;
  cursor: pointer;
}

.remove-image:active {
  border: 0;
  transition: all .2s ease;
}

.MuiSlider-rail {
  width: 100%;
  height: 8px !important;
  display: block;
  opacity: 1 !important;
  position: absolute;
  border-radius: 8px !important;
  background-color: #e4e4e4 !important;
}

.MuiSlider-track {
  height: 8px !important;
  display: block;
  position: absolute;
  border-radius: 8px !important;
  background-color: #0096db !important;
}

.MuiSlider-thumb {
  width: 18px !important;
  height: 18px !important;
  display: flex;
  outline: 0;
  position: absolute;
  box-sizing: border-box;
  /* margin-top: -5px; */
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  margin-left: -6px;
  border-radius: 50%;
  justify-content: center;
  background-color: #0096db !important;
}

.range-slider-input {
  width: 48px;
  padding: 12px;
}

.MuiSlider-root {
  width: 80% !important;
}

.pageHeading {
  font-size: 12px;
  font-weight: 400;
  padding: 1rem 0rem;
  border-bottom: 1px solid #b7b7b7;
  margin-bottom: 1.5rem;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto 75px;
  grid-gap: 5px;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  padding: 0rem .25rem;
  align-items: center;
  margin-bottom: .5rem;
  margin-top: .5rem;
}

.border_bottom {
  border-bottom: 2px solid #000;
}

.border-right-d5 {
  border-right: 1px solid #d5d5d5;
}

.border-right {
  border-right: 1px solid #dedede !important;
}

.border-right-d5:last-child {
  border-right: 0px;
}

.transparent-btn {
  background-color: transparent;
  color: #3164a8;
  font-size: 12px;
  font-weight: 500;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: .25rem;
}

.hollow-delete-btn {
  padding: 8px 12px 8px 12px;
  border: 1px solid #FFCDCD;
  background-color: #FFFFFF;
  border-radius: 6px;
  gap: 6px;
  color: #C11212 !important;
  font-weight: 600;
  width: 79px;
  height: 32px;
}

.hollow-delete-btn:hover {
  background-color: #C11212;
  color: #FFFFFF !important;
  border-color: #C11212;
}

.multi-row-add-btn {
  height: 44px;
  padding: 12px 0px 12px 0px;
  border: 1px solid #0086FF;
  border-radius: 8px;
  background-color: #FFFFFF;
  color: #0086FF !important;
}

.multi-row-add-btn:hover {
  color: #FFFFFF !important;
  background-color: #0086FF;
  border-color: #0086FF;
}
.delete-btn {
  background-color: #f6f6f6;
  color: #ff4a4a;
  border-radius: 4px;
  line-height: 0.9;
  border: 1px solid #edcaca;
  height: 30px;
  width: 30px;
  margin: 2px
}
.delete-outline-btn{
  background-color: transparent;
  padding: 10px;
  border: 1px solid #DBDBDB;
  color: #E53737;
  border-radius: 6px; 
}
.delete-outline-btn:hover{
  background-color: #E53737;
  border: 1px solid #E53737;
  color: #fff;
  cursor: pointer;
}

.cancel-btn {
  background-color: #f6f6f6;
  color: #dedede;
  border-radius: 4px;
  line-height: 0.9;
  border: 1px solid #dedede;
  height: 30px;
  width: 30px;
  margin: 2px
}

.add-button-icon {
  background-color: #f6f6f6;
  color: #0096db;
  border-radius: 4px;
  line-height: 0.9;
  border: 1px solid #dedede;
  height: 30px;
  width: 30px;
  margin: 2px
}

.inputLabel {
  font-size: 12px;
  color: #828282;
  text-align: center;

}

.d-flex .grid-input {
  width: 86%;
}

.grid-input {
  display: grid;
  grid-template-columns: 25% 75%;
  border: 1px solid #dedede;
  background-color: #f6f6f6;
  align-items: center;
  border-radius: 4px;
}

.grid-input .input-component {
  margin-bottom: 0px !important;
  width: 100% !important;
}

.grid-input .input-component input {
  border: none;
  border-left: 1px solid #dedede;
}

.image-container {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #b7b7b7;
  padding: 1rem;
  width: 16%;
  height: 14.5rem;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container img {
  height: 50px;
  display: inline-block;
  margin: auto;
  margin-top: 2.5rem;
}

.image-container .main-text {
  font-size: 11px;
  font-weight: 300;
  line-height: 1;
  color: #000;
}

.image-upload-chip-parent {
  display: flex;

  flex-wrap: wrap;
}

.text-ellipsis-65 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 65px;
}

.text-ellipsis-80 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80px;
}

.text-ellipsis-110 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100px;
}

.text-ellipsis-109 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 110px;
}

.text-ellipsis-150 {
  white-space: nowrap!important;
  text-overflow: ellipsis!important;
  overflow: hidden!important;
  width: 150px!important;
}

.text-ellipsis-200 {
  white-space: nowrap!important;
  text-overflow: ellipsis!important;
  overflow: hidden!important;
  width: 200px!important;
}

.text-ellipsis-60percent {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 60%;
}

.text-ellipsis-250 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 250px;
}

.text-ellipsis-300 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 300px;
}

.text-ellipsis {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.image-upload-chip {
  background-color: #8f9397;
  padding: 6px 16px;
  display: flex;
  justify-content: space-between;
  max-width: 180px;
  overflow: hidden;
  margin: 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 4px;
}

.image-upload-chip p {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image-upload-chip .transparent-btn {
  background-color: #8f9397;
  color: #fff;
}

.image-upload-chip>.transparent-btn>.MuiSvgIcon-root {
  font-size: 14px !important;
}

.btn-ci {
  background-color: #0096db !important;
  color: #fff !important;
  border-color: #0095da;
}

.btn-ci:hover {
  background-color: #0087c5 !important;
}

.btn-green-navi {
  padding: 6px 16px;
  min-width: 150px;
  box-shadow: 1px 1px 5px 1px #ccc;
  color: #fff;
  background-color: #00bd9c;
  font-size: 14px;
  font-weight: 700;
  border: none;
  border-radius: 4px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-green-navi .MuiSvgIcon-root {
  font-size: 2rem !important;
}

.btn-blue-navi {
  padding: 6px 16px;
  min-width: 100px;
  box-shadow: 1px 1px 5px 1px #ccc;
  color: #fff;
  background-color: #0095da;
  font-size: 12px;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-blue-navi .MuiSvgIcon-root {
  font-size: 2rem !important;
}

.btn-grey-navi {
  padding: 8px 16px 8px 12px !important;
  min-width: 100px;
  color: #000;
  background-color: #f9f9f9;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px !important;
  height: 100%;
  line-height: 1.4;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #c9c9c9 !important;
}

.btn-grey-navi .MuiSvgIcon-root {
  font-size: 2rem !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

/*CI details card*/
.card-grid {
  display: grid;
  grid-template-columns: 150px auto 350px;
  grid-gap: 10px;
}

.text-card-grid {
  font-size: 12px;
  color: #989898;
  font-weight: 400;
}

.tag {
  font-size: 12px;
  color: transparent;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 3px 5px;
  width: fit-content;
  margin: 2px
}

.tag-outline-primary {
  background-color: transparent;
  border-color: #0086ff;
  color: #0086ff
}

.tag-group .tag {
  display: inline-block;
}

.tag-group .tag:first-child {
  margin-left: 0px;
}

.tag-group .tag:last-child {
  margin-right: 0px;
}

.green-chip {
  background-color: #6ee791;
  padding: 1rem;
  display: flex;
  justify-content: center;
  border-radius: 24px;
  line-height: 1;
  align-items: center;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  margin-left: .25rem;
  margin-right: .25rem;
}

.green-chip .icon-part {
  margin: 0rem .25rem;
}

.green-chip .text-part {
  margin: 0rem .25rem;
}

.card-footer-ci {
  border-top: 1px solid #dedede;
  padding: 1rem 1.5rem;
  background-color: #fcfcfc;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.grey-chip {
  background-color: #f0f0f0;
  padding: 1rem;
  display: flex;
  justify-content: center;
  border-radius: 24px;
  line-height: 1;
  align-items: center;
  color: #adadad;
  font-size: 13px;
  font-weight: 400;
  margin-left: .25rem;
  margin-right: .25rem;
}

.black-chip {
  background-color: #124d9b;
  padding: 5px;
  display: flex;
  height: 25px;
  justify-content: center;
  border-radius: 4px;
  line-height: 1;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  margin: .25rem;
}

.black-chip .icon-part {
  background-color: transparent;
  color: #fff;
  border: none;
  line-height: 1;
}

.black-chip>.icon-part>.MuiSvgIcon-root {
  font-size: 15px;
  margin: 3px 3px 0px;
}

.black-chip>.icon-part>.MuiSvgIcon-root:hover {
  cursor: pointer;
}

.config-data {
  display: grid;
  grid-template-columns: 65% auto;
  grid-gap: 10px;
}

.config-data input[type="file"] {
  color: #124d9b;
  font-size: 12px;
}

.up-down-btn {
  background-color: #f6f6f6;
  color: #124d9b;
  padding: 1rem .5rem;
  border-radius: 4px;
  border: 1px solid #edcaca;
  font-size: 14px;
  height: 50px;
  width: 50px;
  margin-bottom: 2rem;
  margin-right: .25rem;
  margin-left: .25rem;
}

input.chip-input[type="text"]::after {
  content: 'he';
}

/*Tags input*/
.tags-input {
  display: flex;
  border: 1px solid #b7b7b7;
  align-items: center;
  padding: .5rem;
  border-radius: 4px;
  flex-wrap: wrap;
  min-height: 50px;
  width: auto;
  background-color: #fff;
}

.tags-input textarea {
  border: none;
  align-self: baseline;
  width: 100%;
  margin-top: 10px;
}

textarea {
  border: none;
}

.textarea {
  display: block;
  margin: 0px;
  position: relative;
  width: 100%;
  line-height: 2;
  border-radius: 4px;
  border: 1px solid #b7b7b7;
  background-color: #fff;
  padding: 10px 14px;
  color: #5e5e5e;
  font-size: 12px;
  font-weight: 400;
}

.textarea:focus {
  border: 1px solid #0945a1 !important;
  outline: 0px !important;
}

.label {
  color: #828282;
  font-size: 12px;
  font-weight: 400;
}

.tags-input textarea:focus {
  border: none;
  outline: none;
}

.tags-input input {
  border: 0;
  outline: 0;
}

.tags-input input:focus {
  outline: 0 !important;
  border: 0 !important;
}

.tags-input input:focus .tags-input {
  border: 1px solid #000;
}

/***Landing page CSS***/
.top-section {
  display: flex;
  justify-content: space-around;
  align-self: center;
  margin: 10rem 5rem 0rem;
}

.landing-imgbox {
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  position: relative;
  top: 4rem;
  left: 4rem;
}

.Landing-content {
  margin: 0rem 2rem 5rem 2rem;
  padding: 1rem;

}

.Landing-content .avtar {
  font-weight: bold;
  color: #fff;
  background-color: #124d9b;
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.5rem;
  align-items: center;
  flex-shrink: 0;
  line-height: 1;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
}

.Landing_intro {
  font-size: 36px;
  color: #fff;
  font-weight: 900;
  margin: 0px 3rem 1rem;
  line-height: 1;
  padding: 0rem 1rem;
}

.btn-btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  height: 42px;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 2;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-success span.flaticon-keyboard-right-arrow-button {
  color: #fff;
}

.btn-success {
  color: #fff;
  background-color: #0db79b;
  border-color: #0db79b;
}

a.btn-success {
  color: #fff;
}

.text-small {
  font-size: 14px;
  font-weight: 400;
  color: #7e9ec9;
  margin: 0px 3rem;
  line-height: 1.5;
  padding: 0rem 1rem;
}

.flex-item {
  display: flex !important;
  align-items: center;
}

.Landing-content>.card {
  margin-left: 6rem;
  margin-top: 5rem;
  padding: 2rem;
  border-radius: 10px;
  width: 50rem;
}

.heading_landing {
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  line-height: 1;
}

.heading_landing span {
  border-bottom: 2px solid #0096db;
}

.image-card-box {
  padding: 3rem 1rem;
  display: grid;
  grid-template-columns: 180px 180px;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.Landing-content .card>.btn-green-navi {
  padding: 10px;
  min-width: 150px;
  box-shadow: 1px 1px 5px 1px #ccc;
  color: #fff;
  background-color: #00bd9c;
  font-size: 16px;
  font-weight: 700;
  border: none;
  border-radius: 4px;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-left: 4rem;
}

.card-img {
  display: flex;
  padding: 2rem;
  height: 200px;
  flex-direction: column;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}

.card-img img {
  height: 60px;
  display: flex;
  justify-content: center;
  margin: 20px auto;

}

.card-img p {
  display: flex;
  justify-content: center;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 900;
  color: #273343;
}

.card-img:first-child {
  background-color: #e6fae5;
}

.card-img:nth-child(2) {
  background-color: #ebf7cc;
}

.card-img:nth-child(3) {
  background-color: #c7f5f2;
}

.card-img:last-child {
  background-color: #eeedfb;
}

/*===========================================
===============Service Dashboard=============
============================================*/
.marginTop-3 {
  margin-top: 3rem;
}

.page-heading {
  font-size: 24px;
  font-weight: 300;
  color: #000;
  line-height: 1.5;
}

.subtext {
  font-size: 12px;
  color: #666;
  line-height: 1;
}

.data-card {
  border-radius: 8px;
  box-shadow: 0.877px 1.798px 14px 0px rgba(0, 0, 0, 0.15);
  padding: 1rem;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.data-card-icon {
  background-color: #fff;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-right: .75rem;
  box-shadow: 0.877px 1.798px 14px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}

.data-card-icon img {
  width: 24px;
}

.data-card-text {
  margin-left: .75rem;
}

.data-card-text h3 {
  font-size: 40px;
  font-weight: 300;
  color: #fff;
  line-height: 1;
}

.data-card-text p {
  line-height: 1;
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
}

.section-overview {
  border-bottom: 1px solid #b7b7b7;
  width: 100%;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}

.heading-section {
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100%;
}

.dashboard-card {
  border: 1px solid #dedede;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px;
  position: relative;
  z-index: 9;
  margin-bottom: 1.5rem
}

.dashboard-card-header {
  padding: 1rem 1.5rem;
  background-color: #fff;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
}

.header-left-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-right-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-card-subheader {
  border-top: 1px solid #dedede;
  padding: 1rem 1.5rem;
  display: grid;
  grid-template-columns: 15% 25% 45% 15%;
  border-bottom: 1px solid #dedede;
  background-color: #fcfcfc;
}

.dashboard-card-subheader p {
  font-size: 12px;
  color: #666;
  padding: 0px 10px;
}

.dashboard-card-body {
  padding: 1rem 1.5rem;
  display: grid;
  grid-template-columns: 15% 25% 45% 15%;
  border-bottom: 1px solid #dedede;
  background-color: #fff;
  border-radius: 0px 0px 8px 8px;
}

.dashboard-card-body>div {
  padding: 0px 10px;
}

.service-name {
  color: #0086ff;
  font-size: 14px;
  font-weight: 500;
  margin-right: .25rem;

}

.service-status {
  margin: 0px .25rem;
}

.bullet-text {
  font-size: 12px;
  color: #666;
}

.visit-url {
  color: #124d9b;
  margin: 0px .25rem;
}

.visit-url a {
  color: #124d9b;
  font-size: 12px;
  font-weight: 400;
}

.status-url {
  display: none;
}

.visit-url:hover .status-url {
  display: inline-block
}

.btn-toggle {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.chip-grey {
  background-color: #aaa;
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin: .25rem;
  font-weight: 500;
  padding: .5rem 1rem
}

.chip-green {
  background-color: #0db79b;
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin: .25rem;
  font-weight: 500;
  padding: .5rem 1rem
}

.details {
  margin: .25rem;
}

.details .main-text {
  font-size: 12px;
  color: #000;
}

.details .sub-text {
  font-size: 12px;
  color: #666;
}

input.replica-count {
  max-height: 30px;
  max-width: 80px;
  padding: 12px 8px;
  margin: 0px 10px;
}

.replica {
  display: flex;
  align-items: center;
  margin: 10px 0px;
}

.monitor-box>.visit-url a {
  font-size: 12px;
  color: #124d9b;
  font-weight: 400;
}

.monitor-box>.visit-url {
  display: flex;
  align-items: center;
}

.monitor-box>.visit-url img {
  width: 12px;
  margin-right: .5rem;
  display: inline-block;
}

.progress-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.progress-container>.progress {
  position: relative;
  margin: 0px 5px;
}

.progress-container>.progress:first-child {
  margin-left: 0px;
}

.progress-container>.progress:last-child {
  margin-right: 0px;
}

.progress-container>.progress .text-progress {
  position: absolute;
  font-size: 9px;
  color: #666;
  top: 3.2rem;
  line-height: 1.2;
  width: 100%;
  text-align: center;
}

.text-progress p:first-child {
  font-size: 11px !important;
  color: #000;
}

.CircularProgressbar {
  width: 80px !important;
  margin: 0px 10px;
}

.CircularProgressbarNew {
  width: 100% !important;
  margin: 0px;
  box-shadow: 0px 5.479px 5.479px 0px rgba(0, 0, 0, 0.16);
  border-radius: 50%;
}

.CircularProgressbarWithChildren {
  margin-top: -110% !important;
}

.CircularProgressbar-text {
  font-size: 12px !important;
  color: #000 !important;
}

.usage>.CircularProgressbar .CircularProgressbar-text {
  fill: #000 !important;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #000 !important;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.text-grey {
  color: #666;
}

.Service-section-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.main-heading {
  color: #2F2F2F;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.main-heading-new {
  color: #2F2F2F;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.sub-heading {
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
  color: #666;
}

.sub-heading-new {
  color: #505050;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.left-items {
  display: flex;
  justify-content: center;
  align-self: center;
}

.panel-heading {
  font-size: 14px;
  padding: 0px 0px 0px;

}

.icon {

  margin: auto;
  margin-right: 1rem;
}



.border-color-default {
  border-color: #e7ebeb !important;
}

.right-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-container {
  margin-right: .5rem;
  width: 240px;
  position: relative;
}

.search-container>.MuiSvgIcon-root {
  position: absolute;
  font-size: 32px;
  z-index: 999;
  right: 0px;
  top: 10px;
  color: #ccc;
}

.select-service-dropdown {
  width: 60px;
  position: relative;
  margin-left: .5rem;
}


.line {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: #124d9b;
}

.clear {
  clear: both;
}

.letter-holder {
  padding: 0px;
}

.letter {
  float: left;
  font-size: 14px;
  color: #666;
}

.loading {
  text-align: center;
}

.ring-1 {
  width: 7px;
  height: 7px;
  padding: 10px;
  border: 4px dashed #4b9cdb;
  border-radius: 100%;
}

.loading-text {
  font-size: 11px;
  margin: 0px 10px;
}

.load-4 {
  display: flex;
  align-items: center;
}

.load-4 .ring-1 {
  animation: loadingD 1.5s 0.3s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}

@keyframes loadingD {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* =Animate the stuff
------------------------ */
.loading-text .letter {
  animation-name: loadingF;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-direction: linear;
}

.l-1 {
  animation-delay: .48s;
}

.l-2 {
  animation-delay: .6s;
}

.l-3 {
  animation-delay: .72s;
}

.l-4 {
  animation-delay: .84s;
}

.l-5 {
  animation-delay: .96s;
}

.l-6 {
  animation-delay: 1.08s;
}

.l-7 {
  animation-delay: 1.2s;
}

.l-8 {
  animation-delay: 1.32s;
}

.l-9 {
  animation-delay: 1.44s;
}

.l-10 {
  animation-delay: 1.56s;
}

.loading .line:nth-last-child(1) {
  animation: loadingC .6s .1s linear infinite;
}

.loading .line:nth-last-child(2) {
  animation: loadingC .6s .2s linear infinite;
}

.loading .line:nth-last-child(3) {
  animation: loadingC .6s .3s linear infinite;
}

@keyframes loadingC {
  0% {
    transform: translate(0, 0)
  }

  50% {
    transform: translate(0, 15px)
  }

  100% {
    transform: translate(0, 0)
  }
}

@keyframes loadingF {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.content-center {
  height: 100vh;
  background-color: rgba(0, 0, 0, .2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.full-width {
  width: 100%;
}

.expensionPanelBody {
  height: 130px;
}

.servicePanel {
  padding: 0px 10px;
  height: 100%;
}

.expensionPanelTab {
  font-size: 12px;
  padding: 10px 8px;
  color: #9e9e9e !important;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #dedede;
}

.expensionPanelTab.active {
  color: #0086ff !important;
  border-bottom: 2px solid #0086ff !important;
}

.head {
  display: flex;
  align-items: center;
}

.text-section-main {
  font-size: 13px;
  font-weight: 400;
  color: #000;
  line-height: 1;
}

.text-section-sub {
  font-size: 11px;
  font-weight: 400;
  color: #666;
  line-height: 1.2;
}

.icon-section {
  margin-left: auto;
  display: flex;
  align-items: center;
  position: relative;
}

.icon-section .hover-content {
  color: #0096db;
  position: absolute;
  background-color: #fff;
  border-radius: 4px;
  width: 14rem;
  box-shadow: 0 4px 8px rgba(2, 4, 69, .08);
  z-index: 999999;
  display: none;
  top: 10px;
  left: 20px;
  clear: both;
  border: 1px solid #d8d8d8;
}

.icon-section:hover .hover-content {
  display: block;
}

.hover-content-link {
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 11px 20px 9px;
  color: #0096db;
}

.hover-content-link:hover {
  background: rgba(0, 0, 0, .03);
}

.hover-content-link-more-option {
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 11px 20px 9px;
  padding-left: 6px;
  color: #0096db;
}

.hover-content-link-more-option:hover {
  background: rgba(0, 0, 0, .03);
}

.chip-hover-content {
  position: absolute;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px;
  padding: 10px;
  width: 220px;
  height: 30rem;
  overflow-y: scroll;
  margin: auto;
  border: 1px solid #dedede;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: center;
}

.chip-hover-content::-webkit-scrollbar {
  display: none;
}

.chip.chip-success {
  border: 2px solid #68a46d;
  color: #68a46d;
  background-color: #fff;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  margin: 2px;
  padding: 2px 5px;
  display: inline-block;
  text-transform: capitalize;
}

.chip.chip-failed {
  border: 2px solid #d36961;
  color: #d36961;
  background-color: #fff;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  margin: 2px;
  padding: 2px 5px;
  display: inline-block;
  text-transform: capitalize;
}

.chip-hover-content>.makeStyles-inrfaProvider-321 {
  margin-bottom: 10px;
}

.chip-hover-content>.makeStyles-inrfaProvider-321:last-child {
  margin-bottom: 0px;
}


.group-name {
  font-size: 11px;
  color: #000;
  line-height: 1;
}

.user-count {
  font-size: 11px;
  color: #666;
  line-height: 1;
}

.env-body-274 {
  font-size: 11px;
  text-align: center;
  line-height: 1;
  color: #000;
}

.ml-auto {
  margin-left: auto !important;
}

.btn-icon {
  background: transparent;
  border: none;
  margin: 0px 3px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 4px;
  min-height: 32px;
}

.btn-icon-square {
  background: transparent;
  border: none;
  margin: 0px 3px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 4px;
  min-height: 32px;
}

.bg-purple-text-white {
  background-color: #6b74a8;
  color: #fff;
}

.bg-purple-text-white span[ class^="flaticon"] {
  color: #fff;
}

/*
	Chip style
*/

.chip{
  background-color: transparent;
  border-radius: 4px;
  padding: 4px 8px;
  color: #505050;
}
.chip-primary{
  background-color: #0086ff;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
}

.chip-primary-outline{
  border: 1px solid #DFEDFF;
  color: #0086ff;
  background-color: #F5FAFF;
}

.chip-success-outline{
  border: 1px solid #DFEDFF;
  color: #2EBE79;
  background-color: #E6FBEA;
}


.chip-default {
  font-size: 11px;
  justify-content: center;
  align-items: center;
  display: inline-block;
  line-height: 2;
  border-radius: 25px;
  padding: 2px 8px 0px;
  margin: 2px;
  text-align: center;
  max-width: 160px;
  min-width: 36px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.13)
}

.chip-default-blue {
  font-size: 12px;
  justify-content: center;
  align-items: center;
  display: inline-block;
  line-height: 2;
  border-radius: 25px;
  padding: 2px 8px 0px;
  margin: 2px;
  text-align: center;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.13);
  background-color: #3e73ec;
}

.chip-default-new {
  cursor: pointer;
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 6px 8px;
  gap: 10px;
  height: 27px;
  color: #505050;
  text-align: center;
  
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  background: #EBF5FF;
}

.chip-default-static {
  font-size: 11px;
  justify-content: center;
  align-items: center;
  display: inline-block;
  line-height: 2;
  border-radius: 25px;
  padding: 2px 8px;
  margin: 2px;
  text-align: center;
  max-width: 160px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.13)
}

.chip-default:hover {
  cursor: pointer !important;
  transition: ease-in-out 0.2ms;
}

.chip-default-job:hover {
  cursor: pointer !important;
  transition: ease-in-out 0.2ms;
}

.chip-blue {
  background-color: #fff;
  color: #0086ff;
}

.chip-border-dashed {
  background-color: rgb(252, 252, 252);
  border: 1px dashed #76b7ff;
  border-radius: 4px;
}

.chip-blue:hover {
  background-color: #0086ff;
  color: #fff !important;
  box-shadow: none;
}

.chip-blue:hover a {
  color: #fff !important;
}

.chip-red {
  color: #E53737!important;
  background: #FFEBEB !important;
}

.chip-dirt-green {
  background-color: #7393c8;
  color: #fff !important;
}

.chip-grey-rounded {
  background-color: #b1b9c7;
  color: #fff;
}

.chip-default a {
  color: #0086ff;
}

/*
	tooltip style
*/
.tooltip-text {
  font-size: 11px;
  line-height: 1;
  color: #0096db;
  position: relative;
  margin-left: .5rem;
}

.hover-content-tooltip {
  background-color: #000;
  color: #fff;
  position: absolute;
  padding: 10px;
  display: none;
  border-radius: 4px;
}

.tooltip-text:hover .hover-content-tooltip {
  display: block;
}

.hv-content {
  display: none;
  position: absolute;
  background-color: #000;
  padding: 10px;
  color: #fff
}

.hover:hover .hv-content {
  display: block;
}

/*
		Blank div 
*/
.no-data-div {
  margin: auto;
  text-align: center;
  padding: 20px;
  border: 3px dashed rgb(196, 196, 196);
  width: 300px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: rgb(251, 251, 251);
}

.no-data-div p {
  font-size: 12px;
  margin-bottom: 10px;
}

.blank-div {
  padding: 20px;
  text-align: center;
  width: 100%;
  margin: auto;
  display: grid;
  align-content: center;
  height: 100%;
  max-height: 100px;
}

.blank-div button>div {
  background-image: none;
  box-shadow: none;
  color: #0086ff;
}

.blank-div-text {
  font-size: 12px;
  padding: 15px;
}

.blank-div a {
  font-size: 12px;
  color: #0086ff;
  display: flex !important;
  justify-content: center;
  align-items: center;
  line-height: 1;
  border: 2px solid #0086ff;
  height: 35px;
  min-width: 140px;
  margin: auto;
  border-radius: 4px;
}

.blank-div a:hover {
  background-color: #0086ff;
  color: #fff;
  transition: ease-in-out 2ms;
}

.chip-default-fixed {
  font-size: 11px;
  justify-content: center;
  align-items: center;
  display: inline-block;
  line-height: 2;
  border-radius: 4px;
  padding: 0px 3px;
  margin: 3px 0px;
  text-align: center;
  width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-wrap {
  text-wrap: "nowrap",
}

.service-form-heading-section {
  /* padding-top: px; */
}

.service-form-heading {
  font-size: 30px;
  font-weight: 500;
  line-height: 1;
}

.hint-text-main-heading {
  font-size: 11px;
  color: #888;
}

.sub-heading-with-icon {
  display: flex;
  font-size: 11px;
}

.formDivwdb {
  padding: 2rem;
  margin: 3rem 0rem;
  width: 100%;
  border-radius: 8px;
}

.image-container .flaticon-show-apps-button {
  display: inline-block;
  margin: auto;
  color: #666 !important;
}

.image-container .flaticon-show-apps-button::before {
  font-size: 24px !important;
  margin: 0px;
  color: #0095da;
}

button:hover {
  cursor: pointer;
}

.image-container .sub-text {
  font-size: 11px;
  font-weight: 300;
  line-height: 1;
  color: #666;
}

.card-head-review {
  display: flex;
  border-bottom: 1px solid #b7b7b7;
  padding: .75rem;
  align-items: center;
  margin-bottom: 1rem;
}

.card-head-review .flaticon-settings-cogwheel-button {
  margin: 0px !important;
}

.card-head-review .flaticon-settings-cogwheel-button::before {
  margin: 0px !important;
  font-size: 21px !important;
}

.card-head-review img {
  height: 30px;
}

.btn-blue-next {
  color: #fff;
  width: 140px;
  border: none;
  height: 40px;
  padding: 0rem 0.25rem;
  font-weight: 500;
  background-color: #0086ff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

a.btn-blue-next:visited {
  color: #fff;
}

.btn-blue-next a:visited {
  color: #fff;
}

.btn-blue-next>.flaticon-keyboard-right-arrow-button {
  color: #fff;
}

.btn-blue-next>.flaticon-keyboard-right-arrow-button:before,
.flaticon-keyboard-right-arrow-button:before,
.flaticon-keyboard-right-arrow-button:after {
  font-family: Flaticon, sans-serif;
  font-size: 10px !important;
  font-style: normal;
  margin-left: 0px !important;
}

.card-service {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #b7b7b7;
  box-shadow: rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px;
  position: relative;
  z-index: 9;
  min-width: 500px;
  padding: 1rem 2.5rem;
}

.float-icon {
  display: flex;
  border: 2px solid #b7b7b7;
  color: #b7b7b7;
  vertical-align: middle;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin: 0px 20px;
}

.float-icon .MuiSvgIcon-root {
  font-size: 3rem;
}


.card-service-footer {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.m-rl-15 {
  margin-right: 1rem;
  margin-left: 1rem;
  line-height: 1;
}

.m-tb-3 {
  margin-top: 2px;
  margin-bottom: 2px;
}

.main-text {
  font-size: 11px;
  color: #666;
  margin-right: .5rem;
}

.sub-text {
  font-size: 11px;
  color: #666;
  text-decoration: none;
}

.sub-text a {
  text-decoration: none;
  color: #00bd9c
}

.sub-text-margin {
  margin-right: 4px;
}

.review-env-list {
  display: block;
}

.review-env-list .chip-default .chip-default-job {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}

.card-box {
  display: Grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  justify-content: space-between;
}

.secondary-heading {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: .55rem;
}

.transparent-btn-teal {
  margin: .75rem 0rem;
  font-size: 12px !important;
  color: #0096db;
  background-color: transparent;
  border: none;
}

.btn-green {
  padding: 1rem;
  min-width: 180px;
  max-width: 200px;
  box-shadow: 1px 1px 5px 1px #ccc;
  color: #fff;
  background-color: #00bd9c;
  font-size: 12px;
  font-weight: 700;
  border: none;
  border-radius: 4px;
  margin: 1.5rem 0rem 0rem;
  display: flex;
  justify-content: space-evenly;
}

/*Login page styles*/

.left-image-section {
  height: 100%;
  background-image: url('/public/images/login.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  border-radius: 20px;
}

.signup-left-image {
  height: 100%;
  background-image: url('/public/images/signup.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  border-radius: 20px;
}

.left-image-section-contain {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #253331;
}

.left-image-section .content,
.left-image-section-contain .content {
  margin: auto
}

.content-text {
  font-size: 4.2rem;
  font-weight: 300;
  line-height: 1;
  color: #fff;
}

.left-image-section-contain .content-text {
  margin: 2rem 0px 3rem;
}

.left-image-section-contain .content-text h1 {
  font-size: 3.6rem;
  font-weight: 300;
  line-height: 1.3;
  color: #fff;
}

.left-image-section-contain .content-text p {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.2;
  color: #fff;
}

.color-orange {
  color: #fc8f00;
}

.color-icon-primary {
  color: #dbdbdb;
}

.color-icon-secondary {
  color: #505050 !important;
}

.color-blue {
  color: #4990fc;
}

.color-green {
  color: #0db79b;
}

.disabled-url,
.disabled-url .MuiSvgIcon-root {
  color: #818078 !important;
}

.content-sub-text {
  margin: 20px 0px;
}

.content-sub-text p {
  color: #fff;
  font-size: 20px;
  font-weight: 100;
  line-height: 1.5;
}

.main-form-div {
  background: #FFFFFF;
  height: 100vh;
}

.form-container {
  width: 75%;
  height: 100%;
  display: flex;
  margin: auto;
  align-items: center;
  position: relative;
}

.form-container--1 {
  width: 75%;
  height: 100%;
  display: flex;
  margin: auto;
  align-items: center;
  position: relative;
}

a.demo-btn {
  color: #000;
  background-color: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.23);
  padding: 10px;
  width: 17rem;
  border-radius: 34px;
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

a.demo-btn .flaticon-play-rounded-button {
  display: inline-block;
  margin: 0px 10px 0px 10px;
  color: #fc8f00;
}

.left-image-section .content .content-sub-text a.demo-btn>.flaticon-play-rounded-button::before {
  line-height: 1;
  font-family: 'Flaticon', sans-serif;
  font-size: 24px !important;
  font-style: normal;
  margin-left: 0px !important;
  display: flex;
  align-items: center;
}

p.signup-link {
  font-size: 12px;
  color: #3b3f5c;
  font-weight: 400;
  margin-bottom: 50px;
}

.signup-link a {
  font-size: 12px;
  display: inline-block;
  color: #145CC6;
  text-decoration: none;
  background: #ECF2FA;
  padding: 0 4px;
  border-bottom: 2px dotted #145CC6;
}

.form-container svg {
  position: absolute;
  top: 16px;
  fill: #829693;
  overflow: hidden;
  vertical-align: middle;
}

input.textfield {
  display: inline-block;
  vertical-align: middle;
  border-radius: 0;
  min-width: 50px;
  max-width: 635px;
  width: 100%;
  min-height: 36px;
  background-color: #fafafa;
  border: none;
  position: initial;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  color: #3b3f5c;
  font-weight: 400;
  font-size: 14px;
  border-bottom: 1px solid #d2d6dc;
  padding: 0px 0 10px 35px;
}

.form-group input.textfield:focus {
  outline: none !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #124d9b !important;
}

.form-group {
  position: relative;
  border-bottom: none;
  margin-bottom: 15px;
}

input.textfield:-webkit-autofill,
input.textfield:-webkit-autofill:hover,
input.textfield:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

.btn__primary {
  color: #fff !important;
  background-color: #0086FF !important;
  border-color: #0086FF !important;
  border-radius: 6px;
  /*box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.23);*/
  padding: 11px 16px 11px 12px !important;
  min-width: 150px;
  text-transform: uppercase;
}

.btn__primary:hover{
  background-color: #0557A2 !important;
}

.btn-signin {
  display: inline-block;
  font-weight: 600;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  /* padding: .375rem .75rem; */
  font-size: 12px;
  line-height: 1.5;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  width: 100%;
  margin: 10px 0px 20px;
  text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);
}

.sign-in-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

a.forgeturl {
  font-weight: 600;
  color: #0086FF;
  text-align: center;
  display: block;
  margin-left: auto;
  font-size: 12px;
  justify-self: flex-end;
}

a.forgeturl:hover {
  text-decoration: underline;
}

.footer-content {
  margin-top: 3rem;
}

.copyright {
  max-width: 100%;
  /* margin: 0 auto; */
  color: #D0D0D0;
  font-weight: 600;
  margin-top: 30px;
  font-size: 12px;
  text-align: center;
}

.copyright a {
  color: #D0D0D0;
}

.error-msg-signin {
  padding: 10px;
  background-color: rgba(217, 83, 79, 0.1);
  position: absolute;
  line-height: 1;
  font-size: 10px;
  border: 1px solid #fcc2c3;
  top: 5.7rem;
}

.error-username {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  background-color: #FFEBEB;
  color: #E53737;
  line-height: 2;
  padding: 10px;
  border-radius: 6px;
}

@media (max-width:960px) {
  .section-left {
    display: none;
  }

  .form-container {
    margin: 20px auto;
  }
}

.input-component {
  width: 100%;
  display: block;
  margin-bottom: 1.5rem;
  position: relative;
}

.custom-drop {
  box-shadow: 0.877px 1.798px 14px 0px rgba(0, 0, 0, 0.15);
  width: 30%;
  height: fit-content;
  background-color: #fff;
  border-radius: 4px;
  display: block;
  align-items: center;
  position: relative;
  transition: all .5s ease-in-out;
}

.custom-drop-down-list {
  transition: all .5s ease-in-out;
  position: absolute;
  background: #fff;
  width: 100%;
  border: 1px solid #dedede;
  border-top: none;
  border-radius: 0px 0px 4px 4px;
}

.option>.selected {
  padding: 0px;
}

.btn-lg {
  font-size: 16px;
  font-weight: 900;
}

.option {
  padding: 5px;
  border-top: 1px solid #dedede;
  display: flex;
  align-items: center;
  width: 100%;
}

.option:hover {
  background-color: #eede;
}

.selected {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 20px 5px 5px;
}

.m-l-auto {
  margin-left: auto;
}

.service-name-dropdown {
  padding: 0px 10px;
  font-size: 16px;
  font-weight: 300;
}

.service-name-dropdown span.flaticon-downwards-arrow-key:before,
span.flaticon-downwards-arrow-key:before,
span.flaticon-downwards-arrow-key:after{
  font-size: 14px;
}

.service-name-dropdown span.flaticon-expand-arrow::before,
span.flaticon-expand-arrow:before,
span.flaticon-expand-arrow:after {
  font-size: 14px;
}

.heading-app-selector {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin-top: 20px;
  line-height: 1.5;
}

.select-application {
  display: flex;
  align-items: flex-start;
}

.height-57 {
  height: 57px;
}

.height-40 {
  height: 40px;
}

.height-fit-content {
  height: fit-content !important
}

.height-auto {
  height: auto !important;
}

.container-dropdown {
  width: 100%;
  display: flex;
  align-items: center;
}

/* LOADER */
.card-loading {}

.card-loading .loading-gif {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.card-loading .loading-gif .loader-bg-card {
  align-self: center !important;
  justify-self: center !important;
}

.loading-gif {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader-bg-card {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20rem;
  position: absolute;
  width: 400px;
  background-color: rgba(0, 0, 0, .2);
  border-radius: 8px;
  z-index: 9999;
}

.loader-bg-card-small {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20rem;
  width: 30rem;
}

.loader img {
  width: 80px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  z-index: 999;
}

.loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: absolute;
  z-index: 9999;
  top: 6rem;
  align-self: center;
}

.loader-dots {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

/* LOADER 2 */

#loader-2 span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #3498db;
  margin: 35px 5px;
}

#loader-2 span:nth-child(1) {
  animation: bounce 1s ease-in-out infinite;
}

#loader-2 span:nth-child(2) {
  animation: bounce 1s ease-in-out 0.33s infinite;
}

#loader-2 span:nth-child(3) {
  animation: bounce 1s ease-in-out 0.66s infinite;
}

@keyframes bounce {

  0%,
  75%,
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  25% {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

#loader-1:before,
#loader-1:after {
  content: "";
  position: absolute;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 10px solid transparent;
  border-top-color: #3498db;
}

#loader-1:before {
  z-index: 100;
  animation: spin 1s infinite;
}

#loader-1:after {
  border: 10px solid #ccc;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Top Nav Dropdow */
.dropdown-container {
  position: relative;
}

.dropdown-box {
  display: block;
  margin: 10px;
  top: 42px;
  position: absolute;
  background-color: #FFFFFF;
  width: 120px;
  border-radius: 4px;
  right: -25px;
  box-shadow: 0 4px 8px rgba(2, 4, 69, .08);
}

.dropdown-box ul {
  list-style: none;
}

.dropdown-box ul li {
  padding: 10px;
  border-bottom: 1px solid #dedede;
}

.dropdown-box ul li a {
  font-size: 11px;
  color: #0086ff;
}

.dropdown-box ul li a.logout-btn span.flaticon-move-window {
  color: #fff;
}

.dropdown-box ul li:last-child {
  border-bottom: none;
}



.dropdown-box::before {
  content: "";
  position: absolute;
  pointer-events: none;
  bottom: 100%;
  left: 75px;
  outline-offset: 1px solid #e7e7e7;
  height: 0;
  width: 0;
  border-bottom: 12px solid #fff;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;

}

.hide-class {
  display: none;
}

.show-class {
  color: #0096db;
  position: absolute;
  background-color: #fff;
  border-radius: 4px;
  width: 14rem;
  box-shadow: 0 4px 8px rgba(2, 4, 69, .08);
  z-index: 999999;
  top: 10px;
  left: 20px;
  clear: both;
  display: block;
  border: 1px solid #d8d8d8;
}

.data-card .data-card-icon>span.flaticon-nfc-near-field-communication::before {
  font-size: 26px !important;
  color: #9397ca !important;
}

.MuiTypography-h6 {
  color: #000000 !important;
  font-size: 18px !important;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.05px;
}

.btn-anchor {
  font-size: 12px;
  color: #0086ff;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  border: 2px solid #0086ff;
  height: 35px;
  padding: 0px 15px;
  border-radius: 4px;
}

.btn-anchor:hover {
  background-color: #0086ff;
  color: #fff;
  transition: ease-in-out 2ms;
}

.bg-white {
  background-color: #fff !important;
}

.bg-light-grey {
  background-color: #fbfbfb !important;
}

.bg-fc-grey {
  background-color: #fcfcfc !important;
}

.bg-fa-grey {
  background-color: #fafafa !important;
}

.bg-f5-grey {
  background-color: #f5f6f7 !important;
}

.bg-f4-grey {
  background-color: #f4f4f4 !important;
}

.bg-f9-grey {
  background-color: #f9f9f9 !important;
}

.bg-f8-grey {
  background-color: #f8f8f8 !important;
}

.bg-f8-grey .card-header {
  background-color: #f8f8f8 !important;
  border-bottom: 1px solid #dedede !important;
}

.bg-f8-grey .card-header.with-btn {
  padding-right: 0px !important;
}

.bg-green {
  background-color: #44de85;
}

.MuiPopover-paper {
  height: fit-content;
}

/*App Dashboard Add User popup*/
.MuiDialog-paperScrollPaper {
  width: 50rem;
}

.btn-dialogue {
  padding: 6px 16px;
  min-width: 70px;
  box-shadow: 1px 1px 5px 1px #ccc;
  color: #fff;
  margin: 15px;
  line-height: 2;
  background-color: #0095da;
  font-size: 12px;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-dialogue-close {
  padding: 10px 20px;
  /* min-width: 100px; */
  color: #000;
  background-color: #f9f9f9;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  height: 100%;
  /* line-height: 1.5; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #c9c9c9;
}

.btn-float::after {
  content: "";
  clear: both;
}

:-webkit-full-screen {
  overflow-y: scroll;
}

/* Firefox syntax */
:-moz-full-screen {
  overflow-y: scroll;
}

/* IE/Edge syntax */
:-ms-fullscreen {
  overflow-y: scroll;
}

/* Standard syntax */
:fullscreen {
  overflow-y: scroll;
  height: 100%;
}

.text-width-300 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
}

.text-width-120 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 120px;
}

.text-width-100 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px;
}

#viewFullLogs:fullscreen .logs-window {
  height: 94vh !important;
  overflow-y: scroll;
}

#podsLogViewSCreen:fullscreen .logbody {
  height: 100%;
}

.change-value-btn {
  display: flex;
  flex-flow: column;
  position: absolute;
  background-color: #fff;
  border-left: 1px solid #dedede;
  right: .2rem;
  height: inherit;
  bottom: 1px;
  padding: 0px 3px;
  z-index: 1;
}

.change-value-btn span[ class^="flaticon"] {
  margin: 0px;
  line-height: 1;
  color: #a4a4a4;
  position: relative;
  z-index: 99;
  cursor: pointer;
}

.change-value-btn span[ class^="flaticon"]::before {
  font-size: 10px !important;
}

.slider-with-input .range-slider-input {
  height: 32px;
  border: none;
  outline: none;
}

.slider-with-input .input-component label {
  color: #000;
}

.input-with-btn {
  border: 1px solid #d5d5d5;
  overflow: hidden;
  border-radius: 4px;
  margin-left: 5px;
  position: relative;
}

.table-section {
  font-size: 11px;
}

a.btn:hover {
  background-color: #0086ff;
  transition: ease-in-out .2s;
}

a.btn:hover span[ class^="flaticon"] {
  color: #fff;
  transition: ease-in-out .2s;
}

.btn-round-sm {
  width: 20px !important;
  height: 20px !important;
}

.btn-round-sm.btn-round {
  margin-left: 0px !important;
}

.add-button {
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.1);
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 11px;
  background-image: linear-gradient(-90deg, rgb(0, 150, 219) 0%, rgb(20, 107, 236) 100%);
  padding: 5px 12px;
  color: #fff;
  border-radius: 4px;
}

.add-button .btn {
  background-color: #4fb2f1;
  color: #fff;
}

.add-button .btn .flaticon-add-plus-button {
  color: #fff
}

.btn-submit {
  color: #fff !important;
  background-color: #3696db !important;
}

.btn-submit:hover {
  background-color: #2a7ebb !important;
}

.btn-round-sm .flaticon-add-plus-button::before {
  font-size: 10px;
}

.btn-round:hover span.flaticon-refresh-button-1 {
  color: #fff !important;
}

.btn-round:hover span.flaticon-refresh-button-1::before {
  color: #fff !important;
}

.btn-round span.flaticon-refresh-button-1::before {
  color: #fff !important;
  font-size: 10px;
}

.btn-job:hover span.flaticon-refresh-button-job {
  color: #fff !important;
}

.btn-job:hover span.flaticon-refresh-button-job::before {
  color: #fff !important;
}

/* .btn-job span.flaticon-refresh-button-job::before{
	color: #fff!important;
	font-size:10px;
} */
button.bg-clear-btn:hover {
  background-color: rgb(0, 136, 255) !important;
  color: #fff !important;
}

button.btn-small-icon:hover {
  background-color: #008aca;
  transition: ease-in-out .2s;
  color: #fff !important;
}

button.btn-small-icon:hover span[ class^="flaticon"] {
  background-color: #008aca !important;
  color: #fff !important;
}

.button-round {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  filter: drop-shadow(0.877px 1.798px 4px rgba(0, 0, 0, 0.06));
  background-color: transparent;
  color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.28);
}

.button-round-primary {
  color: #0945a1;
  background-color: #fff;
}

.button-round-danger {
  color: #ff4a4a;
  background-color: #fff;
}

.button-round-danger:hover {
  background-color: rgb(255, 255, 255, 0.7);
}

/*===============Resizeable Div=========================*/

.resizable {
  background: #000;
  width: 100%;
  box-shadow: 0.877px 1.798px 14px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background-color: #000;
  min-height: 80px;

}

.resizable .header {
  color: #fff;
  height: 24px;
  display: flex;
  font-size: 12px;
  overflow-x: scroll;
  align-items: center;
  border-bottom: 1px solid #888;
  justify-content: space-between;
  background-color: #000;
}

.resizable .header .right-cont button {
  border: none;
  background-color: transparent;
}

.resizable .header .tab {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.resizable .body .breadcrumb {
  color: #fff;
  padding: 0px 10px;
  font-size: 10px;
  border-bottom: 1px solid #888;
}

.resizable .body p {
  color: #fff;
  font-size: 11px;
}

.resizable .resizers {
  border: 3px solid #000;
  box-sizing: border-box;
  float: right;
}

.resizable .resizers .resizer {
  position: absolute;
}

.resizable .resizers .flaticon-ellipsis {
  color: #fff;
  background-color: #000;
  border-radius: 20%;
  background-position: center;
  height: 20px;
  line-height: .5;
  padding: 0 5px;
}

.resizable .resizers .flaticon-ellipsis::before {
  font-size: 24px !important;
}

.resizable .resizers .resizer.top-left {
  left: 50%;
  top: -1px;
  cursor: row-resize;
}

.na-class {
  font-size: 11px;
  line-height: 2;
  color: #000;
}

.progress-bar-disabled {
  background-color: #e4e4e4 !important;
}

.auto-scale-disabled {
  font-size: 9px;
  top: .4rem;
  left: 11rem;
  font-weight: 500;
  position: absolute;
}

.color-key {
  color: #a0a0a0 !important;
}

.color-key-78 {
  color: #787878 !important;
}

.color-value {
  color: #2f2f2f !important;
}

.build-type {
  color: #000;
  
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.color-dark-blue {
  color: #124D9B !important;
}

.color-asterisk {
  color: #C11212 !important;
}

.color-key-gray {
  color: #626262 !important;
}

.color-key-primary {
  color: #0086FF !important;
}

.color-key-00 {
  color: #000 !important;
}

@media screen and (max-width:1280px) {
  .auto-scale-disabled {
    left: 8rem;
  }
}

.MuiCheckbox-root .MuiSvgIcon-root {
  font-size: 2.5rem;
}

body::-webkit-scrollbar {
  width: .5em !important;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3) !important;
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey !important;
  outline: 1px solid slategrey !important;
}

.pd-rl-5px {
  padding-left: 5px;
  padding-right: 5px;
}

.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.pd-l-5 {
  padding-left: 5px;
}

.pd-l-10 {
  padding-left: 10px;
}

.pd-r-5 {
  padding-right: 5px;
}

.pd-0 {
  padding: 0px !important
}

.pd-10 {
  padding: 10px
}

.pd-5 {
  padding: 5px;
}

.pd-15 {
  padding: 15px
}

.pd-20 {
  padding: 20px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-8 {
  padding-bottom: 8px;
}


.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pl-6 {
  padding-left: 6px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-18 {
  padding-left: 18px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-22 {
  padding-left: 22px !important;
}

.pb-22 {
  padding-bottom: 22px !important;
}

.pr-22 {
  padding-right: 22px !important;
}


.pl-25 {
  padding-left: 25px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.pl-30 {
  padding-left: 30px;
}

.pr-12 {
  padding-right: 12px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.loading-gif-container {
  width: 100%;
  top: 0px;
  left: 0px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loading-overlay {
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  z-index: 0;
}

.loading-gif-container-two {
  width: 100%;
  top: 0px;
  left: 0px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  height: inherit;
}

.loading-gif-container-two>.loading-overlay {
  background-color: transparent;
}

.loading-gif-container-two>.loader-bg-card-2 {
  background-color: transparent;
}

.loading-gif-container-two>.loader-bg-card-2>.loader {
  height: 50px;
  width: 50px;
}

.loader-bg-card-2 {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* height: 20rem; */
  /* position: absolute; */
  /* width: 400px; */
  background-color: rgba(0, 0, 0, .2);
  border-radius: 8px;
  z-index: 9999;
  margin: auto;
}

.loading-gif-container-two>.loader-bg-card-2>.loader#loader-1:after {
  border: 6px solid #ccc;
}

.top-0px {
  top: 0px !important;
  position: relative;
}

.loading-gif-container-two .loader#loader-1:before,
.loading-gif-container-two .loader#loader-1:after {
  content: "";
  position: absolute;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 6px solid transparent;
  border-top-color: #3498db;
}

.loading-controller .loading-gif-container-two .loader#loader-1:before {
  top: 0px !important;
}

.loading-controller .loading-gif-container-two .loader#loader-1:after {
  top: 0px !important;
}

.text-black .text-lite-purple {
  color: #5f6e9e;
  display: flex;
  align-items: center;
}

#viewFullScreen {
  overflow: scroll;
  height: 46rem;
}

pre#viewFullScreen {
  height: 417px !important;
}

.global-error {
  margin-top: 64px;
  font-size: 11px;
  border: 2px solid #e9797e;
  color: #D8000C;
  padding: 12px;
}

.global-error h2 {
  font-size: 18px;
  font-weight: 400;
  color: #e9474f;
}

.input-with-btn input.range-slider-input {
  width: 48px;
  padding: 4px;
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 8px;
  height: 20px;
  top: 25rem;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-position: bottom right;
  padding: 0 3px 3px 0;
}

.react-resizable-handle::after,
.react-resizable-handle::before {
  content: "";
  border-left: 1px solid #333;
  position: absolute;
  top: 50%;
  transform: translateY(-100%);
  right: 0;
  display: inline-block;
  height: 20px;
  margin: 0 2px;
}

.react-resizable-handle::before {
  left: 0;
}

.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}

.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: col-resize;
}

.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}

.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}

.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}

.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}

.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}

.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}

.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}

.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

input::-moz-focus-outer {
  outline: none;
  border: none;
}

.search-bar input:focus {
  outline: none !important;
  border: none !important;
}

.btn.btn-grey-outline:hover {
  background-color: #fff;
  color: #7f8288 !important;
}

.search-filter-button {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  outline: none;
  position: relative;
  z-index: 0;
  font-size: 1.2rem;
  letter-spacing: .25px;
  background: none;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: 500;
  height: 36px;
  min-width: 80px;
  padding: 0 16px;
  background-color: #1a73e8;
  color: #fff;
  border: none;
}

.search-filter-button:hover {
  background-color: #297be6;
  outline: 1px solid transparent;
  border: none;
}

.clear-filter-button {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  outline: none;
  position: relative;
  z-index: 0;
  font-size: 1.2rem;
  letter-spacing: .25px;
  background: none;
  border-radius: 4px;
  box-sizing: border-box;
  color: #5f6368;
  cursor: pointer;
  height: 36px;
  min-width: 80px;
  padding: 0 16px;
  background-color: #fff;
  font-weight: bold;
  border: none;
}

.clear-filter-button:hover {
  background-color: rgba(0, 0, 0, .2);
  transition: 500ms ease-in;
}

.input-with-icon {
  position: relative;
}

.input-with-icon>.input-icon {
  line-height: 0px;
  position: absolute;
  z-index: 99;
  top: 0px;
  right: 20px;
  margin-right: 0rem;
}

.input-with-icon>.input-icon .MuiSvgIcon-root {
  font-size: 2rem;
}

.btn-saas-fullwidth {
  background-color: #0db79b;
  border-color: #0db79b;
  border: 1px solid #0db79b;
  color: #fff;
  padding: 1rem;
  width: 100%;
  margin-top: 0px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.signup-heading {
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.checkbox-input {
  display: grid;
  grid-template-columns: 3% auto;
  gap: 2%;
  align-items: center;
}

.checkbox-input input {
  display: inline-block;
}

.checkbox-input label {
  font-size: 13px;
  display: inline-block;
}

.checkbox-input label a {
  color: #000;
  text-decoration: underline;
}

.input-with-icon .input-component {
  margin-bottom: 2.2rem;
}

.signupform .input-with-icon>.input-icon {
  right: 5%;
}

.simple-menu .MuiList-padding,
.pt-0 {
  padding-top: 0px !important;
}

.align-center {
  align-items: center;
}

.align-base {
  align-items: baseline;
}

.align-flex-end {
  align-items: flex-end;
}

.service-env {
  font-size: 11px;
  margin-right: 3px;
  letter-spacing: 1px;
}

.service-env-tyoe {
  font-size: 11px;
  letter-spacing: 1px;
}

.row-cardbody p .btn:hover {
  background-color: transparent;
  color: #0086ff !important;
}

.tab-with-refresh {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ebedee;
  border-radius: 4px 4px 0px 0px;
  background-color: #f6f6f6;
}

.tab-with-refresh .headerul {
  border-bottom: none !important;
}

input.upload-config {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 148px;
  height: 34px;
}

.bg-input-lite .input-component input {
  background-color: #f8f8f8
}

.status-chip {
  background-color: transparent;
  color: transparent;
  border-color: transparent;
  display: flex;
  align-items: center;
  padding: .6rem .75rem;
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: center;
  text-transform: uppercase;
  border-radius: 24px;
  margin-left: 3px;
  margin-right: 3px;
}

.status-chip-success {
  color: #fff;
  background-color: #69e09c !important;
}

.status-chip>[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  font-size: 14px !important;
}

.status-chip>span[ class^="flaticon"] {
  margin: 0px 3px 0px 0px;
}

.status-chip-success [class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  color: #fff;
}

.status-chip-danger {
  color: #fff;
  background-color: #ff8969;
}

.status-chip-danger [class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  color: #fff;
}

.status-chip-info {
  color: #fff;
  background-color: #004085;
}

.status-chip-info [class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  color: #fff;
}

.status-chip-warning {
  color: #fff;
  background-color: #fedf5c !important;
}

.status-chip-disapproved {
  color: #fff;
  background-color: #ff8969 !important;
}

.status-chip-disapproved [class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  color: #fff;
}

.status-sea-blue {
  background-color: #76dce5 !important;
  color: #fff;
}

.status-chip-warning [class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  color: #fff;
}

.status-chip-approval {
  color: #fff;
  background-color: #c294f1;
}

.status-chip-approval [class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  color: #fff;
}

.status-chip-revoked {
  color: #fff;
  background-color: #6b74a8;
}

.status-chip-stopped {
  color: #fff;
  background-color: #e9797e;
}

.status-chip-revoked [class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  color: #fff;
}

.status-chip-running {
  color: #fff;
  background-color: #0086ff;
}

.status-chip-running [class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  color: #fff;
}

.status-bullet {
  height: 10px;
  width: 10px;
  background-color: transparent;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  margin-right: 3px;
}

.status-bullet-success {
  background-color: #69e09c;
}

.status-bullet-danger {
  background-color: #ff8969;
}

.status-bullet-pending {
  background-color: #ffd200;
}

.overflow-hidden {
  overflow: hidden !important;
}

.status-bullet-grey {
  background-color: #838383;
}

.color-white {
  color: #fff !important;
}
.w-100 {
  width: 100% !important;

}

.gap-8 {
  gap: 8px !important;

}

.overflow-hidden {
  overflow: hidden !important;

}

.word-wrap-break {
  word-wrap: break-word !important;

}

.align-start {
  align-items: start !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.display-inline-flex {
  display: inline-flex !important;

}

.justify-flex-start {
  justify-content: flex-start !important;

}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-flex-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.align-self-top {
  align-self: flex-start !important;
}

.align-self-center {
  align-self: center !important;
}

.align-flex-start {
  align-items: flex-start !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.justify-flex-start {
  justify-content: flex-start !important;
}

.d-flex-wrap {
  flex-flow: wrap !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.space-between {
  justify-content: space-between !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-evenly {
  justify-content: space-evenly !important;
}

.justify-flexend {
  justify-content: flex-end !important;
}

.justify-flexstart {
  justify-content: flex-start !important;
}

.f-direction-column {
  flex-direction: column;
}

.text-align-center {
  text-align: center !important;
}

/* -----------------------------------------gaps---------------------------------- */
.gap-4 {
  gap: 4px !important;
}

.gap-3 {
  gap: 3px !important;
}

.gap-10 {
  gap: 10px !important;
}

/* -------------------------------------------------border radius------------------------------ */
.border-radius-6 {
  border-radius: 6px !important;
}

.border-radius-4 {
  border-radius: 4px !important;
}

.border-radius-0 {
  border-radius: 0px !important;
}

.border-radius-8 {
  border-radius: 8px !important;
}

/* ------------------paddint-------------- */
.p-10 {
  padding: 10px !important;
}

.btn-add-primary {
  color: #fff !important;
  display: flex;
  padding: 8px 12px;
  border-color: transparent;
  font-size: 11px;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.1);
  align-items: center;
  border-radius: 4px;
  background-image: linear-gradient(-90deg, rgb(0, 150, 219) 0%, rgb(20, 107, 236) 100%);
}

.icon-round {
  background-color: #4fb2f1;
  border-radius: 4px;
  padding: 3px;
  margin-right: 3px;
}

.icon-round [class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  font-size: 11px !important;
  line-height: 1;
}

.width-fitcontent {
  width: fit-content !important;
}

.width-full {
  width: 100% !important;
}

.count-chip {
  background-color: transparent;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  padding: 3px !important;
  width: fit-content;
  color: transparent;
  border-radius: 4px;
  margin-right: 3px;
  margin-left: 3px;
  cursor: pointer;
}

.count-chip-default {
  color: #fff;
  background-color: #788695;
}

.count-chip-danger {
  background-color: #ff8969 !important;
  color: #fff !important;
  border: 2px solid #ff8969;
}

.count-chip-success {
  background-color: #69e09c !important;
  color: #fff !important;
  border: 2px solid #69e09c !important;
}

.count-chip-pending {
  background-color: #fedf5c !important;
  color: #fff !important;
  border: 2px solid #fedf5c !important;
}

.count-chip-info {
  background-color: #72bdd3 !important;
  color: #fff !important;
  border: 2px solid #72bdd3 !important;
}

.count-chip-primary {
  background-color: #3d73eb !important;
  color: #fff !important;
  border: 2px solid #3d73eb !important;
}

.count-chip-primary-outline .MuiSvgIcon-root {
  color: transparent !important;
}

.count-chip-primary-outline {
  background-color: transparent !important;
  color: #3d73eb !important;
  border: 2px solid #3d73eb !important;
}

.count-chip-grey-outline {
  background-color: transparent !important;
  color: #9ba6aa !important;
  border: 2px solid #9ba6aa !important;
}

.count-chip-info-outline {
  background-color: transparent !important;
  color: #72bdd3 !important;
  border: 2px solid #72bdd3 !important;
}

.count-chip-grey-outline .MuiSvgIcon-root {
  color: transparent !important;
}

.count-chip-success-outline {
  background-color: transparent !important;
  color: #69e09c !important;
  border: 2px solid #69e09c !important;
}

.count-chip-pending-outline {
  background-color: transparent !important;
  color: #fedf5c !important;
  border: 2px solid #fedf5c !important;
}

.count-chip-danger-outline {
  background-color: transparent !important;
  color: #ff8969;
  border: 2px solid #ff8969;
}

.nowrap {
  white-space: nowrap !important;
}

.count-chip-lg {
  padding: .5rem 1rem !important;
}

.count-chip-lg-fix {
  width: 35px !important;
  height: 35px !important;
  position: relative;
  z-index: 99;
  display: flex;
}

.count-chip-lg-fix-round {
  width: 35px !important;
  height: 35px !important;
  position: relative;
  z-index: 99;
  display: flex;
  border-radius: 50%
}

.count-chip-round {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.count-chip-success .MuiCheckbox-root {
  color: #69e09c !important;
}

.count-chip-success-outline .MuiCheckbox-root {
  color: transparent !important;
}

.count-chip-success .MuiCheckbox-root:hover,
.count-chip-success-outline .MuiCheckbox-root:hover {
  background-color: transparent !important;
}

.count-chip-primary .MuiCheckbox-root {
  color: #3d73eb !important;
}

.count-chip-primary-outline .MuiCheckbox-root {
  color: transparent !important;
}

.count-chip-primary .MuiCheckbox-root:hover,
.count-chip-primary-outline .MuiCheckbox-root:hover {
  background-color: transparent !important;
}

.count-chip-danger .MuiCheckbox-root {
  color: #ff8969 !important;
}

.count-chip-danger-outline .MuiCheckbox-root {
  color: transparent !important;
}

.count-chip-danger .MuiCheckbox-root:hover,
.count-chip-danger-outline .MuiCheckbox-root:hover {
  background-color: transparent !important;
}

.count-chip-grey-outline .MuiCheckbox-root {
  color: transparent !important;
}

.count-chip-grey-outline .MuiCheckbox-root:hover {
  background-color: transparent !important;
}

.count-chip-pending .MuiCheckbox-root {
  color: #fedf5c !important;
}

.count-chip-pending-outline .MuiCheckbox-root {
  color: transparent !important;
}

.count-chip-pending .MuiCheckbox-root:hover,
.count-chip-pending-outline .MuiCheckbox-root:hover {
  background-color: transparent !important;
}

.count-chip-success-outline .text-of-chip {
  color: #69e09c !important;
}

.count-chip-pending-outline .text-of-chip {
  color: #fedf5c !important;
}

.count-chip-danger-outline .text-of-chip {
  color: #ff8969 !important;
}

.count-chip-grey-outline .text-of-chip {
  color: #9ba6aa !important;
}

.count-chip .text-of-chip {
  position: relative;
  z-index: 999;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  color: #fff;
  text-align: center;
  vertical-align: -webkit-baseline-middle;
  margin: auto;
}

.count-chip .MuiCheckbox-root {
  position: absolute;
  top: -8px;
  left: -14px;
  z-index: 9;
}

.count-chip-xl {
  padding: .75rem 1rem .5rem !important;
  font-size: 24px;
}

.text-color-blue {
  font-size: 12px;
  color: #0086ff;
  margin-right: 10px;
}

.text-color-grey {
  color: #989898 !important;
}

.text-white {
  color: #fff !important;
}

.input-color {
  color: #828282;
}

.text-color-blue:last-child {
  margin-right: 0px;
}
.color-primary{
  color: #2f2f2f !important;
}
.color-link{
  color: #0086ff;
}
.color-link:visited{
  color: #0086ff;
}
.color-link:focus{
  color: #0086ff;
}
.color-secondary{
  color: #505050;
}
.color-tertiary{
  color: #787878;
}
.color-black{
  color: #000!important;
}
.small-text {
  font-size: 12px !important;
}

.color-red {
  color: #ff8969 !important;
}

.color-success {
  color: #2EBE79 !important
}

.color-danger {
  color: #e9797e !important
}

.color-failed {
  color: #e9797e !important
}

.color-failed-new {
  color: #C11212 !important;
}

.color-pending {
  color: #ffc002 !important
}

.color-running {
  color: #ffc002 !important
}

.color-white {
  color: #fff !important
}

.color-revoked {
  color: #c294f1 !important
}

.color-stopped {
  color: #e9797e !important
}

.color-terminating-blue {
  color: #004085;
}

.color-unknown {
  color: #c294f1;
}

.color-error-in-container {
  color: #FF6961
}

.color-container-not-ready {
  color: #FFCB2E
}

.color-nav {
  color: #8FA0B3;
}

.color-icon-nav {
  color: #DFEDFF;
}

.line-normal {
  line-height: normal;
}

.color-red-failed {
  color: #C11212;
}

.word-break {
  word-break: break-word !important;
}

.word-wrap-break {
  word-wrap: break-word !important;
}

.SubHeader-manifest {
  height: 50px;
  margin: 5px -15px 5px;
  display: flex;
  padding: 0px 15px;
  border-top: 1px solid #dedede;
  align-items: center;
  border-bottom: 1px solid #dedede;
  justify-content: space-between;
  background-color: #f6f6f6;
  font-size: 12px;
  color: #666;
}

.SubHeader-manifest .btn-clear span[ class^="flaticon"] {
  margin: 0px 4px 0px 10px;
}

.SubHeader-manifest .btn-clear span[ class^="flaticon"]:before {
  font-size: 11px;
}

.btn-lite-blue {
  color: #0086ff !important;
}

.btn-lite-blue:hover {
  color: #0088ffce !important;
}

.table-grid-header {
  font-size: 12px;
  display: grid;
  padding: 5px 10px;
  border-bottom: 2px solid #000;
  color: #000
}

.table-grid {
  overflow-y: scroll;
  height: 390px;
}

.table-grid-header>p {
  padding: 5px 10px;
}

.table-grid-body {
  font-size: 12px;
  display: grid;
  padding: 5px 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-bottom: 1px solid #d5d5d5;
}

.table-grid-body:last-child {
  border-bottom: none;
}

.table-grid-body>div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #878787;
  padding: 5px 10px;
}

.table-grid-body div a {
  color: #0086ff;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.table-grid-body div p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.table-grid-body div a:visited {
  color: #0086ff;
}

.grid-temp-nine {
  grid-template-columns: 12% 8% 8% 12% 12% 12% 12% auto 8%;
}

.grid-temp-six {
  grid-template-columns: 15% 15% 20% 20% 20% 10%;
}

.grid-temp-three {
  grid-template-columns: 45% 45% auto;
}

.grid-temp-four {
  grid-template-columns: 25% 25% 25% auto;
  gap: 10px;
}

.grid-temp-five {
  grid-template-columns: 25% 15% 25% 15% auto;
  gap: 10px;
}


.pod-lable {
  background-color: #ececec;
  margin: 2px;
  padding: 5px 8px;
  border-radius: 4px;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  width: fit-content;
  font-size: 10px;
  color: #878787;
}

.pods-full-width .pod-lable {
  max-width: fit-content !important;
}

.MuiListItem-root .pod-lable {
  max-width: 220px;
}

.pod-lable:first-child {
  margin-left: 0px;
}

.pod-lable:last-child {
  margin-right: 0px;
}

.label-box {
  width: 350px;
  padding: 0px 20px;
}

.help-text {
  color: #0086ff;
}

.MuiListItem-button:hover {
  background-color: transparent !important;
}

.MuiPopover-paper::-webkit-scrollbar {
  display: none
}

.title-dialogue {
  font-size: 13px;
  font-weight: 400;
}

.dialogue-header {
  background-color: #f6f8f8;
  border-bottom: 1px solid #dedede;
  justify-content: space-between;
  padding: 10px;
}

.dialogue-heading {
  display: flex;
  font-size: 16px;
  line-height: 1.2;
}

.d-grid {
  display: grid;
}

.sub-header {
  gap: 10px;
  align-items: center;
  border-bottom: 2px solid #000;
  padding: 0px 10px;
  line-height: 1.5;
  font-size: 13px;
  color: #000;
}

.sub-header.grid-temp-three {
  grid-template-columns: 40% 40% auto;
  gap: 10px;
  font-size: 12px;
  align-items: center;
  border-bottom: 2px solid #000;
}

.hover-white:hover {
  color: #fff !important;
}

.colo .pd-15px-lr {
  padding: 0px 15px;
}

.grid-temp-three-dialogue {
  grid-template-columns: 40% 40% auto;
  gap: 0px 10px;
}

.input-addrow {
  border: 1px solid #dedede;
  border-radius: 4px;
  margin-bottom: 10px;
}

.grid-temp-three-dialogue .input-component label {
  display: none;
}

.grid-temp-three-dialogue .input-component:first-child label:first-child {
  display: inline;
}

.grid-temp-three-dialogue .input-component:nth-child(2) label:first-child {
  display: inline;
}

.MuiDialogContent-root {
  padding: 8px !important;
}

.MuiDialogActions-root {
  justify-content: space-between !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  border-top: 1px solid #dedede;
}

.MuiDialogActions-root .btn-blue-navi {
  padding: 10px !important;
  text-align: center;
  line-height: 1.4;
  justify-content: center;
}

.MuiSnackbar-root.MuiSnackbar-anchorOriginTopRight {
  top: 105px !important;
  left: auto;
  right: 65px !important;
}

.heading-text {
  font-size: 12px;
  line-height: 1.2;
  padding: 10px 0px;
}

.labels-box .tags-input {
  min-height: 150px;
}

.body-part .text-black {
  font-size: 12px;
}

.text-color-black {
  color: #000 !important;
}

.text-color-181c20 {
  color: #181c20 !important;
  font-weight: 500 !important;
}

.text-color-light-gray {
  color: #989898 !important;
}


.font-10 {
  font-size: 10px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-17 {
  font-size: 17px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-26 {
  font-size: 27px !important;
}

.font-27 {
  font-size: 27px !important;
}

.font-28 {
  font-size: 28px !important;
}

.font-30 {
  font-size: 30px !important;
}

.font-32 {
  font-size: 32px !important;
}

.font-35 {
  font-size: 35px !important;
}

.font-36 {
  font-size: 36px !important;
}

.font-42 {
  font-size: 42px !important;
}

.font-48 {
  font-size: 48px !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.MuiDialog-root .MuiBackdrop-root {
  backdrop-filter: blur(8px);
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-12 {
  line-height: 1.2;
}

.lh-13 {
  line-height: 1.3;
}

.text-grey-83 {
  color: #838383 !important;
}

.text-grey-new {
  color: #2F2F2F !important;
}

.text-grey-72 {
  color: #2a2a2a !important;
}

.text-grey-b6 {
  color: #b6b6b6 !important;
}

.text-grey-6e {
  color: #6e6e6e !important;
}

.text-grey-9e {
  color: #9e9e9e !important
}

.text-grey-icon {
  color: #979fa7 !important
}

.text-grey-af {
  color: #afadad !important
}

.text-color-dark-gray {
  color: #686868 !important;
}

.text-color-grey-mid {
  color: #2f2f2f !important;
}

.body-part .text-gray {
  font-size: 12px;
  color: #989898;
}

.body-part .text-blue-anchor {
  color: #0086ff;
  font-size: 12px;
}

.progress-label.text-gray {
  color: #989898;
}

.text-blue-anchor {
  color: #0086ff !important;
  font-size: 12px;
}

.fix-contnet-200 {
  width: 200px !important;
  white-space: normal !important;
}

.text-blue-anchor:visited {
  color: #0086ff !important;
}

.text-blue-anchor:hover {
  cursor: pointer;
}

.body-part .text-blue-anchor:visited {
  color: #0086ff;
}

.body-part .text-anchor-blue {
  color: #0086ff;
  font-size: 12px;
}

.text-anchor-blue {
  color: #0086ff !important;
  font-size: 12px;
}

.text-view-log {
  color: #0086FF;
  text-align: center;
  
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.body-part .text-anchor-blue:visited {
  color: #0086ff;
}

.text-anchor-blue:visited {
  color: #0086ff;
}

.body-part .configure {
  font-size: 12px;
}

.task {
  font-size: 12px;
  display: flex;
}

.flex-flow {
  display: flex;
  flex-flow: wrap;
}

.cred-div {
  display: grid;
  grid-template-columns: 80% 1fr;
  gap: 20px;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.btn-danger .MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 4px;
}

.MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 4px !important;
}

.MuiIconButton-label {
  font-size: 12px;
}

.load-more {
  border: 2px solid;
  border-radius: 4px;
  padding: 5px 15px ;
}

table {
  border-collapse: collapse;
}

.table-bordered {
  border: 1px solid #dee2e6 !important;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table-responsive {
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.duration-info-div {
  display: flex;
  align-items: center;
  background-color: #fffae4;
  padding: 20px 15px;
  width: 100%;
  height: 30px;
}

.duration-info-div>p {
  color: #636363;
  font-size: 12px;
}

.duration-info-div>p>span {
  color: #2669fd;
  font-size: 12px;
}

.msg {
  color: #9e9e9e
}

.table {
  width: 100%;
  color: #212529;
}

.table thead th {
  border-bottom: none;
  vertical-align: middle;
  color: #000;
}

.table th {
  font-weight: 500;
  color: #324148;
  font-size: 14px;
  padding: .75rem .25rem;
  text-transform: capitalize;
  border-bottom: 1px solid #EAECEC;
  white-space: nowrap;
}

.table tr:nth-child(2) th {
  border-bottom: 0px solid #000 !important;
}

.table td,
.table th {
  vertical-align: middle;
  padding: .75rem .75rem;
  /*1.25rem*/
  font-size: 11px;
  font-weight: 400;
  color: #9e9e9e;
  border-bottom: 1px solid #EAECEC;
}

.no-pad-table td,
.no-pad-table th {
  padding: .75rem 0.75rem;
}

.table td a {
  color: #0086ff;
}

.baseline th {
  vertical-align: baseline !important;
}

.baseline td {
  vertical-align: -webkit-baseline-middle !important;
}

.table td,
.table th {
  vertical-align: middle;
}

.table thead,
.table tbody {
  width: 100%;
}

.table th {
  text-align: left;
  border-bottom: 2px solid !important;
}

.no-pad-table th {
  padding-right: 0;
}

.table th tr th {
  border-bottom: 0px solid #000 !important;

}

.table td {
  white-space: nowrap;
}

.table td .pod-lable {
  vertical-align: bottom;
  display: inline-block;
}

.table tr:last-child td {
  border-bottom: 0px solid;
}

.subOptionChild {
  padding: 3px 12px !important;
}

.head .btn-round {
  margin-left: 10px;
}

.master {
  background-color: #EFF2BC !important;
}

.worker {
  background-color: #E9F2F8 !important;
}

.label-for-multiselect {
  font-size: 12px;
  display: flex;
  align-items: center;
}


.error-notice {
  margin: 5px;
}

.danger {
  border-left-color: #d9534f;
  background-color: rgba(217, 83, 79, 0.1);
}

.expensionPanel {
  background-color: #fafafa;
  border: 1px solid #dedede;
  border-radius: 4px;
  padding: 10px;
  min-height: 175px;
}

.expensionPanelHead {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  border-bottom: 1px solid #dedede;
}

.eventListenerDetailsTab {
  font-style: normal;
  font-size: 16px;
  padding: 10px 8px;
  color: #4D4D4D !important;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #dedede;
}

.eventListenerDetailsTab.active {
  color: #124D9B !important;
  border-bottom: 2px solid #124D9B !important;
}

.databaseManagementTabs {
  font-family: 'Montserrat';
  /* font-style: normal; */
  font-size: 14px;
  padding: 16px 20px;
  color: #2F2F2F !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  /* width: 100%; */
  /* text-align: center; */
  /* border-bottom: 1px solid #dedede; */
}

.databaseManagementTabs.active {
  color: #2F2F2F !important;
  font-weight: 600;
  border-bottom: 2px solid #0086FF !important;
}

.databaseManagementSchemaTabs {
  font-family: 'Montserrat';
  /* font-style: normal; */
  font-size: 12px;
  padding: 4px 12px;
  color: #2F2F2F !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  /* width: 100%; */
  /* text-align: center; */
  /* border-bottom: 1px solid #dedede; */
}

.databaseManagementSchemaTabs.active {
  color: #FFFFFF !important;
  background-color: #0086FF;
  font-weight: 500;
}

.oaerror {
  width: 80%;
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
  margin: 10px 0px;
  font-size: 16px;
}

.danger strong {
  color: #d9534f;
}

.btn-group {
  display: flex;
  align-items: center;
}

.btn-group .input-component .MuiIconButton-root {
  border-left: 0px !important;
}

.icon-box {
  height: 74px;
  width: 74px;
  border-radius: 8px;
  overflow: hidden;
}

.icon-box-top {
  height: 70%;
  text-align: center;
  color: #fff;
}

.icon-box-top .flaticon-locked-padlock-outline,
.icon-box-top .flaticon-unlocked-padlock {
  line-height: 1.7;
}

.icon-box-top .flaticon-locked-padlock-outline::before,
.icon-box-top .flaticon-unlocked-padlock:before {
  font-size: 36px !important;
  color: #fff;

}

.icon-box-bottom {
  height: 30%;
  text-align: center;
  color: #fff;
}

.protected-icon-box.icon-box-top {
  background-color: #69e09c;
  color: #fff;
}

.protected-icon-box.icon-box-bottom {
  background-color: #2EBE79;
  color: #fff;
  line-height: 2.2;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.public-icon-box.icon-box-top {
  background-color: #EBF5FF;
  color: #fff;

}

.public-icon-box.icon-box-bottom {
  background-color: #0086ff;
  color: #fff;
  font-size: 12px;
  line-height: 2.2;
}

.width-150-fix {
  width: 150px !important;
}

.width-200-fix {
  width: 200px !important;
}

.width-120-fixed {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.progress-per {
  font-size: 10px;
  color: #000;
}

.progress-label {
  font-size: 12px;
  color: #000;
}

.progress-circle-container {
  width: 200;
  height: 200;
}

.process-label-sec {
  text-align: center;
  line-height: 1.2;
}

.va-top td {
  vertical-align: top !important;
}

.nothing-to-load {
  font-size: 12px;
  color: #9e9e9e;
}

.setup-summary .subheading {
  font-size: 12px;
  color: #9e9e9e;
}

.setup-summary .mainheading {
  font-size: 14px;
  line-height: 1.3;
}

.setup-summary {
  padding: 15px 10px;
}

.sub-header-setup-summary {
  padding: 5px 10px;
  border-bottom: 1px solid #dedede;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 39px;
}

.sub-box-one-summary {
  display: inline-block;
  width: 50%;
  border-right: 1px solid #dedede;
  height: 191px;
}

.sub-box-one-summary .body-part {
  padding: 10px;
}

.sub-box-one-summary:only-child {
  border-right: 1px solid #dedede;
}

.sub-box-one-summary:last-child {
  border-right: 0px;
}

.loading-parent-div {
  height: 191px;
}

.blank-section {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.controller-dialogue-lable .grid-container {
  grid-template-columns: auto auto !important;
}

.ribbon {
  font-size: 13px;
  line-height: 1.4;
  padding: 8px;
  background-color: #FF706D;
  color: #fff;
  border-radius: 4px;
}

.profile-section {
  display: flex;
  align-items: center;
}

.preview-profile {
  height: 150px;
  width: 150px;
  background-color: #b4b4b4;
  color: #d3d3d3;
  font-size: 48px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 20px;
  overflow: hidden;
}

.small-preview {
  height: 60px;
  width: 60px;
  font-size: 24px;
}

.preview-profile img {
  height: 100%;
  width: 100%;
}

.table-grid-box .header {
  background-color: #fcfcfc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding: 5px 10px;
  color: #949494;
  border-bottom: 1px solid #dedede;
}

.table-grid-box-body {
  height: 350px;
  overflow: auto;
}

.form-card {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dedede;
}

.form-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding: 5px 10px;
}

.form-card-body {
  padding: 5px 10px;
}

.input-switch .card-header {
  border: none;
  border-radius: 0px;
  background-color: transparent;
}

.form-card-footer {
  border-top: 1px solid #dedede;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.unclickable-chip {
  cursor: default !important;

}

.unclickable-chip:hover {
  cursor: default !important;
  background-color: #fff !important;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.13);
}

.unclickable-chip:hover a {
  color: #0086ff !important;
}

.slider-billing .MuiSlider-thumb {
  height: 30px !important;
  width: 30px !important;
  margin-top: -10px;
}

.slider-billing .PrivateValueLabel-offset {
  top: 12px;
}

.slider-billing .PrivateValueLabel-offset-239 {
  background-color: transparent;
  top: 10px;
  color: transparent;
}

.slider-billing .PrivateValueLabel-circle-240 {
  border-radius: 50%;
  font-size: 12px;
}

.config-secret.card {
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #dedede !important;
}

.config-secret .card-header {
  height: auto;
}

.config-secret .card-header .input-component {
  margin-bottom: 0px;
  width: fit-content;
}

.input-row {
  border-bottom: 1px solid #dedede;
  padding-top: 10px
}

.input-row:last-child {
  border-bottom: 0px solid #dedede;
}

.class-remove-btn {
  display: none !important;
}

.card-body div:first-child .card .sub-header .class-remove-btn {
  display: flex !important;
}

.card-body div:only-child .card .sub-header .class-remove-delete-btn {
  display: none !important;
}

.blank-div .url {
  border: none;
  height: auto;
  width: auto;
}

.blank-div .url:hover {
  background-color: transparent;
  color: #0086ff;

}

.justify-end {
  justify-content: flex-end;
}

/* .new-overview-card {
  margin-bottom: 20px;
} */

.new-overview-card .card-header {
  height: auto;
  border-top: 1px solid #dedede;
  padding-bottom: 0px;
  z-index: 9;
  overflow: hidden;
  border-bottom: 0px;
}

.card-cicd {
  border-radius: 0 0 4px 4px !important;
}

.new-overview-card>.card-body {
  z-index: 9;
  position: relative;
  border-top: 1px solid #dedede;
  padding-bottom: 0px;
  background-color: #f8f8f8;
}

.new-overview-card .subheader-cont {
  border-top: 0px;
  border-bottom: 1px solid #dedede;
  background-color: #fff;

}

.new-overview-card .md-stepper-horizontal {
  background-color: #fff;
}

.new-overview-card .section-service-overview {
  padding: 10px;
}

.card.pd-cont {
  border: none;
  border-radius: 0px;
}

.heading-key-value ul.headerul li {
  padding: 7px 15px !important;
}

.heading-key-value ul.headerul li.active {
  border-bottom: 2px solid #0096db !important
}

.kv-border .heading-key-value {
  border-bottom: 1px solid #CED1DB;
  padding-bottom: 0px;
}

.section-service-overview {
  border-top: 0px !important;
}

.text-security {
  -webkit-text-security: disc;
}

.three-grd {
  grid-template-columns: 32% 32% 32% !important;
  gap: 0px 2px;
}

.data-key-value-pair p {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  width: 100%;
}

.data-key-value-pair.overview-card-value-text {
  display: inherit !important;
  align-items: center !important;
  justify-items: flex-start;
  border-bottom: 1px solid #CED1DB !important;
  padding: 3px !important;
}

.master_role {
  margin-right: 10px;
}

.master_role:last-child .comma {
  display: none;
}

.master_role:only-child .comma {
  display: none;
}

.card-ser {
  border-radius: 4px;
  box-shadow: none;
  height: fit-content;
}

.card-title {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 400;
}

.card-ser .btn {
  display: inline-block !important
}

.v-2 {
  margin: 0px 0px 10px;
}

.v-2 ul.headerul li.active {
  background-color: #34638f;
  color: #fff;
  border-radius: 4px;
  border: none;
  border: 2px solid #34638f;
  padding: 0px;
  text-align: center;
}

.v-2 ul.headerul li {
  height: 35px;
  width: 65px;
  line-height: 35px;
  border: 2px solid #34638f;
  border-radius: 4px;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0px;
  text-align: center;
  color: #34638f;
  text-transform: capitalize;
}

.v-2 ul.headerul li:first-child {
  margin-left: 0px;
}

.v-2 ul.headerul li:last-child {
  margin-right: 0px;
}

.MuiSnackbar-anchorOriginTopRight {
  top: 30px !important;
}

.MuiSnackbar-root {
  z-index: 99999999;
}

.card .class-control {
  border-top: none !important;
}

.cont-dialogue .MuiDialogActions-root {
  justify-content: flex-end !important
}

.addmore-cont .card-header {
  height: auto;
}

.chip-sq {
  border-radius: 4px;
  padding: 3px 5px;
  background-color: transparent;
  color: transparent;
  font-size: 12px;
  width: fit-content;
}

.chip-sq-canary {
  border-radius: 4px;
  padding: 3px 5px;
  background-color: transparent;
  font-size: 12px;
  width: fit-content;
}

.dev-bg {
  background-color: #30b4a6 !important;
  color: #fff !important;
}

.qa-bg {
  background-color: #82a479 !important;
  color: #fff !important;
}

.stagin-bg {
  background-color: #6b6fcb !important;
  color: #fff !important;
}

.uat-bg {
  background-color: #30d0f2 !important;
  color: #fff !important;
}

.prod-bg {
  background-color: #a47983 !important;
  color: #fff !important;
}

.devops-bg {
  background-color: #6ede9d !important;
  color: #fff !important;
}

.dev-chip {
  background-color: #30b4a6;
  color: #fff;
}

.qa-chip {
  background-color: #82a479;
  color: #fff;
}

.devops-chip {
  background-color: #6b6fcb;
  color: #fff;
}

.admin-chip {
  background-color: #a47983;
  color: #fff;
}

.chip-sq-primary {
  background-color: #0086ff;
  color: #fff;
}

.chip-sq-default {
  background-color: #ffac52;
  color: #fff
}

.chip-sq-success {
  background-color: #85cc7f;
  color: #fff
}

.chip-sq-info {
  background-color: #129b86;
  color: #fff
}

.plane-chip .chip-default.chip-blue {
  font-size: 11px;
  background-color: transparent !important;
  color: #0086ff !important;
  box-shadow: none !important;
}

.btn-section .btn:first-child {
  margin-right: 5px;
}

.btn-section .btn:last-child {
  margin-left: 5px;
}

.logs-context #viewFullLogs {
  position: relative !important;
}

.mutli-com-controller .header-cont.MuiGrid-grid-lg-11 {
  flex-grow: 0;
  max-width: 88.666667%;
  flex-basis: 91.666667%;
}

.text-box {
  margin: 20px
}

.v-radio .input-component .MuiFormGroup-row {
  flex-direction: column;
}

.text-box .main-text {
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 15px !important;
  color: #004085;
}

.text-box .sub-text {
  font-size: 13px;
  line-height: 1.2;
  color: #004085;
}

.cursor-not-allowed,
.cursor-not-allowed:hover {
  cursor: not-allowed !important;
  color: rgba(158, 158, 158, 0.2) !important;
  border: 1px solid rgba(158, 158, 158, 0.2) !important;
  background-color: inherit !important;
}

.btn-disable {
  background-color: #838383 !important;
  color: #fff !important;
  border-color: #838383 !important;
}

.btn-disable:hover {
  background-color: #4a4949 !important;
  border-color: #4a4949 !important;
}

.disabled-text-box .btn-submit {
  cursor: not-allowed;
}

.disabled-text-box .btn-submit:hover {
  cursor: not-allowed;
}

.disabled-text-box a.btn-submit:hover {
  cursor: not-allowed;
}

.border-style-dashed {
  border-style: dashed !important;
}

.box-with-btn {
  line-height: 1.2 !important;
}

.box-with-btn .text-color-dark-grey {
  line-height: 1.2 !important;
  color: #8b8b8b !important;
}

.box-with-btn span {
  display: inline;
}

.service-affinity-box {
  height: 300px;
  background-color: #fdfdfd;
  width: 100%;
  overflow-y: scroll;
  border: 1px solid #dedede;
  border-radius: 4px;
}

.service-affinity-box::-webkit-scrollbar {
  width: 0px !important;
}

.card-second-level {
  margin-bottom: 15px;
}

.third-level-child .box-third-level .vertical-border-third {
  margin-top: -63px;
}

.box-third-level:last-child .vertical-border-third,
.box-third-level:only-child .vertical-border-third {
  height: 96px;

}

.horizontaltab-modifier {
  border-bottom: 1px solid #dedede;
}

.horizontaltab-modifier ul.headerul li {
  padding: 8px 20px;
}

.chip-overview {
  margin: 3px;
}

.btn-submit.flaticon-white span[ class^="flaticon"] {
  color: #fff;
  margin-left: 0px;
  margin-right: 5px;
}

.btn-link-only {
  border: none !important;
  padding: 0 !important;
}

a.btn-link-anchor:hover {
  background-color: transparent !important;
  color: #0086ff !important
}

.btn-link-green {
  color: #69e09c;
}

.btn-link:hover {
  text-decoration: underline !important;

}

.MuiDialog-paperFullScreen #viewFullLogs {
  bottom: 0px;
}

.switch-only .card {
  border-radius: 0px;
}

.switch-only .card-header {
  background-color: #f9f9f9;
  border: 0px solid #d5d5d5;
}

.menu-cont svg {
  vertical-align: sub;
}

.error-container-multiline {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-ellipsis-multiline {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: break-spaces;
}

.text-ellipsis-twoline {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: break-spaces;
}

.error-container {
  margin-top: 10px;
}

.vertical-top svg {
  vertical-align: text-top;
}

.vertical-bottom {
  vertical-align: bottom;
}

.vertical-text-bottom {
  vertical-align: text-bottom;
}

.v-align-middle {
  vertical-align: middle;
}

.circle-status-skipped .flaticon-merge-calls-arrow::before {
  font-size: 14px !important;
  color: #52bfe7 !important;
}

.cd-m {
  overflow: hidden;
  margin: 20px 15px !important;
}

.cd-m .card-header {
  padding: 10px;
  border: 0px solid;
  border-bottom: 1px solid #dedede;
}

.cd-h .card-header {
  border-bottom: 1px solid #dedede;
}

.loading-10px .top-0px {
  top: 10px !important
}

.loading-10px .loading-gif-container-two {
  z-index: 9;
}

.recent-deployment {
  display: flex;
  padding: 40px;
  align-items: center;
}

.recent-deployment .progress-arrow:nth-child(4) {
  display: none;
}

.menu-item .MuiSvgIcon-root {
  vertical-align: sub;
}

.menu-item {
  border-bottom: 1px solid #dedede !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.menu-item:last-child {
  border-bottom: none !important;
}

.grid-temp-6 {
  grid-template-columns: 12% 14% 8% 12% 1fr 1fr;
  gap: 10px
}

.border-bottom-black {
  border-bottom: 2px solid #000;
}

.border-bottom-grid {
  border-bottom: 1px solid #dedede;
}

.border-bottom-grid:last-child {
  border-bottom: 0px;
}

.highcharts-center-data {
  color: #333333;
  font-size: 18px;
  fill: #333333;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: absolute;
  top: 25%;
  left: 39.5%;
}

.success-data {
  border: 2px dashed #51cbbb;
  background-color: #f3fff8;
}

.pending-data {
  border: 2px dashed #ffb738;
  background-color: #fff3de;
}

.danger-data {
  border: 2px dashed #FF6961;
  background-color: #faccc9;
}

.not-found-data {
  border: 2px dashed #838383;
  background-color: #f4f4f4;
}

.processing-data {
  border: 2px dashed #211c4f;
  background-color: #eae8ff;
}

.round-div {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  font-size: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px;
}

.processing-state {
  background-color: #211c4f;
  color: #fff;
}

.success-state {
  background-color: #51cbbb;
  color: #fff;
}

.pending-state {
  background-color: #ffb738;
  color: #fff;
}

.not-found-state {
  background-color: #838383;
  color: #fff;
}

.multi-hightchart-center-data {
  color: #333333;
  z-index: 1;
  font-size: 18px;
  fill: #333333;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: absolute;
  top: 25%;
  left: 33.5%;
}

.highcharts-label.highcharts-tooltip.highcharts-color-0:hover {
  z-index: 9 !important;
}

.gen-data {
  margin: auto;
  display: block;
  text-align: center;
}

.v-align-sub {
  vertical-align: sub;
}

.grid-template-single-pods {
  grid-template-columns: 25% 75%;
}

.bottom-panel .headerul {
  padding: 10px 20px
}

.bottom-panel .headerul li .active {
  border: 1px solid #dedede;
  border-bottom: none;
  border-radius: 5px 5px 0px 0px
}

.square-box {
  font-size: 12px;
  color: #fff;
  padding: 1rem;
  background-color: transparent;
  border-radius: 2px;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-transform-lower {
  text-transform: lowercase;
}

.text-capatalize {
  text-transform: capitalize;
}

.text-transform-uppercase {
  text-transform: uppercase !important;
}

.text-transform-capitalize {
  text-transform: capitalize !important;
}

@media(max-width:1280px) {
  .highcharts-center-data {
    top: 24%;
    left: 38%;
  }
}

@media (min-width: 1920px) {
  .highcharts-center-data {
    top: 24%;
    left: 42%;
  }
}

.highcharts-container {
  position: inherit !important;
}

.highcharts-tooltip {
  z-index: 99999;
}

@media (min-width:1280px) {
  .content-1 {
    left: 70px !important;
  }
}

@media (min-width:1430px) {
  .content-1 {
    left: 70px !important;
  }
}

.coming-soon-chip {
  background-color: #34638f !important;
}

.btn-link:hover .Y6 {
  text-decoration: none !important;
}

th.cont-th-db {
  padding: 0
}

.table-default td,
.table-default th {
  padding: 1rem !important;
}

th.cont-th-db .table th {
  border-bottom: none !important;
  padding: 0 1.25rem;
}

.table.border-grid th,
.table.border-grid td {
  border: 1px solid #EAECEC;
  border-top: none;
}

.border-top-none {
  border-top: none !important;
}

.border-none th,
.border-none td {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.table-layout-fixed {
  table-layout: fixed;
}

.fixed-four th:first-child,
.fixed-four td:first-child {
  width: 25% !important;
}

.fixed-four th:nth-child(2),
.fixed-four td:nth-child(2) {
  width: 25% !important;
}

.fixed-four th:nth-child(3),
.fixed-four td:nth-child(3) {
  width: 10% !important;
}

.fixed-four th:nth-child(4),
.fixed-four td:nth-child(4) {
  width: 40% !important;
}

.table-striped tbody>tr:nth-of-type(odd) {
  background-color: #f8f8f8;
}

.variant-button ul.headerul li {
  text-transform: capitalize;
  color: #3d73eb;
  margin: auto;
}

.variant-button ul.headerul li.active {
  background-color: #3d73eb;
  border-bottom: 0;
  color: #fff;
  border-radius: 4px;
  padding: 10px;
}

.custom-width .MuiDialog-paperScrollPaper {
  width: 60em
}

.double-thumb-slider {
  display: grid;
  gap: 10px;
  align-items: center;
  grid-template-columns: 70% 1fr;
  justify-content: center;
  justify-items: center
}

.inner-card .with-header {
  background-color: #f7f7f7;
}

.inp-cont .input-component {
  margin-bottom: 0;
}

.inp-cont input {
  width: 50px;
  padding: 5px;
  height: 38px;
}

.switch-controller {
  background-color: #fff;
  padding: 10px;
  border-bottom: 1px solid #dedede;
}

.switch-controller .card-header {
  background-color: #fff;
  border-bottom: none;
  padding: 0px;
  height: auto;
}

.switch-controller .card-header .heading {
  font-size: 12px;
}

.switch-cont .input-component {
  margin-bottom: 0;
}

.inp .input-with-btn {
  border: none;
}

.type-checkbox .input-component .error-message {
  display: block;
}

.inp .input-with-btn input.range-slider-input {
  width: 120px;
}

.inp .MuiSlider-root {
  width: 70% !important;
}

.m-footer .card-header {
  border-bottom: 0;
  background-color: inherit;
}
.MuiInputBase-root{
  height: auto!important;
  line-height: auto!important;
}

.MuiInputBase-input{
  border: 1px solid #b7b7b7 !important;
  border-radius: 4px !important;
  line-height: 2;
}

.MuiTextField-root:hover {
  border: 1px solid #4671C6 !important;
  border-radius: 4px !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 0px solid #4671C6 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  outline: none !important;
}

.MuiInputBase-input {
  font-size: 12px;
  height: auto;
}

.MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.MuiInputBase-input::placeholder {
  color: #D1CCCC !important;
}
.auto-complete-dropdown-ticketing .MuiInputBase-input{
  border: none!important;
}
.auto-complete-dropdown-ticketing .MuiInputBase-root .MuiAutocomplete-inputFocused:focus{
  border: none!important;
}

.input-component .MuiFormControl-fullWidth:hover {
  border: 0px solid #b7b7b7 !important;
}

.env-tabs {
  position: relative;
  top: 1px;
}

ul.headerul.env-tabs li.active {
  color: #124d9b !important;
  border-bottom: 0px solid #fff;
  z-index: 99;
  border-radius: 4px 4px 0px 0px;
  border-top: 1px solid #dedede;
  border-right: 1px solid #dedede;
  border-left: 1px solid #dedede;
}

ul.headerul.env-tabs li.active span {
  color: #124d9b !important;
}

ul.headerul.env-tabs li.active span.badge {
  color: #fff !important;
}

.card-sub-header ul.headerul li.active {
  color: #124d9b;
  border-bottom: 3px solid #124d9b;
}

.v-3 .headerul li.active {
  background-color: #fff;
  color: #124d9b !important;
  border: 1px solid #dedede;
  border-bottom: 0px solid #dedede;
  border-radius: 4px 4px 0 0;
  position: relative;
  top: 1px;
}

.btn-disabled-new {
  color: #e6e6e6 !important;
  border: 1px solid rgba(128, 128, 128, 0.1) !important;
}

.btn-disabled-new:hover {
  background-color: white !important;
  cursor: not-allowed !important;
}


.btn-icon-group {
  background-color: transparent;
  border: 1px solid #0787e1;
  border-radius: 4px;
}

.btn-icon-group .btn-flaticon,
.btn-icon-group .btn-disabeld-flaticon {
  padding: .5rem 0.9rem !important;
  border-right: 1px solid #0787e1 !important;
}

.btn-flaticon span[ class^="flaticon"] {
  margin: 0px 5px 0px 5px;
}

.btn-icon-group .btn-flaticon:hover {
  background-color: #fea010;
  border-radius: 0px;
  margin: 0px;
}

.btn-icon-group .btn-flaticon span[ class^="flaticon"],
.btn-icon-group .btn-disabeld-flaticon span[ class^="flaticon"] {
  margin: 0px !important;
}

.btn-icon-group .btn-disabeld-flaticon span[ class^="flaticon"] {
  color: #b9b8b8;
}

.btn-icon-group .btn-disabeld-flaticon:hover {
  cursor: not-allowed;
}

.btn-disabled-new {
  color: #e6e6e6 !important;
  border: 1px solid rgba(128, 128, 128, 0.1) !important;
}

.btn-disabled-new:hover {
  background-color: white !important;
  cursor: not-allowed !important;
}

.btn-icon-group .btn-flaticon:hover span[ class^="flaticon"] {
  color: #fff !important;

}

.btn-icon-group .btn-flaticon:last-child {
  border-right: 0px solid #0787e1 !important;
}

.btn-icon-group .btn-flaticon,
.btn-icon-group .btn-disabeld-flaticon {
  margin: 0 !important;
}

.btn-group.btn-icon-group .btn:last-child {
  border-right: 0px solid !important;
}

.multiple-div .with-header {
  height: 50px;
  margin: 5px 0px 5px;
  display: flex;
  padding: 0px 15px;
  border-top: 1px solid #dedede;
  align-items: center;
  border-bottom: 1px solid #dedede;
  justify-content: space-between;
  background-color: #f6f6f6;
  font-size: 12px;
  color: #666;
}

.with-header-step {
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
}

.vertical-tabs-settings ul.headerul {
  display: block !important;
}

.vertical-tabs-settings ul.headerul li {
  border-bottom: 1px solid #dedede;
  text-transform: capitalize;
  padding-top: 13px;
  padding-bottom: 13px;
}

.vertical-tabs-settings ul.headerul li.active {
  background-color: #fff;
  position: relative;
  left: 1px;
  color: #2e2af3;
  font-weight: bold;
  border-bottom: 1px solid #dedede;
}

.vertical-tabs-settings.active_bar ul.headerul .not-active-bar li {
  color: #007eff;
  background-color: #f8f8f8;
  font-weight: 500;
}

.vertical-tabs-settings.active_bar ul.headerul .active-bar li.active {
  color: #000;
  background-color: #fff;
  font-weight: 500;
}

.vertical-tabs-settings.active_bar ul.headerul li.active {
  background-color: #fff;
  font-weight: 400;
  position: relative;
  left: 0px;
  color: #666;
  border-bottom: 1px solid #dedede;
}

.vertical-tabs ul.headerul {
  display: block;
}

.vertical-tabs ul.headerul li.active {
  background-color: #fff;
  left: 1px;
  color: #2e2af3;
  font-weight: bold;
  border-bottom: 1px solid #dedede;
}

.vertical-tabs.active_bar ul.headerul .not-active-bar {
  border-left: 5px solid #124d9b;
}

.vertical-tabs.active_bar ul.headerul .active-bar {
  border-left: 5px solid #f89927;
}

.vertical-tabs ul.headerul li {
  border-bottom: 1px solid #dedede;
  text-transform: capitalize;
  padding-top: 13px;
  padding-bottom: 13px;
}

.vertical-tabs.active_bar ul.headerul li.active {
  background-color: #fff;
  font-weight: 400;
  position: relative;
  left: 0px;
  color: #666;
  border-bottom: 1px solid #dedede;
}

.light-loading-container {
  height: 300px;
}

.search-bar .input-component input:focus {
  border: 'none!important'
}

.btn-group .MuiIconButton-root {
  border-left: 1px solid #0787e1 !important;
  border-radius: 0px;
  padding: .5rem 0.9rem !important
}

.btn-grp-handle .MuiIconButton-root {
  border-left: none !important;
}

.MuiFormControlLabel-root.Mui-disabled {
  margin-left: 0;
}

.Mui-disabled.MuiFormControlLabel-label {
  margin-left: 5px;
}

.Mui-disabled .MuiRadio-root {
  color: #a0a0a0 !important;
}

.Mui-disabled .MuiIconButton-root {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0px !important;
}

.condition-grid {
  padding: 0px 0px;
}

.condition-grid p {
  display: grid;
  grid-template-columns: 70% 1fr;
  padding: 6px 8px;
  border-bottom: 1px solid #dedede;
}

.condition-grid p.custion_fields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 6px 8px;
  border-bottom: 1px solid #dedede;
}

.condition-grid p .key {
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.condition-grid p.custion_fields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 6px 8px;
  gap: 10px;
  border-bottom: 1px solid #dedede;
}

.condition-grid p:last-child {
  border-bottom: 0px solid #dedede;
}

.text-white [class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  color: #fff !important;
}

.single-row td {
  vertical-align: middle !important;
}

.alert-primary-1 {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}

.alert-1 {
  position: relative;
  padding: 1rem 1rem;
  font-size: 12px;
  border: 1px solid transparent;
  border-radius: 0;
}

.section-input-1-1 .card-header {
  border: none;
  background-color: transparent;
  width: 300px;
}

.h-12 .loading-text-cc {
  height: 30px
}

.border-left-blue {
  border-left: 1px solid #0787e1 !important;
  margin-left: 0 !important;
}

.yamlName:hover {
  cursor: pointer;
}

.integration-checklist__copy-button {
  display: block;
  cursor: pointer;
  border: 0px;
  background-color: #fff;
  position: relative;
  margin-left: 5px;
}

.integration-checklist__copy-button:before {
  content: "";
  display: none;
  position: absolute;
  z-index: 9998;
  top: 14px;
  left: 3px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgba(0, 0, 0, 0.72);
}
.integration-checklist__copy-button.copybutton:before {
  content: "";
  display: none;
  position: absolute;
  z-index: 9998;
  top: 38px;
  left: 53px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgba(0, 0, 0, 0.72);
}

.integration-checklist__copy-button:after {
  content: "Copy to Clipboard";
  display: none;
  position: absolute;
  z-index: 9999;
  left: -50px;
  width: 114px;
  height: 36px;
  color: #fff;
  font-size: 10px;
  line-height: 36px;
  text-align: center;
  background: rgba(0, 0, 0, 0.72);
  border-radius: 3px;
}
.integration-checklist__copy-button.copybutton:after {
  content: "Copy to Clipboard";
  display: none;
  position: absolute;
  z-index: 9999;
  left: 0px;
  width: 114px;
  height: 36px;
  color: #fff;
  font-size: 10px;
  line-height: 36px;
  text-align: center;
  background: rgba(0, 0, 0, 0.72);
  border-radius: 3px;
  top: 43px;
}

.integration-checklist__copy-button:hover:before,
.integration-checklist__copy-button:hover:after {
  display: block;
}

.integration-checklist__copy-button:active,
.integration-checklist__copy-button:focus {
  outline: none;
}

.integration-checklist__copy-button:active:after,
.integration-checklist__copy-button:focus:after {
  content: "Copied!";
}

.signature {
  position: absolute;
  right: 12px;
  bottom: 12px;
  color: rgba(0, 0, 0, 0.07);
  font-size: 10px;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0px 0px !important
}

.MuiTextField-root .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  padding: 0px 4px !important;
}

.input-component .slider-controller {
  flex-direction: column-reverse;
}

.slider-controller .input-with-btn {
  display: none !important;
}

.card-autoscaling .slider-controller .input-with-btn {
  display: block !important;
}

.card-autoscaling .input-component .slider-controller {
  flex-direction: row;
}

.slider-sm .slider-controller {
  flex-direction: row !important;
}

.slider-sm .input-with-btn {
  display: block !important;
}

.card-header.deployment-strategy {
  border-top: none !important;
}

.deployment-strategy .input-component {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.deployment-strategy {
  border-bottom: 1px solid #dee2e6 !important;
}

.msg-box__1 {
  background-color: rgb(252, 252, 252);
  border: 1px solid rgb(222, 222, 222);
  padding: 10px;
  border-radius: 4px;
}

.arrow_icon {
  border: 3px solid #004085;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  display: inline-block;
  margin-left: 50px;
  margin-right: 60px;
}

.canary_template_row .row_canary:last-child .arrow_icon {
  display: none;
}

.bar-grid {
  display: inline-block;
  width: 65px;
  padding: 10px
}

.row_canary {
  padding-bottom: 15px;
}

.bb-important {
  border-bottom: 1px solid #dedede !important;
}

.sm-card {
  border-radius: 4px;
  overflow: hidden;
  padding: 0px;
  width: 180px;
  text-align: center;
}

.sm-card-header {
  font-size: 13px;
  padding: 12px 0px;

}

.sm-card-body {
  padding: 25px 0px;
  background-color: #f2f4f5;
}

.sm-card-body p {
  font-weight: 200;
}

.sm-card-footer {
  font-size: 13px;
  color: #fff;
  padding: 12px 0px;
}
.bg-primary{
  background-color: #0086ff!important;
}

.bg-success {
  background-color: #62e187;
}

.bg-processing {
  background-color: #ffb738;
}

.bg-failed {
  background-color: #fa5c5c;
}

.bg-pending {
  background-color: #e3e6e8;
}

.bg-initiated {
  background-color: #004085;
}

.bg-dark-blue-124 {
  background-color: #124d9b;
}

.bg-revoked {
  background-color: #c294f1;
}

.bg-running {
  background-color: #0086ff;
}

.canary_template_row.sm-card-sec {
  padding-left: 30px;
  padding-right: 30px;
}

.sm-card-sec .row_canary {
  display: inline-flex;
  align-items: center;
  padding-bottom: 40px;
}

.header__section {
  padding-bottom: 35px;
  padding-top: 20px;
  padding-right: 30px;
  padding-left: 30px;
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr 235px;
}

.slider-with-value {
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 10px;
  align-items: center;
}

.slider-value {
  font-size: 34px;
  color: #737373;
  font-weight: 200;
  line-height: 1;
}

.slider-with-value-heading {
  font-weight: 300;
  font-size: 12;
}

.slider-with-value-heading span {
  color: #737373;
}

.accordian-card .MuiExpansionPanelSummary-content.Mui-expanded {
  margin: 0px;
}

.accordian-card .MuiExpansionPanelSummary-root.Mui-expanded {
  min-height: 48px;
}

.accordian-card.MuiPaper-elevation1 {
  box-shadow: none;
}

.accordian-card .MuiExpansionPanelDetails-root {
  border: 1px solid #dedede;
  border-radius: 0px 0px 4px 4px;
}

.details-label .navigation_buttons {
  top: 9px
}

.navigation_buttons.btn-group-1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation_buttons .btn-round-primary-outline-1 {
  height: 36px;
  width: 36px;
  background-color: transparent;
  border-radius: 18px;
  border: 2px solid #0086ff;
  color: #0086ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 3px;
  margin-right: 3px;
}

.navigation_buttons .btn-round-primary-outline-1:hover {
  background-color: #0086ff;
  border: 2px solid #0086ff;
  color: #fff;
}

.feature_tag_div {
  transition: all .5s ease-in-out;
  overflow: hidden;
  padding-left: 15px;
  padding-right: 15px;
  display: grid;
  gap: 50px;
  grid-template-columns: 80% 1fr;
  align-items: center;
}

.deploy_class .input-component label {
  margin-bottom: 6px;
  display: inline-block;
}

.external-temp-2 {
  grid-template-columns: 240px 1fr;
}

.min-height-500 {
  min-height: 500px;
}

.sq-avt {
  height: 50px;
  width: 50px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.group-list .vertical-tabs ul.headerul li div {
  text-transform: lowercase;
}

#simple-menu .transparent-btn {
  color: #686868 !important;
}

.border-bottom.border-bottom-last-child:last-child {
  border-bottom: 1px solid #dedede !important
}

.btn-group.btn-icon-group .MuiIconButton-root {
  border-left: 0px !important
}

.sm-checkbox-wrapper .icon-section .btn-group .label-for-multiselect {
  margin-left: 3px;
  margin-right: 3px;
}

.sm-checkbox-wrapper .icon-section .btn-group .label-for-multiselect:hover,
.sm-checkbox-wrapper .icon-section .btn-group .label-for-multiselect .MuiButtonBase-root:hover,
.sm-checkbox-wrapper .icon-section .btn-group .label-for-multiselect .count-chip .text-of-chip:hover {
  cursor: not-allowed !important;
}

.input-mb-0 .input-component {
  margin-bottom: 0px !important
}

.auto-complete-dropdown .input-component .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  padding: 9.5px 4px !important
}

.auto-complete-dropdown-42 .input-component .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  /* padding: 14.5px 4px !important */
  padding: 5px 14px !important;
  height: auto;

  font-size: 14px!important;
  font-weight: 500!important;
  line-height: 2!important;
  /* border: none!important; */
}

.auto-complete-dropdown-43 .input-component .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  /* padding: 14.5px 4px !important */
  padding: 5px 14px !important;
  height: auto;
  font-size: 14px!important;
  font-weight: 500!important;
  line-height: 2!important;
}
.MuiInputBase-root .MuiAutocomplete-inputFocused:focus{
  outline: none;
  border: 1px solid #b7b7b7!important;
}
.section-service-overview .manual-height {
  height: 60vh;
}

.btn-round-v2 {
  border-radius: 50%;
  background-color: #fff;
  height: 35px;
  width: 35px;
  filter: drop-shadow(0.877px 1.798px 4px rgba(0, 0, 0, 0.06));
  display: flex;
  align-items: center;
  justify-content: center;
}

.verifed-box {
  background-color: #00bd9c;
  width: 100%;
  padding: 0.75rem 1rem;
  text-align: center;
  color: #fff;
  font-size: 1.2rem;
  border: 2px solid #00bd9c;
  line-height: 1.5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.btn-round-primary-outline-1 {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid transparent !important;
  padding: .75rem 1rem;
  font-size: 1.2rem;
  line-height: 1.4;
  text-shadow: 0px 2px #00000040;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #fff;
  border-color: #0086ff;
  color: #0086ff;
  transition: color .15s ease-in-out,
  background-color .15s ease-in-out,
  border-color .15s ease-in-out,
  box-shadow .15s ease-in-out;
}

.btn-round-primary-outline-1:hover {
  background-color: #0086ff;
  color: #fff;

}

.card-ser .card-body .btn-primary-v2.d-block {
  display: block !important;
}

.input-controller.with-error .error-message {
  position: relative !important;
}

.sch-inp-controller .heading {
  display: none;
}

.sch-inp-controller .input-component {
  margin-bottom: 0px !important;
  display: flex;
  align-items: center;
}

.cust-border .input-component input {
  border-left: none;
  border-radius: 0;
}

.cust-bor-r .input-component select {
  border-radius: 0;
}


.remove-margin-from-input .input-component {
  margin-bottom: 0px !important;
}

.display-flex {
  display: flex;
}

.display-inline-flex {
  display: inline-flex;
}

.gap-8 {
  gap: 8px !important;
}

.card-header-switch .card-header .heading {
  font-size: 12px;
  color: #585858;
}

.full-width-switch .card-header {
  border: 1px solid #dedede;
}

.drop-file-style {
  border: 2px dashed #d1d1d1;
}


.job-template-card {
  border-top: 3px solid #838383;
}

.job-template-left-connection {
  border-left: 3px solid #838383;
}

.job-template-parent:last-child .job-template-left-connection {
  border-left: none !important;

}

.job-template-left-connection-step {
  border-left: 3px solid #838383;
}

.btn-with-icon.btn-sq {
  background: transparent;
  border: 1px solid #dedede !important;
  color: #fff !important;
  border-radius: 4px;
}

.btn-with-icon.btn-sq.btn-sq-small {
  padding: 5px;
}

.btn-with-icon.btn-sq .btn-icon-white.MuiSvgIcon-root {
  color: #fff;
}

.btn-with-icon.btn-sq:hover {
  background-color: #fff !important;
}

.connected-lines {

  border-top: 3px solid #838383;
}

.righ-connected-lines {

  border-right: 3px solid #838383;
}

.job-card-design {
  background-image: linear-gradient(0deg, #fef2f7 0%, #ffffff 100%);
  border: 1px solid #f4c3d6;
  border-bottom: 4px solid #f48fb5 !important;
  border-radius: 5px;
}

.step-card-design {
  background-image: linear-gradient(0deg, #f1f3ff 0%, #ffffff 100%) !important;
  min-width: 180px !important;
  min-height: 55px !important;
  border: 1px solid #bfcbfb !important;
  border-bottom: 4px solid #829bff !important;
  border-radius: 5px !important;
}

.condition-card-design {
  background-image: linear-gradient(0deg, #ffefe0 0%, #ffffff 100%);
  border: 1px solid #ffbb7f;
  border-bottom: 4px solid #ff7303 !important;
  border-radius: 5px;
}

.job-template-canvas {

  overflow: auto !important;
  /* background-image: url('/images/bg-pipeline-view.png'); */
}

.job-template-canvas::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

.add-job-card {
  background-image: url('/public/images/bg-pipeline-view.png');
  border: 2px solid #1074e8;
  border-style: dashed !important;
  background-color: #fff;
  border-radius: 4px;
}

.add-job-card:hover {
  cursor: pointer;

}

.job-template-add-button {

  border-radius: 5px;
  border: 1px solid blue !important;
  background-color: #1074e8 !important;
  height: 26px;
  width: 26px;
  padding: 0px;
  text-align: center;
}


.dialog-left .MuiDialog-paper {

  margin-left: auto !important;
  margin-right: 0px;
  margin-top: 35px;
  height: 100% !important;
  margin-bottom: 0px !important;
}

.dialog-wrapper-pipeline .MuiDialog-paper {
  width: 1000px !important;
  max-width: 1000px !important;
}

.step-dialog-card {

  border: 1px solid #9898987a;
  background-color: #f5f5f5;
  padding-bottom: 45px;
  border-radius: 3px;
}

.step-dialog-card .header-card {

  background-color: #f5f5f5;
  height: 45px;
  border-bottom: 1px solid #9898987a;
  display: flex;
  align-items: center;
  padding-left: 12px;

}

.non-conditional-step {

  border: 1px solid #9898987a;
  border-radius: 3px;

}

.non-conditional-step .non-conditional-step-header {

  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 12px;

}

.accordian-job-div .MuiAccordionSummary-root {

  min-height: 40px !important;
}

.accordian-job-detail-div .MuiAccordionDetails-root {
  padding: 0 !important;
  display: block !important;
}

.accordian-job-div .MuiAccordionSummary-root.Mui-expanded {

  height: 40px !important;
}

.margin-bottom-accordian .MuiAccordion-root.Mui-expanded:last-child {

  margin-bottom: 20px !important;
}

.no-m-bottom-accordian .MuiAccordion-root.Mui-expanded:last-child {

  margin-bottom: 0 !important;
}

.round-button {
  width: 35px;
  height: 35px;
  border: 1px solid white;
  border-radius: 118%;
  background-color: #ffffff;
  box-shadow: 1px 1px 2px 2px whitesmoke;
}

.round-button:hover {
  background-color: #e7ebec !important;
  border: none !important;
  box-shadow: none !important;
}

.text-selection-null {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none
}

.job-template-menu-item .MuiListItem-gutters {

  padding-left: 0px !important;

}
.MuiMenuItem-root{
  color: #505050!important;
  font-weight: 500!important;
}

.job-template-menu-item .MuiMenuItem-root {
  font-size: 12px !important;
}

.canary-menu-item .MuiListItem-gutters{
  padding-left: 0px !important;
}


.step-condition-tab::-webkit-scrollbar {
  width: 1.5em !important;
  height: 8px !important;
}

.env-var-table {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.env-text-ellipsis-130 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 130px !important;
}

.tab-cross-button {

  display: none;
}

.removeable-tab-parent:hover .tab-cross-button {
  display: inline-block;
}


.round-checkbox input[type="checkbox"] {
  display: none;
}

.round-checkbox input[type="checkbox"]+label {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  cursor: pointer;
  border-radius: 50px;
  background-color: transparent;
  border: solid 2px #7d7d7d;
  display: inline-block;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 35px !important;
  height: 35px !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: #9ba6aa;
  margin-left: 3px;
}

.round-checkbox input[type="checkbox"]:checked+label {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  background-color: #3d73eb !important;
  color: #fff !important;
  border-color: #3d73eb;
}

.build-round-icon {
  background-color: #69e09c;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.build-round-icon .flaticon-flash-on-indicator {
  color: #fff;
}

.icon-trigger-card .flaticon-flash-on-indicator {
  color: #0086ff;
}

.icon-deploy-card .flaticon-upload-button {
  color: #0086ff;
}

.icon-promote-card .flaticon-forward-arrow {
  color: #0086ff;
}

.right-line {
  height: 97px;
}


.color-tab-inactive {
  background-color: #fff;
  color: black;
  border: none;
  border-right: 1px solid #b7b7b7;
}

.color-tab-active {
  border: none;
  background-color: #3696db;
  color: white;
}

.color-tab-active label {
  font-weight: bold;
}

.color-tab-inactive label {
  font-weight: bold;

}

.headerul.color-tab-inactive:last-child {
  border: none;
}

.rounded-corner-tab {
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  color: #004eff;
  width: 84px;
  height: 40px;
  border-right: none;
}

.rounded-corner-tab-active {
  background-color: #004eff;
  color: #ffffff;
  width: 84px;
  height: 40px;
  border: none;
}

.home-dashboard-url-data-tab {
  background-color: #ffffff;
  border: none;
  width: 170px;
  height: 30px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #6A6A6A;
}


.home-dashboard-url-data-tab-active {
  background-color: #004eff;
  border: 1px solid #e1e1e1;
  color: #ffffff;
  width: 170px;
  height: 30px;
  border-radius: 20px;
}

.home-dashboard-url-data-tab-container {
  width: 370px;
  height: 40px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ECECEC;
  border-radius: 50px;
}

.build-deployment-dashboard-tab {
  background-color: #ffffff;
  border: none;
  width: 50px;
  height: 35px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #71768F;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 6px;
  padding-right: 6px;
}

.build-deployment-dashboard-tab-active {
  background-color: #004eff;
  border: 1px solid #e1e1e1;
  width: 50px;
  height: 35px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 6px;
  padding-right: 6px;
}

.build-deployment-dashboard-tab-container {
  display: flex;
}

.round-corner-container> :first-child {

  width: 130px;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
}

.round-corner-container> :last-child {
  border: 1px solid #e1e1e1;
  width: 130px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
}

.rounded-corner-tab-seperated-active {
  background-color: #0086FF;
  border: 1px solid #e1e1e1;
  color: #ffffff;
  height: 25px;
  border: none;
  margin: 0px 6px;
  width: fit-content;
  height: 35px;
  padding: 10px 16px 10px 16px;
  gap: 10px;
  border-radius: 18px;
}

.rounded-corner-tab-seperated {
  background-color: #ffffff;
  color: #414343;
  border: none;
  margin: 0px 6px;
  width: fit-content;
  height: 35px;
  padding: 10px 16px 10px 16px;
  gap: 10px;
  border-radius: 18px;
  opacity: 1;
}

.rounded-corner-tab-bulky-active {
  background-color: #0086FF;
  /* border: 1px solid #e1e1e1; */
  color: #ffffff;
  height: 35px;
  border: none;
  margin: 0px 4px;
  /* width: 90px; */
  border-radius: 21px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 16px 10px 16px;
}

.rounded-corner-tab-bulky {
  background-color: #ffffff;
  border: 1px solid #E0E0E0 !important;
  color: #636363;
  height: 35px;
  border: none;
  margin: 0px 4px;
  /* width: 90px; */
  border-radius: 68px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 16px 8px 16px;
  cursor: pointer !important;
}

.rounded-corner-tab-bulky:hover {
  border-color: #505050 !important;
}


.footer-card {
  transition: all .2s;
  height: 70px;
}

.build-insight-table-row {

  border-bottom: 1px solid #dedede;
}

.build-insight-table-div {
  border-right: 1px solid #dedede;
  padding-left: 15px;
  padding-right: 15px;
}

.build-insight-table-header-div {
  text-align: left;
  padding-left: 15px;
  border-right: 1px solid #dedede;
  border-bottom: 1px solid #5d5d5d;

}

.build-insight-table-row> :last-child {
  border-right: none;
}

.placement-bottom-start {
  z-index: 9 !important;
  left: 937.39px !important;
}

.rs-picker-toggle {
  height: 42px !important;
}

.loading-text-hide .loading-text {
  display: none;
}

.single-row-card {
  display: grid;
  grid-template-columns: 22% 22% 29% 17%;
  grid-template-areas:
    'option-1 option-2 option-3 option-4 option-5'
    'option-6 option-6 option-6 option-6 option-6';
  padding: 10px;
  font-size: 11px;
  font-weight: 400;
  color: #9e9e9e;
  border-bottom: 1px solid #eaecec;
}

.ongoingActivityCardBody {
  height: 470px;
  overflow: scroll;
}

.view-details-block {
  grid-area: option-6;
  /* border-top: 1px solid #eaecec; */
}

.single-row-item-one {
  min-width: 250px;
  display: flex;
  align-items: center;
}

.single-row-item-two {
  min-width: 200px;
}

.single-row-item-three {
  min-width: 200px;
}

.single-row-item-three .text-anchor-blue .text-color-black {
  color: #0086ff !important;
}

.single-row-item-four {
  min-width: 285px;
}

.single-row-item-five {
  min-width: 100px;
}

.overriadable-env .MuiDialog-paper {
  height: 100% !important;
  margin-right: 0px;
  margin-left: auto;
  width: 75% !important;
  margin-bottom: 0px !important;
}

.add-job-to-template .MuiDialog-paper {
  margin-top: 35px;
  width: 600px !important;
  margin-bottom: 0px !important;
}

.circle-tab-container {

  background-color: #ffff;
  padding: 8px;
  border-radius: 50px;
  width: fit-content;
  height: 40px;
}

.circle-tab-selector {
  background-color: #ffffff;
  padding-left: 18px;
  padding-right: 18px;
  color: #c6ccce;
  width: fit-content;
  height: 100%;
  border: none;
  border-radius: 50px;
}

.circle-tab-container .active {
  color: #ffff !important;
  background-color: #0096db !important;

}

.footer-card.h-0 {
  height: 0px;
  border: none;
}

.btn-disabeld-flaticon .MuiSvgIcon-root {
  color: #b9b8b8;
}

.btn-disabeld-flaticon .material-icons {
  color: #b9b8b8 !important;
  cursor: not-allowed !important;
  vertical-align: middle;
}

.btn-flaticon-disabled .material-icons {
  color: #b9b8b8 !important;
  cursor: not-allowed !important;
  vertical-align: middle;
}

.btn-flaticon .material-icons {
  vertical-align: middle;
  color: #0086ff;
}

.btn-mui-svg {
  padding: 0px .5rem !important;
}

.single-row-data .btn-disabeld-flaticon .MuiSvgIcon-root {
  color: #b9b8b8 !important
}

/* .single-row-data .btn-mui-svg{
	padding: 0px 0.5rem!important
} */

.integrations-dialog .MuiDialog-scrollPaper {
  margin-right: 0px;
  justify-content: flex-end;


}

.build-card-bottom {
  z-index: 999;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 72px;
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: right;
  box-shadow: 2px -9px 9px -4px rgba(0, 0, 0, 0.1);
}

.build-card-bottom>div {
  padding: 16px;
}

.build-card-bottom>div button {
  cursor: pointer;
  height: 40px;
  width: 74px;
  border-radius: 6px;
  border: 1px solid #9DC0EE;
  color: #124D9B;
  background: white;
  font-size: 12px;
  line-height: 14.63px !important;
  font-weight: 600;
}

.md-step-bar-left-skipped {
  background-color: #B4B4B8;
}

.integrations-dialog .MuiDialog-paperScrollPaper {
  max-height: 100% !important
}

.integrations-dialog .MuiDialog-paper {
  margin: 0px;
}

.integrations-dialog.pipeline-view-params-dialog .MuiPaper-root {
  background-color: #f7f7f7;
  height: 100vh;
  max-width: 1035px;
}

/*Side Nav*/
.side-nav-dialog {
  z-index: 999 !important;
}

.side-nav-dialog .MuiDialog-scrollPaper {
  margin-right: 0px;
  justify-content: flex-start;
  margin-left: 257px;
}

.side-nav-dialog .MuiDialog-paperScrollPaper {
  max-height: 100% !important
}

.side-nav-dialog .MuiDialog-paper {
  margin: 0px;
}

.side-nav-dialog .MuiPaper-root {
  background-color: #f7f7f7;
  height: 100vh;
  max-width: 255px;
}

.side-nav-dialog .MuiDialog-scrollPaper .MuiPaper-rounded {
  border-radius: 0px;
}


.custom-card-exec-paramsn .card-header p {
  font-size: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
}

.custom-card-exec-paramsn .card-body {
  padding-bottom: 0px;
}

.custom-card-exec-paramsn .card-body .execution-params-row {
  font-size: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid #dedede;
  padding: 5px;
}

.custom-card-exec-paramsn .card-body .execution-params-row p {
  line-height: 1;
  padding: 5px;
}

.custom-card-exec-paramsn .card-body .execution-params-row:last-child {
  border-bottom: 0px;
}

.custom-card-exec-paramsn .card-body .execution-params-row:nth-child(even) {
  background-color: #f8f8f8;
}

.integrations-dialog .MuiPaper-root {
  background-color: #F4F4F4;
  height: 100vh;
  max-width: 380px;
}

.integrations-dialog .MuiDialog-scrollPaper .MuiPaper-rounded {
  border-radius: 0px;
}

.integrations-dialog1 .MuiDialog-scrollPaper .MuiPaper-rounded {
  overflow: visible;
}

.integrations-dialog .integration-header-strip {
  background-color: #3e7bfa;
  font-size: 13px;
  font-weight: 300;
  padding: 10px;
  color: #fff;
}

.integration-sub-header-strip {
  background-color: #fff;
  font-size: 13px;
  font-weight: 300;
  padding: 10px;
  color: #000;
}

.integration-boxes {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 35px 15px;
}

.integration-single-box {
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 4px;
  margin: auto;
  margin-bottom: 8px;
}

.integration-single-box img {
  width: 25px;
}

.input-search>div {
  border: 1px solid #b7b7b7;
  margin: 10px;
}

.textarea-resize-vertical-only,
.textarea-resize-vertical-only .input-component textarea {
  resize: vertical;
}

.error-space .input-component .error-message {
  padding-left: 12px !important;
  position: static !important;

}

/* .horizontal-tab-scroll .md-stepper-horizontal{
	width: 100rem;
	overflow-x: scroll;
}
@media screen and ( max-width:1200px){
	.horizontal-tab-scroll .md-stepper-horizontal{
		width: 90rem;
	}
} */
.loading-align-left {}

@keyframes single-div-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.single-div-loading {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 0.25rem solid rgba(255, 255, 255, .2);
  border-top-color: rgb(255, 255, 255);
  animation: single-div-spin 1s infinite linear;
}

.horizontal-tab-scroll .md-stepper-horizontal {
  max-width: 960px;
  overflow-x: scroll;
}

@media screen and (max-width:1200px) {
  .horizontal-tab-scroll .md-stepper-horizontal {
    width: 90rem;
    overflow-x: scroll;
    margin: auto;
  }
}

.input-searchbar {
  display: grid;
  grid-template-columns: 86% 1fr;
  background-color: #fff;
  border: 1px solid #b7b7b7;
  border-radius: 4px;
  align-items: center;
  overflow: hidden;
}

.input-searchbar input {
  border: none !important;
  outline: none !important;
  border-radius: 0px !important;

}

.input-searchbar input:focus {
  border: 0px solid #4671C6 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  outline: none !important;
}

.input-searchbar .input-component {
  margin-bottom: 0px;
}

.input-searchbar .btn {
  padding: 0.4rem 1rem 0.55rem;
  background-color: #dcd8d885;
  border-left: 1px solid #b7b7b7 !important;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.multi-select-checkbox .MuiTextField-root .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  height: 42px
}

.build-failure-chart-list::before {
  content: " ";
  width: 1px;
  height: 20%;
  position: absolute;
  background-color: #c6b6b6;

}

.build-failure-chart-list::after {

  content: " ";
  width: 15px;
  height: 15px;
  background-color: #ff4a4a;
  left: -6px;
  position: absolute;
  border-radius: 50%;
}

.build-failure-container:last-child .build-failure-chart-list::before {
  height: 0px !important;
}


.pie-container.highcharts-data-table table {
  min-width: 120px;

  margin: 1em auto;
}

.avatar-override .sm-round-override {
  height: 30px;
  width: 30px;
}

.height-0 {
  height: 0;
  overflow: hidden
}

.height-172 {
  height: 172px;
}

.slide-down {
  transition: height .5s;
}

.slide-up {
  transition: height .5s;
}

.set-error {
  position: relative;
}

.error-line {
  position: absolute;
  bottom: 2px;
  left: 10px;
}

.set-error .input-component .file-upload .image-upload-wrap {
  border-color: #e9797e;
  background-color: #fce4e4;
}

.upload-input-40 .input-component .file-upload .image-upload-wrap {
  height: 40px
}

.small-loading {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 3px solid #0088ff57;
  border-top-color: #0086ff;
  animation: single-div-spin 1s infinite linear;
}

.section-height-100 {
  height: 180px;
  overflow: auto;
}

.section-height-0 {
  height: 0px;
  overflow: hidden;
}

.chip-bg-color {
  color: #3e73ec !important;
  border: 1px solid #3e73ec !important;
  background-color: #ebf7ff !important;
}


.job-template-v3-step-details .MuiDialog-paperWidthSm {
  width: 70% !important;
  max-width: 100% !important;
}

.MuiMenu-list .comma-class:last-child {
  display: none;
}

.MuiMenu-list .comma-class:nth-last-child(2) {
  display: none;
}

.Mui-disabled .MuiSvgIcon-root {
  color: #838383 !important;
}

.policy-step {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #EBEBEB;
  display: flex;
  align-items: center;
  justify-content: center;
}

.policy-step-active {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #124D9B;
  display: flex;
  align-items: center;
  justify-content: center;
}

.policy-step-success {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #124D9B;
  display: flex;
  align-items: center;
  justify-content: center;
}

.policy-step-details {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 11px;
}

.policy-template-footer {
  border-top: 1px solid #dedede;
  padding: 1rem 1.5rem;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 999;
}

.icon-color {}

.icon-color:hover {
  color: #fff !important;
}

/* .MuiListItem-root {
  background: #FDFDFD;
  border: 1px solid #E9E9E9;
  border-radius: 4px;
  width: calc( 100% - 20px);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 50px
} */

.policy-step-details:last-child .d-to-hide {
  display: none;
}

.dr-switch-div .switch {
  width: 72px;
}

.dr-switch-div .input-component {
  margin-bottom: 0px;
}

.dr-switch-div p {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}

.dr-cluster-box {
  display: flex;
  align-items: center;
  padding: 34px 0px;
  padding-left: 20px;
}

.dr-cluster-box p {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
}

.dr-cluster-box span {
  color: #868686;
}

.active-inactive-input .switch-label::before {
  top: 9px;
}

.active-inactive-input .switch-input:checked~.switch-handle {
  left: 53px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
  top: 1px;
}

.active-inactive-input .switch-label::after {
  left: 13px;
  top: 10px;
}

/* @media all and (max-height: 749px) {
  .ci-cd-edit-input-div {
    height: 228px;
    overflow-y: auto;
    scrollbar-width: thin
  }

  .sidecar-edit-input-div {
    height: 255px;
    overflow-y: auto;
    scrollbar-width: thin
  }

  .ci-cd-edit-input-div .MuiGrid-spacing-xs-2 {
    width: calc(100% + 8px) !important;
  }

  .ci-v3-input-div {
    height: 315px;
    overflow-y: auto;
    scrollbar-width: thin
  }
}

@media all and (min-height: 750px) {
  .ci-cd-edit-input-div {
    height: 313px;
    overflow-y: auto;
    scrollbar-width: thin
  }

  .sidecar-edit-input-div {
    height: 340px;
    overflow-y: auto;
    scrollbar-width: thin
  }

  .ci-cd-edit-input-div .MuiGrid-spacing-xs-2 {
    width: calc(100% + 8px) !important;
  }

  .ci-v3-input-div {
    height: 400px;
    overflow-y: auto;
    scrollbar-width: thin
  }
} */

.ci-cd-edit-input-div {
  height: calc(100vh - 350px);
  overflow-y: auto;
  scrollbar-width: thin
}

.ci-cd-edit-input-div .MuiGrid-spacing-xs-2 {
  width: calc(100% + 8px) !important;
}

.ci-v3-input-div {
  height: calc(100vh - 360px);
  overflow-y: auto;
  scrollbar-width: thin
}

/* Media query for laptops and desktops */
/* @media screen and (min-width: 1024px) {
  .ci-cd-edit-input-div {
    height: calc(100vh - 440px);
  }
} */

.sidecar-edit-input-div {
  height: calc(100vh - 400px);
  overflow-y: auto;
  scrollbar-width: thin
}

.sidecar-edit-input-div .MuiGrid-spacing-xs-2 {
  width: calc(100% + 8px) !important;
}

/* Media query for laptops and desktops */
/* @media screen and (min-width: 1024px) {
  .sidecar-edit-input-div {
    height: calc(100vh - 400px);
  }
} */

@media all and (min-height : 635px) {
  .build-input-div {
    overflow: hidden;
  }
}

.img-with-tag p {
  text-align: center;
  font-size: 12px;
  color: #a9a8a9;
}

.integrations-dialog .MuiPaper-root.card-accordian {
  height: auto;
}


@keyframes fade-inout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fade-in-out {
  animation: fade-inout 1s infinite alternate;
}

.logs-alert-style .MuiSnackbar-root.MuiSnackbar-anchorOriginTopRight {
  top: 190px !important;
  left: auto;
  right: 10px !important;
}

.color-tab-active-dp {
  border: none;
  background-color: #3696db;
  color: white;
}

.color-tab-active-dp label {
  font-weight: bold;
}

.color-tab-inactive-dp {
  background-color: #fff;
  color: #007EFF;
  border: none;
}

.color-tab-inactive-dp label {
  font-weight: bold;
}

.headerul.color-tab-inactive-dp:first-child {
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.headerul.color-tab-inactive-dp:last-child {
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.headerul.color-tab-active-dp:first-child {
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.headerul.color-tab-active-dp:last-child {
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.service-and-branch-listing .sub-header-env {
  background-color: #f5f5f5;
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
}

.expensionPanelTabApplication {
  font-size: 12px;
  padding: 10px 8px;
  color: #9e9e9e !important;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #dedede;
}

.expensionPanelTab.active {
  color: #0086ff !important;
  border-bottom: 2px solid #0086ff !important;
}

.material-icon-custom-style{
  background-color: white;
  border: 1px solid #0086ff;
  padding: 5px;
  border-radius: 4px;
  height: 37px;
  cursor: pointer;
}

.MuiAlert-icon .MuiSvgIcon-root {
  color: #fff;
  align-items: center;
}

.name-data-ans .comma-class:last-child {
  display: none;
}

.stepper-div .md-stepper-horizontal {
  overflow-x: scroll;
}


.integrations-dialog-new.action_card_dialog_width-new {
  width: 1000px !important;
  max-width: 1100px;
  background-color: #F4F4F4;
  right: 0px !important;
  margin-right: 0px;
  justify-content: flex-end;
}


.integrations-dialog.action_card_dialog_width .MuiDialog-paperScrollPaper {
  width: 1100px !important;
}

.integrations-dialog.run-with-params-dialog .MuiDialog-scrollPaper .MuiPaper-rounded {
  width: 1050px;
  max-width: 1050px;
  background-color: #fff;
}

.multiple-select-deployment-dropdown .multi-select-checkbox .MuiTextField-root .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  height: 40px;
}

.multiple-select-deployment-dropdown .input-component {
  margin-top: 16px;
}

.multiple-select-deployment-dropdown .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  background-color: #fff;
}

.integrations-dialog.action_card_dialog_width .z-index-top .MuiPaper-root {
  height: auto !important;
  width: auto !important;
}

.side-nav-dialog .search-card-width .MuiDialog-paperScrollPaper {
  /* responsive needed*/
  width: 255px !important;
  background-color: #fff !important;
}

.z-index-top.MuiSnackbar-root.MuiSnackbar-anchorOriginTopRight {
  top: 55px !important;
  left: auto;
  right: 15px !important;
}

.z-index-top .MuiPaper-root {
  height: fit-content !important;
}

.material-icon-custom-style:hover {
  background-color: #f89927;
}

.service-and-branch-listing:first-child .sub-header-env {
  border-top: 0px solid #dedede;
}

.ul-styles {
  font-size: 12px;

}

.sub-env-row {
  font-size: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid #dedede;
  padding: 5px;
}

.sub-env-row span {
  padding: 5px;
}

.btn-position-change {
  position: absolute;
  bottom: 15px;
  right: 15px;
  background-color: #FF3232;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}


.drawer-div {
  overflow: hidden;
  height: inherit;
  width: 0px;
  /* Adjust the width as needed */
  border-left: 5px solid #FF3232;
  /* border-top: 5px solid red; */
  transition: width 0.5s ease-in-out;
  margin-left: auto;
  position: absolute;
  right: 0;
  top: 0;
}

.text-transform-lower-case {
  text-transform: lowercase !important;
}

.drawer-div.open {
  width: 500px;
  box-shadow: 0px 7px 9px 7px #888888;
  /* Adjust the expanded width as needed */
}

.drawer-div .content {
  position: absolute;
  top: 0;
  right: -300px;
  /* Initially offscreen */
  height: 100%;
  width: 500px;
  /* Adjust the width as needed */
  background-color: #fff;
  /* Adjust the background color as needed */
  transition: right 0.5s ease-in-out;
  z-index: 999;
}

.content-header {
  background-color: #124d9b;
  font-size: 13px;
  color: #fff;
  padding: 10px 15px;

}

.content-header p {
  margin-left: 5px;
  font-size: 14px;
}

.content-header img {
  width: 50px;
}

.drawer-div.open .content {
  right: 0;
  /* Show the content when the container is open */
}

.arrow-button {
  height: 40px;
  width: 40px;
  border-radius: 20px 0px 0px 20px;
  position: absolute;
  right: 5px;
  /* Adjust the positioning as needed */
  top: 25px;
  transform: translateY(-50%);
  background-color: #fff;
  /* Adjust the color as needed */
  cursor: pointer;
  transition: background-color 0.5s ease-in-out;
  border: 1px solid #dedede;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiSvgIcon-root.rotate-btn {
  transition: transform 0.5s ease-in-out;
}

.rotate-btn {
  transform: rotate(180deg);
}


.arrow-btn-move-left {
  right: 500px;
  transition: right 0.5s ease-in-out;
}

.arrow-btn-move-right {
  right: 5px;
  transition: right 0.5s ease-in-out;
}

.arrow:hover {
  background-color: darkblue;
  /* Adjust the hover color as needed */
}

.content-table {
  width: 100%;
  border-collapse: collapse;
  /* border: 1px solid #ddd; */
}

.content-table .table-sub-header {
  background: #fff;

}

.content-table th,
.content-table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  font-size: 12px;
  text-align: left;
}

.content-table th {
  background-color: #f2f2f2;
  font-size: 12px;
}

.content-table .even-row {
  background-color: #fff;
}

.content-table .odd-row {
  background-color: #ffffff;
}

.content-footer {
  background-color: #f8f8f8;
  border-top: 1px solid #dedede;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.btn-posi-bottom {
  position: absolute;
  bottom: 10px;
  right: 50px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.19);
  border-radius: 20px;

}

.btn-danger-v2 {
  background-color: #ff3232;
  background-color: rgb(234, 67, 53);
  color: #fff;
}

.override-dialog-class .MuiDialog-paper {
  height: 640px;
}

.flip-container {
  perspective: 1000px;
  width: 100%;
  height: 100%;
  position: relative;
}

.flipper {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.flip-container.flip .flipper {
  transform: rotateY(90deg);
}

/* 
.tableDiv, */
.messageDiv {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  text-align: center;
  opacity: 1;
  transition: opacity 0.3s;
  z-index: 999;
}

.tableDivi {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  text-align: center;
  opacity: 1;
  transition: opacity 0.3s;
  z-index: 999;
}

.tableDivi.hidden {
  opacity: 0;
  z-index: 0;
}

.tableDiv-container {
  z-index: 9999;
}

.tableDiv-container .canary-table {
  border: 1px solid #E6E6E6;
  border-radius: 6px;
}

.tableDiv.hidden,
.messageDiv.hidden {
  opacity: 0;
}

.messageDiv.hidden {
  z-index: 0;
}

.tableDiv.hidden {
  z-index: 0;
}

.tableDiv-container {
  max-height: 230px;
  overflow-y: scroll;
}

.tableDiv {
  width: 100%;

}

.buttonContainer {
  margin-top: 10px;
}

.buttonContainer button {
  margin: 0 5px;
}

.div-structure {
  text-align: left;
  border: 1px solid #ddd;
}

.div-structure p {
  border-bottom: 1px solid #ddd;
  font-size: 12px;
  padding: 5px;
}

.div-structure p:last-child {
  border-bottom: 0px solid #ddd;
}

.highlighted-box {
  margin: 20px 40px;
  font-size: 14px;
  line-height: 1.2;
  text-align: left;
  color: #fff;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center !important;

}

.steplogswrapper .loading-div .md-stepper-horizontal {
  max-width: 1250px;
  overflow-x: scroll;
}

.select_jira .card-header {
  border: 1px solid #dedede;
  border-radius: 4px;
}

.material-icon-custom-style {
  background-color: white;
  border: 1px solid #0086ff;
  padding: 5px;
  border-radius: 4px;
  height: 37px;
  cursor: pointer;
}

.MuiAlert-icon .MuiSvgIcon-root {
  color: #fff;
  align-items: center;
}

.name-data-ans .comma-class:last-child {
  display: none;
}

.stepper-div .md-stepper-horizontal {
  overflow-x: scroll;
}

.integrations-dialog.action_card_dialog_width .MuiPaper-root {
  width: 1035px !important;
  max-width: 1035px;
}

.integrations-dialog.action_card_dialog_width .MuiDialog-paperScrollPaper::-webkit-scrollbar {
  display: none;
}

.integrations-dialog.action_card_dialog_width .MuiDialog-paperScrollPaper {
  height: 100vh;
  width: 1035px !important;
  overflow: visible;
}

.integrations-dialog-for-monitoring.action_card_dialog_width .MuiDialog-paperScrollPaper {
  width: 483px !important;
  height: 100vh;
}

.integrations-dialog-for-canary.canary_card_dialog_width .MuiDialog-paperScrollPaper {
  width: 803px !important;
  height: 100vh;
}

/* .integrations-dialog-for-canary.canary_exception_dialog_width .MuiDialog-paperScrollPaper {
  width: 655px !important;
} */

.integrations-dialog-for-monitoring .MuiDialog-scrollPaper {
  margin-right: 0px;
  justify-content: flex-end;
}

.integrations-dialog-for-monitoring .MuiDialog-paperScrollPaper {
  max-height: 100% !important;
}

.integrations-dialog-for-canary .MuiDialog-scrollPaper {
  margin-right: 0px;
  justify-content: flex-end;
}

.integrations-dialog-for-canary .MuiDialog-paperScrollPaper {
  max-height: 100% !important;
}

.multiple-select-deployment-dropdown .multi-select-checkbox .MuiTextField-root .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  height: 40px;
}

.multiple-select-deployment-dropdown .input-component {
  margin-top: 16px;
}

.multiple-select-deployment-dropdown .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  background-color: #fff;
}

.integrations-dialog.action_card_dialog_width .z-index-top .MuiPaper-root {
  height: auto !important;
  width: auto !important;
}

.z-index-top.MuiSnackbar-root.MuiSnackbar-anchorOriginTopRight {
  top: 55px !important;
  left: auto;
  right: 15px !important;
}

.material-icon-custom-style:hover {
  background-color: #f89927;
}

.ys-div:last-child .vertical-border-one {
  display: none !important;
  height: 20px !important;
}

.log-dst-button {
  background: transparent;
  border: 1px solid #124d9b !important;
  color: #124d9b;
  white-space: nowrap;
  border-radius: 4px;
}

.log-dst-button:hover {
  background-color: #fff;
  color: #124d9b;
}

.ys-div:last-child .vertical-border-one {
  display: none !important;
  height: 20px !important;
}

@media screen and (max-width:1280px) {
  .handle-overlap-1280 {
    overflow-x: scroll;
  }
}

.bouncing-loader {
  display: flex;
  align-items: center;
  gap: 2px;
}


.bouncing-loader>div {
  transform: translateY(-5px);
  width: 5px;
  height: 5px;
  margin-right: 2px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 1;
  animation-name: bouncing-loader;
  animation-duration: 1.2s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}

.bouncing-loader>div:nth-child(1) {
  animation-delay: 0s;
}

.bouncing-loader>div:nth-child(2) {
  animation-delay: 0.8s;
}

.bouncing-loader>div:nth-child(3) {
  animation-delay: 1.2s;
}

@keyframes bouncing-loader {
  0% {
    transform: translateY(-5px);
  }

  85% {
    transform: translateY(-5px);
  }

  100% {
    opacity: 1.5;
    transform: translateY(-13px);
  }
}

.bouncing-loader>div:nth-child(2) {
  animation-delay: 0.4s;
}

.bouncing-loader>div:nth-child(3) {
  animation-delay: 0.8s;
}

.dialog-card-header {
  font-size: 1.8rem;
  background-color: #124D9B;
  padding: 1rem 0rem 1rem 2rem
}


.v1-dialog .MuiDialog-paperScrollPaper {
  width: auto;
}
.card-header-blue {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #C5C5C5;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  background-color: #124D9B;
  padding: 10px 20px
}

.card-header-new {
  position: absolute;
  z-index: 999;
  width: 100%;
  top: 0px;
  border-bottom: 1px solid #dedede;
  height: 54px;
  padding: 16px 24px 16px 24px;
  background-color: #124D9B;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v1-dialog .MuiDialog-paperScrollPaper {
  width: auto;
}

.git-repo-section {
  background: linear-gradient(180deg, #FFF5F3 0%, rgba(255, 245, 243, 0) 100%);
  padding: 2rem;
}

.buildpiper-section {
  background: linear-gradient(180deg, #F0F6FF 0%, rgba(240, 246, 255, 0) 100%);
  padding: 2rem;
}

.git-repo-badge {
  color: #F05033;
  border: 1px solid #F05033;
  padding: .5rem 1rem;
  border-radius: 4px;
  overflow: hidden;
}

.bp-badge {
  color: #134F99;
  border: 1px solid #134F99;
  padding: 5px 10px;
  border-radius: 4px;
  overflow: hidden;
}


.git-info-section .webhook-link {
  color: #0086FF;
}

.git-info-section .copy-to-clipboard svg {
  font-size: 2rem;
}

.checkbox-wrapper-git .input-component {
  margin-bottom: 0px;
}

.checkbox-wrapper-git .input-component label {
  font-size: 1.4rem;
  color: #5C5C5C;
}

.update-input-class .wrapper-for-search .search-bar input::placeholder {
  color: #939393 !important;
  opacity: 1;
  /* Firefox */
}

.update-input-class .wrapper-for-search .search-bar input::placeholder::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: red !important;
}

.sidecar-init-container>.sideCardCard {
  margin-left: 15px !important;
}

div.sidecar-container:last-child .side-car-border-lines {
  height: 50px;
}


.light-grey {
  color: #DBDBDB !important;
}

.heading-primary {
  font-size: 18px;
  line-height: 1.4;
  font-weight: 500;
  color: #2f2f2f;
}

/* this is a new style applied to the Job Template listing header */
.heading-primary-new {
  font-size: 18px;
  line-height: 1.4;
  font-weight: 500;
  color: #2f2f2f;
}

.heading-secondary {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 600;
  color: #2f2f2f;
}

.sub-heading-primary {
  font-size: 12px;
  line-height: 1.4;
  font-weight: 400;
  color: #505050;
}

.input-label {
  font-size: 14px;
  font-weight: 500;
  color: #2f2f2f;
}

input::placeholder {
  color: #a0a0a0;
  font-weight: 400;
  font-size: 14px;
}

input {
  color: #2f2f2f;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.dynamic-input-left .input-component select {
  border-right: 0px;
  padding-left: 22% !important;
  position: relative !important;
  border-bottom-right-radius:0px;
  border-top-right-radius:0px;
  /* padding-top: 10px; */
}

.dynamic-input-right .input-component select {
  padding-left: 8% !important;
  position: relative !important;
  border-bottom-left-radius:0px;
  border-top-left-radius:0px;
}

.subheading-input-label {
  font-size: 14px;
  font-weight: 500;
  color: #2F2F2F;
}

.MuiAutocomplete-option {
  font-size: 14px;
  font-weight: 500 !important;
  color: #2f2f2f;
}

.MuiAutocomplete-noOptions {
  font-size: 14px;
  color: #2f2f2f !important;
  font-weight: 500 !important;
}

/* Mac Air (and similar laptops) */
@media screen and (max-width: 1440px) {

  /* Adjust styles for Mac Air and similar devices */
  body {
    font-size: 14px;
  }

  .input-label {
    font-size: 14px;
    font-weight: 500;
    color: #2f2f2f;
  }

  .update-search-class .search-bar {
    grid-template-columns: 6% 74% 1fr
  }
}

/* Mac Pro (larger desktop screens) */
@media screen and (min-width: 1440px) and (max-width: 1920px) {

  /* Adjust styles for Mac Pro and similar devices */
  body {
    font-size: 16px;
  }

  .md-stepper-horizontal .md-step.active .md-step-title {
    font-size: 13px;
  }

  .heading-primary {
    font-size: 20px;
  }

  .sub-heading-primary {
    font-size: 14px;
  }

  .input-label {
    font-size: 14px;
    font-weight: 500;
    color: #2f2f2f;
  }

  .side-nav-dialog .MuiDialog-scrollPaper {
    margin-right: 0px;
    justify-content: flex-start;
    margin-left: 291px;
  }


}

/* Large screens (desktops) */
@media screen and (min-width: 1921px) {

  /* Adjust styles for large screens */
  body {
    font-size: 18px;
  }

  .md-stepper-horizontal .md-step.active .md-step-title {
    font-size: 16px;
  }
}

.color-inactive-breadcrumb {
  color: #787878;
}

a.color-inactive-breadcrumb:visited {
  color: #787878;
}

.breadcrumb-navi .ri-arrow-right-s-line:nth-last-child(2) {
  color: #787878;
}

.update-search-class .search-bar {
  grid-template-columns: 6% 80% 1fr
}

@media screen and (min-width: 1280px) and (max-width: 1440px) {
  .update-search-class .search-bar {
    grid-template-columns: 6% 74% 1fr
  }
}

.new-profile-settings {
  background-color: #fff;
  border-radius: 6px;
  padding: 15px;
  padding-bottom: 7px;
}

.profile-section-bg {
  /* background-color: #fff7ec;
  padding: 15px;
  border-radius: 6px;
  display: grid;
  grid-template-columns: 60px 1fr;
  margin-bottom: 15px;
  align-items: center; */
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  gap: 13px;
  align-self: stretch;
  border-radius: 8px;
  background: rgba(254, 161, 17, 0.08);
  margin-bottom: 12px;
}

.profile-section-bg .user-type {
  line-height: 1;
}

.switch-bottom-profile-section {
  /* border: 1px solid #e6e6e6;
  background-color: #fafafa;
  padding: 10px;
  border-radius: 6px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  gap: 20px; */
  display: flex;
  padding: 6px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  /* border: 1px solid  #E6E6E6; */
  background: #FAFAFA;
  margin-bottom: 16px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.04) inset;
}

.active-portal {
  /* background-color: #fff;
  padding: 10px;
  color: #2f2f2f; 
  color: #fea111;
  border: 1px solid #e6e6e6;
  display: inline-block;
  line-height: 1;
  border-radius: 6px;
  text-align: center;
  box-shadow: 0px 1px 4px 0px #00000040; */
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  color: #2f2f2f;
  font-weight: 600;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 6px;
  border: 1px solid #E6E6E6;
  background: #FFF;
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.04);
}

.inactive-portal {
  /* background-color: transparent;
  padding: 10px;
  color: #2f2f2f;
  border: 1px solid transparent;
  display: inline-block;
  line-height: 1;
  border-radius: 6px;
  text-align: center; */
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  color: #787878;
  font-weight: 500;
}

.profile-nav-item {
  font-size: 12px !important;
  font-weight: 500 !important;
  /* border: 1px solid #e6e6e6 !important; */
  padding: 8px 12px !important;
  line-height: 1 !important;
  /* margin-bottom: 10px !important; */
  border-radius: 6px !important;
  background-color: #fff !important;
}

.profile-nav-item:hover {
  background-color: #FAFAFA !important;
}

.profile-nav-item svg.MuiSvgIcon-root {
  /* color: #124d9b !important; */
  color: #2f2f2f !important;
}

.profile-nav-item span {
  /* color: #124d9b !important; */
}

.logout-btn.profile-nav-item svg {
  color: #e30000 !important;
}

.logout-btn.profile-nav-item span {
  color: #e30000 !important;
}

.MuiTypography-root {
  font-weight: 500;
}

.md-stepper-horizontal .tab-container {
  display: flex;
  width: 100%;
  height: 75px;
  /* padding: 4px, 0px, 0px, 0px; */
  background: #FFFFFF;
  box-shadow: 0px 4px 24px 0px #0000001F;
}

.tab-container .stages-div {
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* cursor: pointer; */
}

.tab-container .active-stage {
  border-bottom: 4px solid #0086FF;
}

.tab-container .visited-stage {
  border-bottom: 2px solid #01BB62
}


.tab-container .stages-div .stage-order {
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  color: #797979;
  margin-bottom: 12px;
}

.tab-container .stages-div .stage-name {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  color: #2F2F2F;
}

.tab-container .stages-div .stage-connected-line {
  border-top: 2px solid #E6E6E6;
  width: 53%;
  position: absolute;
  right: -50px;
  top: 27px;

}

.capital-text {
  text-transform: uppercase;
}

.tab-container .stages-div:last-child .stage-connected-line {
  border: 0;
}

.runtimeparams-v3-env .MuiDialog-paper {
  height: 100% !important;
  margin-right: 0px;
  margin-left: auto;
  width: 60% !important;
  margin-bottom: 0px !important;
}


.service-container-v3 .service-overridable-container {
  visibility: hidden;
}


.versioning-round {
  height: 48px;
  width: 48px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.draft_round {
  background: #FEA111;
  box-shadow: 0px 8px 8px 0px #FEA11133;

}

.not_configured_round {
  background: #D1D1D1;
  box-shadow: 0px 8px 8px 0px rgba(208, 208, 208, 0.22);

}

.configured_round {
  background: #01BB62;
  box-shadow: 0px 8px 8px 0px rgba(208, 208, 208, 0.22);

}

.inuse_round {
  background: #0086ff;
  box-shadow: 0px 8px 8px 0px #FEA11133;

}

.versioning-chip {
  font-size: 12px;
  font-weight: 600;
  padding: 8px 10px;
  border-radius: 6px;
  background-color: transparent;
  color: #fff;
  line-height: 1.2;
  height: 'fit-content';
}

.secondary-build-chip {
  font-size: 12px;
  font-weight: 600;
  padding: 8px 10px;
  border-radius: 6px;
  background-color: transparent;
  color: #FEA111;
  line-height: 1.2;
  height: 'fit-content';
}



.not-configured-chip {
  background: rgba(254, 161, 17, 0.20);
  color: #FEA111;
}

.draft-pending-chip {
  background-color: #FCF6E1;
  color: #E1941D;
}

.draft-chip {
  background-color: #EBF5FF;
  color: #407BCA;
}

.color-E1941D {
  color: #E1941D !important;
}

.draft-failed-chip {
  background-color: #FFEBEB;
  color: #c11212;
}

.in-use-chip {
  background-color: #01bb621A;
  color: #01BB62;
}

.chip-v1 {
  padding: 8px 16px;
  border-radius: 6px;
  background-color: transparent;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  width: fit-content;
  color: transparent;
  height: 36px;
  position: relative;
  overflow: hidden;
}

.default-outline-chip-v1 {
  border: 1px solid transparent;
  border-bottom: 2px solid #CACACA;
  /* Initial border style */
  color: #0086FF;
  /* animation: loading 1.5s infinite; Apply loading animation */
}

.chip-v1 {
  padding: 8px 16px;
  border-radius: 6px;
  background-color: transparent;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  width: fit-content;
  color: transparent;
  height: 36px;
  position: relative;
  overflow: hidden;
}

.default-outline-chip-v1 {
  border: 1px solid #cacaca;
  color: #0086FF;
  position: relative;
}

.button-processing::after {
  content: "";
  border-bottom: 2px solid #0086ff;
  position: absolute;
  width: 80px;
  bottom: 0;
  left: 42px;
  animation: moveLine 3s alternate infinite;
}


@keyframes moveLine {
  0% {
    left: -80px;
  }

  25% {
    left: 160px;
  }

  50% {
    left: -80px;
  }

  75% {
    left: 160px;
  }

  100% {
    left: -80px;
  }
}


.icon-btn-v1 {
  font-size: 20px;
  height: 36px;
  width: 36px;
  vertical-align: middle;
  border: 1px solid transparent;
  background-color: #FFFFFF;
  color: #124D9B;
  border-radius: 6px;
  text-align: center;
}

.icon-btn-outline-default {
  border-color: #DBDBDB;
  color: #124D9B;
}

.icon-btn-outline-default:hover {
  background-color: #0086FF;
  color: #fff
}

.icon-btn-outline-danger {
  color: #E95454;
  border-color: #DBDBDB;
}

.icon-btn-outline-danger:hover {
  background-color: #E95454;
  color: #fff;
  border-color: #E95454;
}

.btn-group-v1 button {
  margin-left: 3px;
  margin-right: 3px;
}

.btn-group-v1 button:first-child {
  margin-left: 0px;
}

.btn-group-v1 button:last-child {
  margin-right: 0px;
}

.sm-round-green {
  background-color: #01BB62 !important;
  border-radius: 50%;
  height: 16px;
  width: 16px;
}

.static-round-icon-bg {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.size-20 {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.static-round-icon-bg {
  top: -5px;
  right: -5px;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.btn-sq-icon-primary {
  font-size: 20px;
  color: #124D9B;
  border: 1px solid #dbdbdb;
  max-width: 36px;
  max-height: 36px;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 8px;
}
.btn-sq-icon-primary:hover{
  color: #fff!important;
}
.btn-sq-icon-primary:hover .btn-sq-icon-primary-icon{
  color: #fff!important;
}

.btn-sq-icon-primary-disabled {
  font-size: 20px;
  color: #b9b8b8 !important;
  border: 1px solid #dbdbdb;
  max-width: 36px;
  max-height: 36px;
  border-radius: 6px;
  background-color: transparent;
  padding: 8px;
}

.btn-sq-icon-primary-disabled {
  font-size: 20px;
  color: #b9b8b8 !important;
  border: 1px solid #dbdbdb;
  max-width: 36px;
  max-height: 36px;
  border-radius: 6px;
  background-color: transparent;
  background-color: #ffffff;
  padding: 8px;
}


.btn-sq-icon-primary:hover {
  color: #fff;
  background-color: #0086ff;
  border-color: #0086ff;
}

.vertical-align-super {
  vertical-align: super;
}

.float-cancel-button {
  font-size: 24px;
  background-color: #c11212;
  height: 54px;
  width: 54px;
  border-radius: 6px 0 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 3px;
  margin: 0px;
  margin-right: 0px !important;
  border: none;
  position: absolute;
  top: 0px;
  left: -0px;
}
.btn-icon-v1 {}

.btn-icon-v1 {}

/*My Edit*/

.company-logo {
  display: flex;
  width: 135px;
  height: 38px;
  padding: 0px 29px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-left: 6px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.04);
  object-fit: contain;
}

.user-company-details {
  border-top-right-radius: 50px;
}

.nav-user-avatar {
  height: 47px;
  cursor: pointer;
}

.avatar-user {
  border: 2px solid #8FA0B3;
  font-size: 18px !important;
  background-color: #234361 !important;
  border-color: #99CFFF;
  color: #FFF !important;
  font-weight: 600;
  height: 44px !important;
  width: 44px !important;
}

.nav-user-avatar:hover .avatar-user {
  opacity: 1;
  display: none;
}

.nav-user-avatar:hover .avatar-image {
  opacity: 1;
}

.avatar-image {
  opacity: 0;
  position: absolute;
  /* top: -40px; */
  display: none;
  width: 44px !important;
  height: 44px !important;
}

.capital-text {
  text-transform: uppercase;
}

.ri-eye-line-icon.visible {
  opacity: 1;
}

.ri-eye-line-icon {
  opacity: 0;
  position: absolute;
  right: 5px
}

.ri-eye-line-icon:hover {
  color: #0086ff;
  cursor: pointer;
}

.display-none {
  display: none !important;
}

.sub-container {
  position: relative;
}

.time-text-div {
  position: absolute;
  right: 10px
}

.muiAutocomplete-endAdornment-wrapper .MuiAutocomplete-endAdornment {
  top: 0 !important;
  right: 0 !important;
}

.container-of-items {
  /* width: 100%;  */
  max-width: 600px;
  overflow-x: scroll;
  scroll-behavior: smooth;
}

/* this is new checkbox for clustor on boarding*/
.switch-new-box {
  content: "No";
  cursor: pointer;
  --button-width: 3.2em;
  --button-height: 1.5em;
  --toggle-diameter: 1.5em;
  --button-toggle-offset: calc((var(--button-height) - var(--toggle-diameter)) / 2);
  --toggle-shadow-offset: 10px;
  --toggle-wider: 3em;
  --color-grey: #cccccc;
  --color-green: #4296f4;
  display: flex;
  align-items: center;
  justify-content: center;

}


.slider-new-box {
  display: inline-block;
  width: var(--button-width);
  height: var(--button-height);
  background-color: var(--color-grey);
  border-radius: calc(var(--button-height) / 2);
  position: relative;
  transition: 0.2s all ease-in-out;
}

.slider-new-box::after {
  content: "";
  display: inline-block;
  width: var(--toggle-diameter);
  height: var(--toggle-diameter);
  background-color: #fff;
  border-radius: calc(var(--toggle-diameter) / 2);
  position: absolute;
  top: var(--button-toggle-offset);
  transform: translateX(var(--button-toggle-offset));
  box-shadow: var(--toggle-shadow-offset) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
  transition: 0.2s all ease-in-out;
  border: 1px solid var(--color-green);
}

.switch-new-box input[type="checkbox"]:checked+.slider-new-box {
  background-color: var(--color-green);
}

.switch-new-box input[type="checkbox"]:checked+.slider-new-box::after {
  transform: translateX(calc(var(--button-width) - var(--toggle-diameter) - var(--button-toggle-offset)));
  box-shadow: calc(var(--toggle-shadow-offset) * -1) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
}

.switch-new-box input[type="checkbox"] {
  display: none;
}

.switch-new-box input[type="checkbox"]:active+.slider-new-box::after {
  width: var(--toggle-wider);
}

.switch-new-box input[type="checkbox"]:checked:active+.slider-new-box::after {
  transform: translateX(calc(var(--button-width) - var(--toggle-wider) - var(--button-toggle-offset)));
}

.color-check-green {
  color: #129E5B !important;
}

.download-options {
  height: 60px;
  padding: 10px 12px 10px 12px;
  border-radius: 6px;
  border: 1px solid #F0F0F0;
  opacity: 1;
  position: absolute;
  z-index: 999;
  top: calc(100% + 5px);
  left: -70%;
  background-color: white;
  box-shadow: 0px 4px 4px 0px #00000040;
  }
.cancel-button {
  width: 84px;
  display: flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  border: 1px solid #9DC0EE;
  background: #FFF;
  color: #124D9B;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.cancel-button:hover {
  background: #0086FF !important;
  color: #FFF;
}

.add-group-btn {
  display: flex;
  height: 40px;
  padding: 11px 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  background: #0086FF;
  color: #FFF;
  text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  border: none;
}

.add-group-btn:hover {
  background: #03234D;
  color: #FFF;
}

/* Define the font family for the placeholder text */
::-webkit-input-placeholder {
  font-family: 'Montserrat', sans-serif; /* Change this to your desired font */
}
:-moz-placeholder {
  font-family: 'Montserrat', sans-serif; /* Change this to your desired font */
}
::-moz-placeholder {
  font-family: 'Montserrat', sans-serif; /* Change this to your desired font */
}
:-ms-input-placeholder {
  font-family: 'Montserrat', sans-serif; /* Change this to your desired font */
}
.disable-enable-switch .label-controller{
  visibility: hidden;
}

.download-menu {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.option-text {
  
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  margin-left: 8px;
  color: #5C5C5C;
}

.dialog-align-corner {
  position: relative;
}

.dialog-align-corner .MuiPaper-root {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 0px;
  border-radius: 0px;
  height: 100%;
  max-height: 100%;
  max-width: 900px;
  width: 100%;
  background-color: transparent;
  box-shadow: none;
}

.dialog-align-corner .dialog-sub-component {
  height: inherit;
}

.dialog-align-corner .left-panel-dialog {
  box-shadow: -20px 0px 35px 0px #0000000D inset;
}

.dialog-align-corner .right-panel-dialog {
  box-shadow: 0px 8px 24px 0px #0000000A;
  height: 100vh;
}

.btn-icon-only {
  font-size: 24px;
  background-color: transparent;
  height: 36px;
  width: 36px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 3px;
  margin: 0px;
  margin-right: 0px !important;
  border: none;
}

.btn-icon-only:hover {
  background-color: #f4f4f4;
}

.float-cancel-button {
  font-size: 24px;
  background-color: #c11212;
  height: 54px;
  width: 54px;
  border-radius: 6px 0 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 3px;
  margin: 0px;
  margin-right: 0px !important;
  border: none;
  position: absolute;
  top: 0px;
  left: -0px;
}

.dynamic-input-left .input-component select {
  border-right: 0px;
  padding-left: 22% !important;
  position: relative !important;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;

}

.dynamic-input-right .input-component select {
  padding-left: 8% !important;
  position: relative !important;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.gap-6 {
  gap: 6px;
}

.font-24 {
  font-size: 24px;
}

.revoke-btn {
  color: #C11212 !important;
  background: white;
}

.revoke-btn:hover {
  background-color: #C11212 !important;
  color: white !important;
  border: 1px solid white !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
  font-size: 14px;
      font-size: 14px !important;
    font-weight: 500 !important;
    height: auto;
    line-height: 2 !important;
    padding: 5px 14px !important;
}

/* .md-step-circle>.MuiSvgIcon-root {
  font-size: 24px !important;
} */

.MuiTab-root {
  font-family: Montserrat !important;
}

.step-logs-bar {
  height: 2px;
  width: 70px !important;
}


.step-logs-bar.failed {
  background: #BA0000;
}
.step-logs-bar.new-failed-log {
  background: #BA0000;
}

.step-logs-bar.success {
  background: #129E5B;
}

.step-logs-bar.running {
  background: #0086FF;
}

.step-logs-bar.inqueue {
  background: #FEA111;
}

.step-logs-bar.revoked {
  background: #626262;
}

.step-logs-bar.skipped {
  background: #e7e7e7;
}

.child-build-branch-div {
  border-radius: 6px;
  border: 1px solid #D8D8D8;
  background: linear-gradient(94deg, #FAFAFA 46.72%, #FAFAFA 90.41%);
  padding: 10px 12px 12px 12px;
  margin: 24px 0px;
}

.child-build-branch-div .header-text {
  color: var(--CONTENT-SECONDARY, #505050);
  
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 8px;
}

.child-build-branch-div .card-header{
  height: 100% !important;
  display: flex;
  align-items: center;
}

.override-parent-div {
  margin-top: 16px;
}

.single-override-div .child-build-header {
  color: var(--CONTENT-SECONDARY, #505050);
}
.running-state-icon {
  animation-name: roundinfinite;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

@keyframes roundinfinite {
  0% {
    rotate: 90deg;
  }

  25% {
    rotate: 180deg;
  }

  65% {
    rotate: 270deg;
  }

  100% {
    rotate: 360deg;
  }
}

.btn-status {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 4px;
  height: 23px;
  width: 75px;
  padding: 4px 6px;
  width: 80px !important;
}

.new-revoke {
  border: 1px solid #DBDBDB;
  color: grey;
}

.new-success {

  color: #129E5B;
  border: 1px solid #DEF5E7;
  background: #EFFFF3;
}

.new-failed {
  color: #BA0000;
  border: 1px solid #FBE6E6;
  background: #FFF3F3;
}

.new-running {
  /* width: 100px !important; */
  color: #0086FF;
  border: 1px solid #DEF5E7;
  background: #EBF5FF;
}

.new-inqueue {
  width: 80px !important;
  color: #FEA111;
  border: 1px solid #F4E5CC;
  background: #FCF6E1;
}

.activity-details-btn {
  cursor: pointer;
  height: 36px;
  width: 36px;
  border-radius: 6px;
  border: 1px solid #DBDBDB;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #FFF;
  color: #124D9B;
}

.activity-details-btn:hover {
  background-color: #007eff;
  color: white;
}

.revoke-btn {
  color: #C11212;
}

.revoke-btn:hover {
  background-color: #C11212;
  color: white;
}


/* Hide the default checkbox */
.new-checkbox-container input {
  display: none;
}

.new-checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  /* font-size: 20px; */
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

/* Create a custom checkbox */
.new-checkbox-checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 0.25em;
  transition: all 0.25s;
}


.new-checkbox-container input:checked~.new-checkbox-checkmark {
  background-color: #2196F3;
}


.new-checkbox-checkmark:after {
  content: "";
  position: absolute;
  transform: rotate(0deg);
  border: 1px solid #CDCDCD;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 0.25em;
  transition: all 0.25s, border-width 0.1s;
}


.new-checkbox-container input:checked~.new-checkbox-checkmark:after {
  left: 0.45em;
  top: 0.25em;
  width: 5px;
  height: 10px;
  border-color: white;
  border-width: 0 0.15em 0.15em 0;
  border-radius: 0em;
  transform: rotate(45deg);
}

.activity-card:hover {
  background-color: rgba(245, 245, 245, 0.6) !important;
}

.hover-bg-blue:hover {
  background-color: #004085 !important;
  color: white !important;
}


.v3-advance-tabs {
  display: flex;
  overflow: auto;
  width: 100%;
  height: 47px;
  padding: 0 20px 0 20px;
  border-bottom: 1px solid #E6E6E6;
}


.v3-advance-tabs::-webkit-scrollbar {
  width: 0;
}

.v3-advance-tabs::-webkit-scrollbar-thumb {
  display: none;
}

.v3-advance-tabs::-webkit-scrollbar-track {
  display: none;
  /* For hiding scrollbar track */
}

.v3-advance-tabs .tabs {
  padding: 16px 24px 16px 24px;
  gap: 12px;
  border: 0px 0px 4px 0px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  color: #2F2F2F;
  cursor: pointer;
  text-transform: uppercase;
  flex: 0 0 auto;
}

.v3-advance-tabs .active-tabs {
  padding: 16px 24px 16px 24px;
  gap: 12px;
  border: 0px 0px 4px 0px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.63px;
  text-align: left;
  color: #2F2F2F;
  border-bottom: 4px solid #124D9B;
  cursor: pointer;
  text-transform: uppercase;
  width: fit-content;
  flex: 0 0 auto;

}

.report-tools-tabs {
  display: flex;
  overflow: auto;
  width: 100%;
  height: 47px;
  padding: 0;
  border-bottom: 1px solid #E6E6E6;
}


.report-tools-tabs::-webkit-scrollbar {
  width: 0;
}

.report-tools-tabs::-webkit-scrollbar-thumb {
  display: none;
}

.report-tools-tabs::-webkit-scrollbar-track {
  display: none;
  /* For hiding scrollbar track */
}

.report-tools-tabs .tabs {
  padding: 16px 24px 16px 24px;
  gap: 12px;
  border: 0px 0px 4px 0px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  color: #2F2F2F;
  cursor: pointer;
  text-transform: uppercase;
  flex: 0 0 auto;
}

.report-tools-tabs .active-tabs {
  padding: 16px 24px 16px 24px;
  gap: 12px;
  border: 0px 0px 4px 0px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.63px;
  text-align: left;
  color: #2F2F2F;
  border-bottom: 4px solid #0086FF;
  cursor: pointer;
  text-transform: uppercase;
  width: fit-content;
  flex: 0 0 auto;

}


.disable-enable-switch .label-controller {
  visibility: hidden;
}

.inner-switch-input.disable-enable-switch .label-controller {
  display: none;
}

.mb-24{
  margin-bottom: 24px;
}
.disable-enable-switch .label-controller{
  visibility: hidden;
}
.inner-switch-input.disable-enable-switch .label-controller{
  display: none;
}
.inner-switch-input.disable-enable-switch .input-component{
  margin-bottom: 0px;
  width: 100%;
}
.inner-switch-input.disable-enable-switch .input-component {
  margin-bottom: 0px;
  width: 100%;
}

.widget-input-div .input-component {
  margin-bottom: 0.5rem;
}

.versionBtn {
  display: flex;
  width: 48px;
  padding: 3px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 6px;
  background: #129E5B;
  color: #FFF;
  
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.single-override-div .hr-line {
  width: 100%;
  height: 1px;
  background-color: #E6E6E6;
  margin-left: 12px;
}
.h-40px {
  height: 40px !important;
}

.step-header-new {
  width: 300px;
  color: #505050;
  
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  /* borderBottom: '1px solid #E6E6E6', */

}

.step-body-new {
  display: flex;
  color: #2F2F2F;

  
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 21px */
}

/* [starting here] created new style for horizontal scroll*/
div.horizontal_scroll_div {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 3px;
}

div.horizontal_scroll_div button {
  margin: 5px;
  height: 30px;
  width: 30px;
  background: #FFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #CFCFCF;


}

.sample-input .Mui-disabled {
  background-color: transparent !important;
}

.sample-input input:disabled {
  opacity: .0;
}

div.horizontal_scroll_div button:nth-child(1) {
  -webkit-box-shadow: 29px 8.5px 25.5px 8px rgba(250, 250, 250, 1);
  -moz-box-shadow: 29px 8.5px 25.5px 8px rgba(250, 250, 250, 1);
  box-shadow: 29px 8.5px 25.5px 8px white;
}

div.horizontal_scroll_div button:nth-child(1){
  -webkit-box-shadow: 29px 0px 5px 0px rgba(250, 250, 250, 1);
  -moz-box-shadow: 29px 0px 5px 0px rgba(250, 250, 250, 1);
  box-shadow: 29px 11px 12px 27px white;
}
div.horizontal_scroll_div button:nth-child(2) {
  -webkit-box-shadow: -26px 0px 5px 0px rgba(250, 250, 250, 1);
  -moz-box-shadow: -26px 0px 5px 0px rgba(250, 250, 250, 1);
  box-shadow: 35px 0 2px 0px white !important;
}

div.horizontal_scroll_div button:hover {
  background-color: #0086FF;
  color: white;
}

div.horizontal_scroll_div div {
  scroll-behavior: smooth;
  white-space: nowrap;
  /* width: 90%; */
  overflow-x: auto;
  display: flex;
  align-items: center;
  gap: 20px;
}

div.horizontal_scroll_div div::-webkit-scrollbar {
  display: none;
}

div.horizontal_scroll_div div::-webkit-scrollbar {
  display: none;
}
div.horizontal_scroll_div li {
  cursor: pointer;
  list-style: none;
  padding: 5px 10px;
  font-size: 13px;
  color: #666;
  text-transform: uppercase;
}

div.horizontal_scroll_div li.active {
  color: #0096db;
  border-bottom: 3px solid #0096db;
}

.ad-left-arrow {
  display: none;
}

.ad-right-arrow {
  display: none;
}

/* .v3-advance-tabs:hover .ad-left-arrow {
  display:block;
} 
.v3-advance-tabs:hover .ad-right-arrow {
  display:block;
}  */

/* [eneded here] */
.menu-list-service {
  padding-left: 12px;
  height: 30px;

}
.menu-list-service:hover{
  background: #8080804a;
}

.pin-container {
  display: inline-flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  background: #FAFAFA;
  color: #787878;
  height: 32px;
  cursor: pointer;
}

.pin-container:hover {
  color: #0086FF;
  background: #EBF5FF;
}
.pipeline-history-back{
  color:#787878 !important;
}

.pipeline-history-back:hover{
  color:#0086ff !important;
}

.monitoring-icon{
  width: 40px;
  height: 35px;
  background: radial-gradient(circle, rgba(107, 116, 168, 0.7) 0%, rgba(107, 116, 168, 0.3) 40%, rgba(255, 255, 255, 0.5) 80%, rgba(255, 255, 255, 0.2) 100%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: blink-radient 1s ease-in-out infinite;
 
}

.monitoring-icon:hover{
  animation: none;
  background: #7A89EE;
}


@keyframes blink-radient {
  0% {
    background: none;
  }
  20% {
    background: none;
  }
  25%{
    background: radial-gradient(circle, #6b74a8 0%, #6b74a8 20%, #6b74a8 30%, rgba(255, 255, 255, 0.7) 40%, transparent 45%, transparent 80%, transparent 100%);
  }
  30% {
    background: radial-gradient(circle, #6b74a8 0%, #6b74a8 20%, #6b74a8 30%, rgba(255, 255, 255, 0.7) 40%, transparent 45%, transparent 80%, transparent 100%);
  }
  35%{
    background: radial-gradient(circle, #6b74a8 0%, #6b74a8 20%, #6b74a8 30%, rgba(255, 255, 255, 0.7) 50%, transparent 55%, transparent 80%, transparent 100%);
  }
  40% {
    background: radial-gradient(circle, #6b74a8 0%, #6b74a8 40%, #6b74a8 50%, rgba(255, 255, 255, 0.7) 80%, rgba(255, 255, 255, 0.7) 100%);
  }
  50% {
    background: radial-gradient(circle, #6b74a8 0%, #6b74a8 40%, #6b74a8 50%, rgba(255, 255, 255, 0.7) 80%, rgba(255, 255, 255, 0.7) 100%);
  }
  60% {
    background: radial-gradient(circle, #6b74a8 0%, #6b74a8 40%, #6b74a8 50%, rgba(255, 255, 255, 0.7) 80%, rgba(255, 255, 255, 0.7) 100%);
  }
  65% {
    background: radial-gradient(circle, #6b74a8 0%, #6b74a8 40%, #6b74a8 50%, rgba(255, 255, 255, 0.7) 80%, rgba(255, 255, 255, 0.7) 100%);
  }
  70% {
    background: radial-gradient(circle, #6b74a8 0%, #6b74a8 20%, #6b74a8 30%, rgba(255, 255, 255, 0.7) 50%, transparent 55%, transparent 80%, transparent 100%);
  }
  75% {
    background: radial-gradient(circle, #6b74a8 0%, #6b74a8 20%, #6b74a8 30%, rgba(255, 255, 255, 0.7) 40%, transparent 45%, transparent 80%, transparent 100%);
  }
  80% {
    background: none;
  }
  100% {
    background: none;
  }
}


.btn-sq-icon-primary-transparent {
  font-size: 20px;
  color: #124D9B;
  border: none;
  max-width: 36px;
  max-height: 36px;
  border-radius: 6px;
  background-color: transparent;
  padding: 8px;
}

.btn-sq-icon-primary-transparent:hover {
  color: #fff;
  background-color: #0086ff;
  border-color: #0086ff;
  border: 1px solid #dbdbdb;
}

.pipeline-history-back{
  color:#787878 !important;
}

.pipeline-history-back:hover{
  color:#0086ff !important;
}

/* For Log steps scroll */
.logs-step::-webkit-scrollbar {
  width: 12px !important;
  height: 5px !important;
}
.logs-step::-webkit-scrollbar-track {
  background-color: #ffffff !important; /* White track */
  -webkit-box-shadow: none !important;
}
.logs-step::-webkit-scrollbar-thumb {
  background:#ece9e9 !important; /* Gray thumb */
  border-radius: 6px;
}
.logs-step::-webkit-scrollbar-thumb:hover {
  background: #C5C5C5 !important; /* Darker gray thumb on hover */
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s; 
}

.resend-btn:hover{
  text-decoration: underline;
}

/* input[data-autocompleted] {
    background-color: transparent !important;
} */

/* [eneded here] */
.float-button-logs{
  color: #fff;
  cursor: pointer;
  display: flex;
  padding: 10px;
  background: #FEA111;
  text-align: center;
  align-items: center;
  border-radius: 6px;
  justify-content: center;
  position: absolute;
  border: none;
  font-size: 12px;
  font-weight: 600;
 bottom: 50px;
 left: 50px;
}
.markdown-rapper{
    font-size: 12px;
    font-weight: 500;
  }
.markdown-rapper code{
  white-space: normal;
}
.sample-checkbox-wrapper .input-component{
  display: flex;
  flex-direction: row-reverse;
}
.bg-watermark{
  background-image: url('/public/images/bp-watermark1.png')!important;
  background-repeat: no-repeat;
  background-position: bottom left;
  height: 148px;
  width: 300px;
  position: absolute;
  left: 0px;
  bottom: 24px;
  opacity: 0.16; 
  z-index: 1;
}
.vertical-canary-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 6px 12px;
}


.vertical-canary-tabs .metric-name {
  font-size: 12px;
  font-weight: 500;
  color: #2F2F2F;
}

.vertical-canary-tabs .metric-status-failed {
  padding: 2px 6px;
  border-radius: 4px;
  background-color: #FFEBEB;
  
  font-size: 12px;
  font-weight: 600;
  color: #C11212
}

.vertical-canary-tabs .metric-status-pass {
  padding: 2px 6px;
  border-radius: 4px;
  background-color: #E6FBEA;
  
  font-size: 12px;
  font-weight: 600;
  color : #129E5B
}

.canary_card_dialog_width {
  width : "803px !important",
}

/* [eneded here] */
.inner-switch-input.disable-enable-switch .label-controller{
  display: none!important;
}
.inner-switch-input.disable-enable-switch > .input-component > .d-flex{
  display: none!important;
}
.width-fix {
  width: 80px;
}
.switch.ed-switch .switch-input:checked~.switch-handle-fix {
  left: 63px;
}
.version-chip{
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  font-size: 12px;
  color: #0086ff;
  font-weight: 600;
  padding: 4px 10px;
}

.canvas-gjt:last-child .canvas-gjt33{
  height: 0px!important;
/* [eneded here] */
}
.scrollable-div-wrapper .container-of-items{
max-width: 450px;
}

.p-20{
  padding: 20px;
}

.font-weight-100{
  font-weight: 100 !important;
}

.jira-icon{
  padding:6px;
  border-radius:4px;
  color:#ffffff;
  width:24px;
  height:24px;
  display:flex;
  align-items:center;
  justify-content:center;
}

.locker svg {
  position: relative;
  /* top: 16px; */
  fill: #0086ff !important;
}
.btn-prime {
  color: #fff !important;
  background-color: #0086ff !important;
  /* border-color: #0db79b !important;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.23); */
  font-size: 12px !important;
  text-transform: uppercase;
  padding: 11px 16px 11px 12px !important;
  min-width: 150px;
  border-radius: 6px;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s; 
}

.jira-icon{
  padding:6px;
  border-radius:4px;
  color:#ffffff;
  width:24px;
  height:24px;
  display:flex;
  align-items:center;
  justify-content:center;
}
.MuiPopover-paper::-webkit-scrollbar {
  display: none
}
.checkbox-wrapper .input-component{
  margin-bottom: 0px;
  
}
.scrollable-div__p1::-webkit-scrollbar-thumb{
  background-color: darkgray!important;
  visibility: hidden;
}
.scrollable-div__p1::-webkit-scrollbar-track{
  box-shadow: none!important;
  background-color: #f4f4f4 !important;
  visibility: hidden;
}
.scrollable-div__p1:hover::-webkit-scrollbar-thumb{
  background-color: darkgray!important;
  visibility: visible;
}
.scrollable-div__p1:hover::-webkit-scrollbar-track{
  box-shadow: none!important;
  background-color: #f4f4f4 !important;
  visibility: visible;
}

.MuiSnackbar-root.MuiSnackbar-anchorOriginTopRight.supreme_priority_class{
  z-index: 99999999!important;
}
.supreme_priority_class .MuiSnackbar-root.MuiSnackbar-anchorOriginTopRight{
  z-index: 99999999!important;
}
.markdown-rapper ol, .markdown-rapper ul {
    list-style: none;
    font: 12px
  }
  .markdown-rapper p{
    margin-bottom: 10px;
  }
  .markdown-rapper li{
    margin-bottom: 10px;
   }
.snackbar-wrapper .MuiAlert-root {
  background-color: #fff !important;
  border-left: 5px solid transparent;
  box-shadow: 0px 20px 34px 0px #00000029;
  border-radius: 10px;
  min-width: 330px;
  padding: 16px 20px;
  display: flex;
  align-items: center;
}
.snackbar-wrapper .MuiAlert-message{
  padding: 0px !important;
}
.snackbar-wrapper .MuiAlert-root.MuiAlert-standardError {
  border-color: #E95454;
}

.snackbar-wrapper .MuiAlert-root.MuiAlert-standardSuccess {
  border-color: #52D376;
}
.snackbar-custom-alert {
  align-items: center;
}
.snackbar-wrapper .MuiAlert-icon {
  display: none !important;
}
.snackbar-custom-alert .btn-transparent:hover{
  background-color: transparent!important;
}

.snackbar-div .MuiPaper-root{
  width: auto !important;
}

.database-type-list{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.database-type-card > span{
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  color:#2F2F2F;

}

.database-type{
  display: flex;
  align-items: center;
  gap: 8px;
}

.database-type .option-capsule {
  border-radius: 36px;
  border: 1px solid #E0E0E0;
  background-color: #FFFFFF;
  color: #636363;
  padding: 8px 16px;
  font-weight: 600;
  font-size: 14px;
}
.material-ui-chip-wrapper .MuiChip-deleteIcon{
height: auto;
width: auto;
}
.material-ui-chip-wrapper .color-white{
  color: #fff!important;
}
.sidebar-job-temp{
  height: calc(100vh - 100px);
  overflow-y: scroll;
}