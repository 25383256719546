
.snackbar-wrapper .MuiAlert-root {
    background-color: #fff !important;
    border-left: 5px solid transparent;
    box-shadow: 0px 20px 34px 0px #00000029;
    border-radius: 10px;
    min-width: 330px;
    padding: 16px 20px;
    display: flex;
    align-items: center;
  }
  .snackbar-wrapper .MuiAlert-message{
    padding: 0px !important;
  }
  .snackbar-wrapper .MuiAlert-root.MuiAlert-standardError {
    border-color: #E95454;
  }
  
  .snackbar-wrapper .MuiAlert-root.MuiAlert-standardSuccess {
    border-color: #52D376;
  }
  .snackbar-custom-alert {
    align-items: center;
  }
  .snackbar-wrapper .MuiAlert-icon {
    display: none !important;
  }
  .snackbar-custom-alert .btn-transparent:hover{
    background-color: transparent!important;
  }