

.page-root-class{
    padding-top: 65px;
    padding-left: 35px;
    padding-right: 35px;
    background-color: #e7eeee;
    height: calc(100% - 60px);
    overflow: auto;
}
.btn-v2{
    font-size: 12px;
    background-color: transparent;
    border-radius: 4px;
    border: 0px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btn-icon-v2{
    font-size: 24px;
    font-weight: 300;
}
.btn-icon-secondary-v2 .MuiSvgIcon-root{
    color: #607086;
}
.btn-icon-round-v2{
    border-radius: 8px;
    padding: 12px;
}
.heading-icon{
    background-color: #fff;
    border-radius: 4px;
    height: 55px;
    width: 55px;
}
.heading-icon .icon-vs{
    width: 28px;
    height: 28px;
}
.text-color-primary{
    color: #394048;
}
.single-img-card{
    background-color: #fff;
    border-radius: 8px;
    width: 195px;
    height: 136px;
    margin: auto;
}
.icon-bg-grey{
    height: 70px;
    width: 70px;
    background-color: #f0f2fb;
    border-radius: 35px;
}
.footer-bottom{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #FFF;
    color: white;
    text-align: right;
    padding: 10px;
}