.add-pipeline-card{
    /* border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px;
    background: #f9f9f9;
    border: 1px solid #dedede; */
    overflow: hidden;
}
.add-pipeline-card-head{
    /* background-color: #fbfbfb;
    
    font-weight: 500; */
    font-size: 13px;
    padding: 15px;
    /* border-bottom: 1px solid #dedede; */
}
.add-pipeline-card-body{
    padding: 0px 0px;
    /* background-color: #fff; */
}
.add-pipeline-card-footer{
    background-color: #fbfbfb;
    padding: 15px;
    border-top: 1px solid #dedede;
    display: flex;
    justify-content: flex-end;
}

.btn-rnd{
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-rnd:hover{
    background-color: #eeeeee;
    transition: ease-in-out .2s;
}
.pd-20{
    padding: 20px;
}
.btn-grey-outline{
    border: 2px solid #7f8288;
    font-size: 12px;
    color: #7f8288;
    padding: 5px;
    border-radius: 4px;
    height: 32px;
    min-width: 90px;
    display: inline-block;
    text-align: center;
    line-height: 1.5;
    font-weight: bold;
    margin: 0px 3px;
}
.btn-blue-curve{
    background-color:#0086ff;
    color:#fff;
    font-size:12px;
    font-weight:400;
    border-color:#0086ff}
.btn-blue{
    font-size: 12px;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    height: 32px;
    width: 80px;
    background-color: #0086ff;
    display: inline-block;
    text-align: center;
    line-height: 2;
    font-weight: bold;
}
.main-div{
    /* padding: 32px 0px; */
    margin-bottom: 20px;
}
.sub-text .flaticon-clock-with-white-face, .sub-text .flaticon-flash-on-indicator{
    margin: 0px 5px 0px 0px!important;
}
.main-div .main-heading{
    font-size: 24px;
    font-weight: 300;
    line-height: 1.5;
}
.main-div .sub-heading{
    color: #666;
    font-size: 12px;
    font-weight: 300;
    line-height: 1;
}
.pipeline-detailed-card{
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0.877px 1.798px 14px 0px rgba(0, 0, 0, 0.15);
    width: 100%;
    margin-top: 10px;
}
.pipeline-detailed-card-head{
    padding: 32px 0px;
    width: 100%;
    margin-top: 12px;
}
.general-head-data{
    display: flex;
    align-items: center;
}
.pipeline-name{
    font-size: 18px;
    line-height: 1;
    font-weight: 400;
    color: #191a1c;
    margin: 0px 0px 0px 0px;
    max-width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.data-frequency-retention{
    display: flex;
    margin: 0px 5px 0px 0px;
}
.main-text-dark{
    font-size: 11px;
    line-height: 1;
    color: #838383;
}
.main-text-lite{
    font-size: 11px;
    line-height: 1;
    color: #ba9999;
}
.text-box-pipeline-head{
    margin-right: 10px;
    line-height: 1.2;
}
.owner-name .main-text{
    margin-right: 0rem;
}
.chip-default-square{
    max-width: 93px;
    line-height: 2;
    padding: 0px 5px;
    border-radius: 3px;
    background-color: #bed1ef;
    font-size: 10px;
    color: #3e434b;
    font-weight: 400;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 1px;
}
.chip-default-square:first-child{
    margin-left: 0px;
}
.mb-5{
    margin-bottom: 5px;
}
.pipeline-service-data{
    margin:0px 10px;
}
.chip-bg-dark-grey{
    background-color: #babec2;
    color: #fff;
}
.edit-btn{
    margin-left: auto;
}
.section-header{
    background-color: #fbfbfb;
    border: 1px solid #ebedee;
    border-right: none;
    font-size: 14px;
    color: #2b2b2b;
    width: 100%;
}
.pipeline-detailed-card-body{
    display: grid;
    /* grid-template-columns: 70% 30%; */
    height: 50.5rem;
    overflow-y: hidden;
    border-radius: 8px;
}
.parent-pipeline-box{
    border-radius: 8px;
    box-shadow: 0px 8px 6.5px rgba(0,0,0,0.21);
    width: 250px;
}
.task-name-pipeline .flaticon-right-arrow-forward::before{
    font-size: 11px!important;
    color: #a4abcf!important;
}
.bg-transparent{
    background-color: transparent!important;
    padding: 2px 0px!important;
}
.parent-pipeline-box-head-stage-name-card{
    overflow: hidden;
    background-color: #4652a2;
    height: 50px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    position: relative;
}
.parent-pipeline-box-head{
    overflow: hidden;
    background-color: #4652a2;
    height: 32px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px 4px 0px 0px;
    position: relative;
}
.parent-pipeline-box-head .more-option span.flaticon-show-more-button-with-three-dots {
    display: inline-block;
    margin: 0px;
    color: #fff;
}
.parent-pipeline-box-body{
    background-color: #5262c8;
    padding: 10px;
    position: relative;
    border-radius: 0px 0px 4px 4px;
}
.parent-pipeline-box-footer{
    background-color: #51598b;
    padding: 0px 5px;
    height: 32px;
    border-radius: 0px 0px 4px 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.fullscreen-button{
    background-color: transparent;
    border: none;
    position: relative;
    right: 20px;
    bottom: 50px;
    z-index: 9999;
}
/* pre{
    overflow-y: scroll!important;
    height: 89vh;
} */
.service-to-run-on{
    color: #fff;
    display: flex;
    font-size: 11px;
    line-height: 2;
    margin: 10px 0px 0px;
}
.task-type{
    font-size: 10px;
    color: #fff;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 110px;
    margin-top: 6px;
}
.card-head-content{
    font-size: 11px;
    color: #fff;
    line-height: 1;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
}
.text-lite-purple [class^="flaticon-"]:before, [class*=" flaticon-"]:before, [class^="flaticon-"]:after, [class*=" flaticon-"]:after{
    color: #a4abcf!important;
}
.text-white{
    color: #fff;
}
.task-name-pipeline{
    font-size: 11px;
    color: #fff;
    line-height: 3;
}
.pd-bottom-10{
    padding-top: 10px;
}
.text-box-pipeline div{
    line-height: 1.2;
    font-size: 11px;
    color: #fff;
    opacity: 0.5;
}
.height-empty-box{
    height: calc(100% - 5.5rem)!important;
}
.top-40{
    top: 40px!important;
}
.corner-count-box{
    height: 20px;
    width: 20px;
    border-radius: 4px 0px 4px 0px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #97a1de;
    margin: auto;
    text-align: center;
    font-size: 11px;
    color: #fff;
    line-height: 2;
}
.pipeline-stage-card-border-right{
    width: 8rem;
    height: 3px ;
    background-color: #898989;
    position: absolute;
    top: 40px;
    right: -80px;
}
.pipeline-stage-card-border-left {
    width: 3rem;
    height: 3px;
    background-color: #898989;
    position: absolute;
    top: 0;
    top: 70px;
    left: -28px;
}
.pipeline-stage-card-border-vertical {
    width: 3px;
    height: calc(100% - 9rem);
    background-color: #898989;
    position: absolute;
    display: block;
    top: 26px;
    left: -3rem;
    transition: background 4s;
}
.top-26{
    top: 26px;
}
.pipeline-stage-card{
    position: relative;
    width: fit-content;
    display: flex;
    margin: 0px 25px;
}
.pipeline-stage-card:first-child{
    margin-left: 0px;
}
.pipeline-stage-card-head{
    display: flex;
    position: relative;
}
.empty-box{
    box-shadow: none;
    border: 2px dashed #5262c8;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.empty-box .btn .flaticon-add-button-inside-black-circle{
    color: #5262c8;
    display: block!important;
}
.empty-box .btn .flaticon-add-button-inside-black-circle::before{
    font-size:18px!important ;
}
.text-btn{
    font-size: 11px;
    line-height: 1.5;
}
.d-block{
    display: block;
}
.bg-dark-purple{
    background-color: #6b74a8;
}
.bg-light-purple{
    background-color: #7881b4;
}
.line-height-1{
    line-height: 1.5;
}
.tag-under-card{
    background-color: #52bfe7;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    padding: 2px 4px;
    font-size: 11px;
    height: 21px;
    min-width: 40px;
    width: fit-content;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 18px;
    margin: 0rem .5rem;
}
.tag-under-card-non-dependent-job{
    background-color: #818abb;
    border-radius: 4px;
    color: #fff;
    padding: 0px 4px;
    font-size: 11px;
    height: 21px;
    min-width: 40px;
    width: fit-content;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 18px;
    margin: 0rem .5rem;
}
.bg-purple-chip{
    background-color: #7b84b8
}
.tag-bg{
    background-color: #818abb;
    color: #fff;
    max-width: 100px;
}
.search-section{
    background-color: #fff;
    display: grid;
    grid-template-columns: 40% 40% 1fr;
    gap: 10px;
    align-items: center;
    padding: 10px;
    margin: 15px 0px;
    border-radius: 8px;
    border: 1px solid #ebedee;
    box-shadow: rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px;
}
.search-section div .btn-grey-outline{
    background-color: #fff;
} 
.m-25{
    margin: 0px .25rem;
}
.vertical-box {
    margin-top: 2rem;
    margin-left: 0rem;
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
}
.pipeline-view{
    overflow: hidden;
    background-image: url('/public/images/bg-pipeline-view.png');
    background-size: 12px;
    border-radius: 0px 0px 0px 8px;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
}
.pipeline-stage-card-structure{
    position: relative;
    height: 100%;
    margin-right: 4rem;
    margin-left: 4rem;
}
.scrollable-view .input-component .image-upload-wrap{
 width: 750px;
}
@media screen and (max-width : 1280px){
    .file-upload-input {
        position: absolute;
        margin: 0;
        padding: 0;
        outline: none;
        opacity: 1;
        cursor: pointer;
        max-height: 39px;
        color: transparent;
        width: 92px;
        bottom: 0px;
        right: 0px;
        border: none;
    }
    .scrollable-view .input-component .image-upload-wrap{
        width: 640px;
       }   
}
.scrollable-view{
    overflow-y: scroll;
    height: 100%;
    
}
/* .scrollable-view::-webkit-scrollbar {
   
  } */
.empty-box .btn{
    background-color: transparent;
}
.more-option{
    display: flex;
}
.if-Approval span{
    font-size: 10px;
    color: #fff;
    margin: 0px .1rem;
}
  /* Hide scrollbar for IE and Edge */
  .scrollable-view {
    -ms-overflow-style: none!important;
  }
  .section-header-right-panel{
    background-color: #fbfbfb;
    border: 1px solid #ebedee;
    border-right: none;
    font-size: 14px;
    color: #2b2b2b;
    width: 100%;
  }
  .section-header-right-panel ul.headerul li {
    padding: 14px 20px;
}
.fill-details-card{
    height: calc(100% - 0px);
    display: grid;
    grid-template-rows: max-content;
    background-color: #ededed;
}
.fill-details-head{
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border-bottom: 1px solid #ebeded;
}
.fill-details-head p{
    font-size: 13px;
    line-height: 2;
}
.fill-details-body{
    background-color: #ededed;
    /* overflow-y: auto; */
    margin: 10px 20px;
    /* height: 350px; */
}
.fill-details-body .input-component{
    /* padding: 10px; */
    padding-bottom: 20px;
    margin-bottom: 0px;
}
.fill-details-body.input-css-controller .input-component input{
    border: 0px;
}

.fill-details-body.input-css-controller .input-component input::placeholder{
    color: #A8A8A8;
}
.fill-details-body.input-css-controller .conditions-div-header .heading{
    color: #2f2f2f;
    font-weight: 500;
    font-size: 14px;
}
.fill-details-body.input-css-controller .input-component .label-controller{
    color: #2f2f2f
}
.fill-details-body.input-css-controller .switch-input-wrapper
.switch-input:checked ~ .switch-label{
    background-color: #007EFF !important;
    border: none;
    padding: 5px 30px;
    top: -5px
}
.fill-details-body.input-css-controller .switch-input-wrapper
.switch-input ~ .switch-label{
    background-color: #4d647b !important;
    border: none;
    padding: 5px 30px;
    top: -5px
}
.fill-details-body.input-css-controller .switch-input-wrapper 
.switch-input ~ .switch-handle {
    box-shadow: none;
    background: #fff;
    height: 15px;
    width: 15px;
    top: 3px;
    left: 8px;
}
.fill-details-body.input-css-controller .switch-input-wrapper 
.switch-input ~ .switch-label::before {
    top: 15px;
    font-size: 12px;
}
.fill-details-body.input-css-controller .switch-input-wrapper .switch-input:checked ~ .switch-handle{
    box-shadow: none;
    background: #fff;
    height: 15px;
    width: 15px;
    top: 3px;
    left: 38px;
}
.fill-details-body.input-css-controller .switch-input-wrapper .switch-label::after {
    left: 11px;
    top: 15.5px;
    font-size: 12px;
}
.fill-details-body .card-header {
    border-bottom: 1px solid #ebeded;
    border-top: none;
    border-left: none;
    border-right: none;
    height: 50px;
    padding: 10px;
    background-color: #fff;
    display: flex;
    border-radius:0px ;
    justify-content: space-between;
    align-items: center;
}
.fill-details-body  .card-header .heading{
    color: #828282;
    font-size: 12px;
    font-weight: 400;
}
.fill-details-body.input-css-controller .select{
    border-color: #e7e7e7;
}
.pipeline-right-content{
    /* border-left: 1px solid #ebeded; */
    position: relative;
    width: 100%;
    min-width: 240px;
    height: 100%;
}
.pipeline-right-content .section-footer-right-panel{
    position: absolute;
    bottom: 0px;
    padding: 10px;
    border-top: 1px solid #dedede;
    width: 100%;
}
.fill-details-footer{
    display: flex;
    justify-content: flex-end;
}
.switch-input-wrapper .switch {
    width: auto;
    min-width: 50px;
}
.btn-clear{
    font-size: 12px;
    font-weight: 400;
    /* border: 2px solid #2b2b2b; */
    border-radius: 4px;
    margin-right: 5px;
    color: #222;
    height: 30px;
    min-width: 80px;
}
.btn-add{
    font-size: 12px;
    font-weight: 400;
    border-radius: 4px;
    margin-right: 5px;
    line-height: 1;
    color: #fff;
    background-color: #0086ff;
    /* height: 30px; */
    min-width: 80px;
}
.fill-details-footer{
    background-color: #f5f5f5;
    padding: 10px ;
    border: 1px solid #ebeded;
    align-self: flex-end;
    border-radius: 0px 0px 8px 0px;
}
.fill-details-body .input-component{
   position: relative;
}

#checkboxes-tags-demo{
    border: none!important;
    outline: none!important;
}
#checkboxes-tags-demo:focus{
    border: none!important;
    outline: none!important;
}
input#checkboxes-tags-demo:focus{
    border: none!important;
    outline: none!important;
}
.Mui-focused, .mui-focused:focus{
    border: none!important;
    outline: none!important;
}
.m-rl-10{
    margin: 0px 10px;
}
.MuiAutocomplete-inputFocused:focus{
    border: none!important;
    outline: none!important;
}
.MuiChip-label {
    font-size: 11px;

}
.normal-text{
    font-size: 12px;
    margin-bottom: 15px;
}
.normal-text:first-child{
    margin-top: 15px;
}
.overview-heading-pipeline{
    font-size: 16px;
    font-weight: 500;
    color:#000;
    margin-bottom: 15px;
}
.section-overview-pipeline{
    margin: auto;
    margin-top: 2rem;
    padding: 0px 32px;
}
.schedule-daily{
    background-color: #fff;
    border-radius: 8px;
    padding: 1rem;
    width: 30rem;
    margin: 15px 0px;
}
.time-required{
    display: grid;
    align-items: center;
    grid-template-columns: 25% 2% 25% 35%;
    grid-gap: 10px;
    justify-content: center;
    gap: 10px;
}
/* .daily-or-weekly{

} */
.Schedule-weekly{
    padding: 1rem 1.3rem;
    background-color: #fff;
    border-radius: 8px;
    width: fit-content;
    margin: 15px 0px;
}
.heading-pipeline-label{
    color: #828282;
    font-size: 12px;
    font-weight: 400;
}

.Schedule-weekly .time-required{
    justify-content: left;
}
.input-label-pipeline{
    color: #828282;
    font-size: 12px;
    font-weight: 400;
}
.MuiChip-root {
    color: #fff;
    border: none;
    cursor: default;
    height: 32px;
    display: inline-flex;
    outline: 0;
    padding: 0;
    font-size: 0.8125rem;
    box-sizing: border-box;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    white-space: nowrap;
    border-radius: 8px;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: #586069;
}
.bg-required{
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #d5d5d5;
    padding: 1rem;
    margin-bottom: 1.5rem;
}
.MuiChip-root {
    color: #fff!important;
    border: none;
    cursor: default;
    height: 32px;
    display: inline-flex;
    outline: 0;
    padding: 0;
    font-size: 0.8125rem;
    box-sizing: border-box;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    white-space: nowrap;
    border-radius: 6px!important;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: #949494!important;
}
.MuiChip-deleteIcon {
    color: #949494!important;
    width: 22px;
    background-color: #fff;
    cursor: pointer;
    height: 22px;
    outline: none;
    background-size: 88% 88%;
    margin: 0 5px 0 -7px;
    /* -webkit-tap-highlight-color: transparent; */
    border-radius: 13px;
}
.sub-heading-pipeline-form{
    font-size: 12px;
}
.text-icon-section > .service-name{
    margin: 0px;
}
.service-name-list{
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 0.5%;
}
.service-name-list .MuiFormControlLabel-root{
    margin: 0px 5px;
}
.more-option > .flaticon-show-more-button-with-three-dots::before{
    font-size: 10px!important;
    color: #fff;
}
.pipeline-stage-level-card-border-left {
    width: 3rem;
    height: 3px;
    background-color: #898989;
    position: absolute;
    top: 0;
    top: 27px;
    left: -30px;
}
.circular-arrow{
    height: 30px;
    width: 30px;
    border: 2px solid #4652a2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
    margin-top: 1rem;margin-left: -2rem;
    margin-right: 3rem;
}
.circular-arrow .flaticon-right-arrow-forward::before{
    font-size: 12px!important;
    color: #4652a2;
}
.bg-white .MuiAutocomplete-root{
    background-color: #fff;
}
.MuiFormControl-fullWidth:hover{
    border: none!important;
    outline: none!important;
}
.promote .divider{
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.stage-view{
    display: grid;
    grid-template-columns: 10% 70% 10%;
    padding: 5px 10px;
    gap: 4%;
    align-items: center;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ebedee;
}
.stage-view .flaticon-expand-arrow{
    color:#000;
}
.menu-stage-view .MuiPopover-paper{
    height: fit-content;
}

.stage-view .flaticon-expand-arrow::before{
    font-size: 10px!important;
}
.stage-view .flaticon-show-more-button-with-three-dots::before{
    font-size: 11px!important;
    
}
.stage-view .flaticon-show-more-button-with-three-dots{
    justify-self: center;
    color: #000;
    cursor: pointer;
}
.job-view .flaticon-show-more-button-with-three-dots::before{
    font-size: 11px!important;
}
.job-view .flaticon-show-more-button-with-three-dots{
    justify-self: center;
    color: #000!important;
}
.stage-alignment{
    font-size: 11px;
    font-weight: 500;
    display: inline-block;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.build-name{
    font-size: 11px;
    display: inline-block;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.pipeline-stage-name-data{
    width: 100%;
    line-height: 2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.job-view{
    background-color: #f8f8f8;
    padding: 5px 10px;
    display: grid;
    gap: 4%;
    grid-template-columns: 10% 70% 10%;
    align-items: center;
    border-bottom: 1px solid #ebedee;
}
.empty-division{
    display: inline-block;
}
.env-chip{
    font-size: 11px;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: #666;
    color: #fff;
    line-height: 1;
    padding-top: 7px;
    margin: 0px 10px;
}
.bg-lite-blue{
    background-color: #52bfe7!important;
    color: #fff;
}
.bg-dark-blue{
    background-color: #52a3e7!important;
    color: #fff;
}
.workflow-heading{
    font-size: 11px;
    color: #000;
    display: grid;
    grid-template-columns: 10% 80% 10%;
    align-items: center;
    padding: 5px 10px;
    background-color: #f6f6f6;
    border-bottom: 1px solid #ebedee;
}
.workflow-heading .flaticon-show-more-button-with-three-dots{
    justify-self: center;
    color: #000;
    cursor: pointer;
}
.workflow-heading .flaticon-show-more-button-with-three-dots::before{
    font-size: 11px!important;
}
.workflow-heading .flaticon-expand-arrow:before{
    font-size: 11px!important;
    color: #000;
}
.mr-0{
    margin-right: 0px;
}
.m-auto{
    margin: auto;
}
.makeStyles-InputLabel-113{
    color: #828282;
    font-size: 12px;
    font-weight: 400;
}
.btn-lg{
    width: 120px!important;
}
.log{
    position: absolute;
    height: 150px;
    width: 84.3%;
    background-color: #000;
    bottom: 0;
    padding: 10px;
    color: #fff;
    font-size: 12px;
    overflow-y: scroll;
    margin: auto;
}
.MuiDialog-paperFullScreen{
    background-color: #f0f7f7!important;
}
.pipeline-tags-data .service-name-chip-div{
    min-height: fit-content;
    line-height: 0;
}
.pipeline-service-data .service-name-chip-div{
    min-height: fit-content;
    line-height: 0;
}
.service-name-chip-div{
    min-height: 42px;
}
.pipeline-tags-data .service-name-chip-div{
    min-height: 22px!important;
}
.service-name-chip-div div.chip-default.chip-blue{
    display: inline-block;
    box-shadow: none;
    overflow: inherit;
    padding: 0px;
    background-color: transparent;
    line-height: 0;
    margin: 0;
}
.owner-name span:nth-child(2){
    color:#868181
}


/* .service-name-chip-div div.chip-default.chip-blue:hover{
    
} */
.pipeline-tags-data{
    margin: 0px 10px;
    align-self: start;
}
.MuiPopover-paper.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded{
    min-height:fit-content!important ;
}
.top-left-zero{
    top: 0!important;
    left: 0!important;
}
.ripple {
    position: absolute;
    top: 17px;
    left: -40px;
    z-index: 9;
    margin: auto;
    transition: background 0.5s ease;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    -webkit-animation-name: ripple-out;
    animation-name: ripple-out;
  }
  .ripple:before {
    display: block;
    content: '';
    border: 8px solid #9e9d9d;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    opacity: 0;
    -webkit-animation-name: ripple-out;
    animation-name: ripple-out;
  }
  .ripple:hover {
    -webkit-animation-name: ripple-out;
    animation-name: ripple-out;
  }
  .approval{
      background-color: #c294f1;
      border-radius: 50%;
      height: 25px;
      width: 25px;
      display:flex;
      align-items:center;
      justify-content:center
  }
  .approval .flaticon-thumb-up-button{
      color: #fff!important;
      
  }
  .approval .flaticon-thumb-up-button::before{
    font-size: 14px!important;
    
}
  .failed{
    background-color: red;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    display:flex;
    align-items:center;
    justify-content:center
}
.pending{
    background-color: yellow;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    display:flex;
    align-items:center;
    justify-content:center
}
.logs-view{
    background-color: transparent;
    color: #0086ff;
    width: 100%;
    display: block;
    text-align: center;
    font-size: 12px;
    padding: 5px 10px;
    height: 30px;
}
.logs-view.current{
    background-color: #4c4c4c;
}
.MuiCircularProgress-circle {
    stroke: #fff;
}
.MuiCircularProgress-circle.yellow{
    stroke: #ffd62a;
}
.MuiCircularProgress-circle.success{
    stroke: #ffd62a;
}

.ripple:hover:before {
    -webkit-animation-name: ripple-out;
    animation-name: ripple-out;
  }
@keyframes ripple-out {
    0% {
      opacity: 1;
    }
    100% {
      top: -15px;
      right: -15px;
      bottom: -15px;
      left: -15px;
      opacity: 0;
    }
  }
  @keyframes ripple {
    from {
      transform: translate(-50%, -50%) translateZ(0px);
    }
    to {
      transform: translate(-50%, -50%) translateZ(100px);
    }
  }

  /* Animation css*/

  .wrapper {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 17px;
    left: 40px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-perspective: 300px;
            perspective: 300px;
    -webkit-perspective-origin: 50% 50%;
            perspective-origin: 50% 50%;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
  }
  .wrapper .circle {
    box-sizing: border-box;
    position: absolute;
    top: 55%;
    left: 4%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border: 2px solid #000;
    border-radius: 100%;
    -webkit-animation-name: ripple;
            animation-name: ripple;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
  }
  

  
  .circle {
    width: 50px;
    height: 50px;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  
  @-webkit-keyframes ripple {
    from {
      -webkit-transform: translate(-50%, -50%) translateZ(0px);
              transform: translate(-50%, -50%) translateZ(0px);
    }
    to {
      -webkit-transform: translate(-50%, -50%) translateZ(100px);
              transform: translate(-50%, -50%) translateZ(100px);
    }
  }
  
  @keyframes ripple {
    from {
      -webkit-transform: translate(-50%, -50%) translateZ(0px);
              transform: translate(-50%, -50%) translateZ(0px);
    }
    to {
      -webkit-transform: translate(-50%, -50%) translateZ(100px);
              transform: translate(-50%, -50%) translateZ(100px);
    }
    
  }

  @keyframes scale-display {
	0% {
		opacity: 0;
		transform: scale(0);
    -webkit-transform: scale(0);
	}

	100% {
		opacity: 1;
		transform: scale(1);
    -webkit-transform: scale(1);
	}
}
.button--disapear {
    display: none;
    transition: all .5s ease-in-out;
  }
  .button--appear {
    display: block;
    transition: all .5s ease-in-out;
  }
  .expandable {
    background: #fff;
    overflow: hidden;
    color: #000;   
    line-height: 50px;
  
    transition: all .5s ease-in-out;
    height: 0;
   }
   
   .expandable:target {
    height: 100%;
  }
.blank-div button{
    font-size: 12px;
    color: #0086ff;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    border: 2px solid #0086ff;
    height: 35px;
    width: 140px;
    background-color: #fff;
    margin: auto;
    border-radius: 4px;
}
  /*ends here*/
.text-black{
    color: #000;
    display: flex;
    align-items: center;
}
.text-black span[ class^="flaticon" ]{
    color: #5f6e9e;
    margin: 0px;
    padding-left: 10px!important;
    padding-right:5px ;
    display: inline-block;
}
.text-black span[ class^="flaticon" ]:first-child{
    padding-left: 0px!important;
}
.stage-view .flaticon-expand-arrow{
    cursor: pointer;
}
.stage-view .flaticon-downwards-arrow-key{
    color: #000;
}
.stage-view .flaticon-downwards-arrow-key::before{
    font-size: 10px!important;
}
.run-pipeline{
    color: #fff;
    font-size: 14px !important;
    padding: 10px;
    border-radius: 4px;
}
.sv-workflow{
    background-color: #FF8D00!important;
    color: #fff;
    font-size: 14px !important;
    padding: 10px;
    border-radius: 4px;
}
.sv-workflow:hover{
    background-color: #da7900!important;
}
.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .yaml-upload{
      display: inline;
  }
  .yaml-upload span[ class^="flaticon" ]{
      margin: 2px;
  }
  .yaml-upload > input{
      display: none;
  }
  
  .yaml-upload span{
      /* background-color: #efefef;
      padding: 0px 5px; */
      display: inline-block;
      cursor: pointer;
      border-radius: 4px;
  }
  /* .pipeline-list-scrollable{

  } */
  /* width */
  .pipeline-list-scrollable ::-webkit-scrollbar {
    width: 0px;
  }

  
  /* Handle on hover */
  .pipeline-list-scrollable ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  .approval-req-card{
    overflow: hidden;
    border-radius: 6px;
    background-color: #fff;
    
  }
  .approval-req-card .switch-input-wrapper .card-header{
    background-color: #fafafa;
  }
  .approval-req-card .inner-switch-input .card-header{
    background-color: #fafafa;
    
  }
  .label-with-image{
      display: flex;
      align-items: center;
  }
  .label-with-image img{
    width: 30px;
    margin-right: 5px;
  }
  .approval-question-card{
  box-shadow: rgb(0 0 0 / 6%) 0.877px 1.798px 21px 0px;
overflow: hidden;
border: 1px solid #dedede;
}
  .approval-question-card .switch-input-wrapper .card-header{
    border-bottom: 1px solid #dedede;
  }
  .approval-req-card .switch-input-wrapper .card-header .heading {
      color: #595656;
  }
  .inner-inner-card{
    border: 1px solid #dedede;
    box-shadow: rgb(0 0 0 / 6%) 0.877px 1.798px 21px 0px;
    overflow: hidden;
    border-radius: 4px;
  }
  .inner-inner-card .card-header {
      background-color: #fafafa;
      border-bottom: 1px solid #dedede;
  }
  .p-btn-icon{
      background-color: #ffffff;
      color: #0086ff;
      border: 1px solid #dedede!important;
      padding: 8px 10px;
  }
  .p-btn-icon .material-icons{
      font-size: 18px;
      vertical-align: bottom;
  }
  .p-btn-icon:hover{
      cursor: pointer;
      background-color: #0086ff;
      color: #fff;
      border: 1px solid #0086ff;
      transition: all .3;
  }

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .rotating {
    -webkit-animation: rotating 3s linear infinite;
    -moz-animation: rotating 3s linear infinite;
    -ms-animation: rotating 3s linear infinite;
    -o-animation: rotating 3s linear infinite;
    animation: rotating 3s linear infinite;
  }
  .flag-strip .icon-section .content-strip {
    width: 0px;
    overflow: hidden;
    margin-left: 10px;
    white-space: nowrap;
    transition: width 2s;
  }
  .flag-strip .icon-section:hover .content-strip {
    width: 350px!important;
    transition: width 2s;
  }

  .text-nowrap {
    text-wrap: nowrap;
  }

  .text-ellipsis {
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
  }