.md-stepper-horizontal-rmp {
    display: flex;
    width: 100% !important;
    margin: 0 auto;
    background-color: #f6f8f8;
    
    }
    
    .md-stepper-wrapper .md-stepper-horizontal-rmp {
    display: flex;
    width: 80% !important;
    margin: 0 auto;
    background-color: #f6f8f8;
    }
    
    .md-stepper-horizontal-rmp .md-step {
    width: inherit;
    position: relative;
    padding: 24px;
    }
    
    .md-stepper-horizontal-rmp .md-step:active {
    border-radius: 15% / 75%;
    }
    
    .md-stepper-horizontal-rmp .md-step:first-child:active {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    }
    
    .md-stepper-horizontal-rmp .md-step:last-child:active {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    }
    
    .md-stepper-horizontal-rmp .md-step:hover .md-step-circle {
    background-color: #f9f9f9;
    }
    
    .md-stepper-horizontal-rmp .md-step:first-child .md-step-bar-left,
    .md-stepper-horizontal-rmp .md-step:last-child .md-step-bar-right {
    display: none;
    }
    
    .md-stepper-horizontal-rmp .md-step:first-child .md-step-bar-logs-left,
    .md-stepper-horizontal-rmp .md-step:last-child .md-step-bar-logs-right {
    display: none;
    }
    
    .md-stepper-horizontal-rmp .md-step .md-step-circle {
    width: 28px;
    height: 28px;
    margin: 0 auto;
    border: 2px solid#ccc;
    border-radius: 50%;
    text-align: center;
    font-size: 9px;
    font-weight: 400;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F4F4F4;
    }
    
    .md-stepper-horizontal-rmp .md-step.active:hover {
    cursor: pointer;
    
    }
    
    .md-stepper-horizontal-rmp .md-step.active:hover .md-step-circle {
    cursor: pointer;
    
    }
    
    .md-stepper-horizontal-rmp .md-step.active:hover .md-step-circle .stepper-icon {
    cursor: pointer;
    display: block !important;
    }
    
    .md-stepper-horizontal-rmp .md-step.active:hover .success-circle {
    border: 3px solid #1A794C !important;
    }
    
    .md-stepper-horizontal-rmp .md-step.active:hover .md-step-title {
    color: #505050 !important;
    }
    
    
    #changes .md-stepper-horizontal-rmp .md-step.active .md-step-circle.disable {
    border: 3px solid #80808096;
    }
    
    .md-stepper-horizontal-rmp .md-step.active .md-step-circle.current {
    border: 3px solid #0096db;
    }
    
    .md-stepper-wrapper .md-stepper-horizontal-rmp .md-step.active .md-step-circle.current {
    border: 3px solid transparent;
    }
    
    
    .md-stepper-horizontal-rmp .md-step.active .md-step-circle.success {
    border: 2px solid #B5EED3;
    background: #E6FBEA;
    }
    
    .md-stepper-horizontal-rmp .md-step.active .md-step-circle.skipped {
    border: 3px solid #AEAEAE;
    }
    
    .md-stepper-horizontal-rmp .md-step.active .md-step-circle.fail {
    border: 3px solid #E53737;
    background: #FFEBEB;
    }
    
    .md-stepper-horizontal-rmp .md-step.active .md-step-circle.log-fail {
    border: 0;
    }
    
    .md-stepper-horizontal-rmp .md-step.active .md-step-circle.log-success {
    border: 0;
    }
    
    .md-stepper-horizontal-rmp .md-step.active .md-step-circle.log-running {
    border: 0;
    }
    
    
    .md-stepper-horizontal-rmp .md-step.active .md-step-circle.purple {
    border: 3px solid #c294f1;
    }
    
    .md-stepper-horizontal-rmp .md-step.active .md-step-circle.yellow {
    border: 3px solid #ffc436;
    }
    
    .md-stepper-horizontal-rmp .md-step.active .md-step-circle.default {
    border: 3px solid #0096db;
    }
    
    .md-stepper-horizontal-rmp .md-step.active .md-step-circle.running {
    background: #F5FAFF;
    border: 2px solid #0086FF;
    }
    
    .md-stepper-horizontal-rmp .md-step.active .md-step-circle.stopped {
    border: 3px solid #e9797e;
    }
    
    .md-stepper-horizontal-rmp .md-step.active .md-step-circle.running-only {
    border: 3px solid #0088ff57;
    border-top-color: #0086ff;
    animation: single-div-spin 1s infinite linear;
    }
    
    .md-stepper-wrapper .md-stepper-horizontal-rmp .md-step.active .md-step-circle {
    border: 3px solid transparent;
    }
    
    .md-stepper-horizontal-rmp .md-step.active .md-step-circle {
    border: 3px solid #e7e7e7;
    }
    
    .md-stepper-horizontal-rmp .md-step.active .md-step-circle.pending {
    border: 3px solid #ff8787;
    }
    
    .md-stepper-horizontal-rmp .md-step.active .md-step-circle.approval-pending {
    border: 2px solid #9747FF;
    background: #F4ECFF;
    }
    
    .md-stepper-horizontal-rmp .md-step.active .md-step-circle.revoke {
    border: 2px solid #E1941D;
    background: #FCF6E1;
    }
    
    
    
    .md-step-circle .flaticon-warning-sign {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff8787 !important;
    height: 100%;
    margin: 0;
    }
    
    .md-step-circle .flaticon-schedule-button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffc436 !important;
    height: 100%;
    margin: 0;
    }
    
    .md-step-circle .flaticon-thumb-up-button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #c294f1 !important;
    height: 100%;
    margin: 0;
    }
    
    .md-step-circle .flaticon-warning-sign::before {
    font-size: 14px !important;
    }
    
    .md-stepper-horizontal-rmp .md-step.done .md-step-circle:before {
    font-family: 'FontAwesome', sans-serif;
    font-weight: 100;
    content: "\f00c";
    }
    
    .md-stepper-horizontal-rmp .md-step.done .md-step-circle *,
    .md-stepper-horizontal-rmp .md-step.editable .md-step-circle * {
    display: none;
    }
    
    .md-stepper-horizontal-rmp .md-step.editable .md-step-circle {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    }
    
    .md-stepper-horizontal-rmp .md-step .md-step-title {
    margin-top: 5px;
    font-size: 11px;
    font-weight: 400;
    }
    
    .md-stepper-horizontal-rmp .md-step .md-step-title,
    .md-stepper-horizontal-rmp .md-step .md-step-optional {
    text-align: center;
    color: rgba(0, 0, 0, .26);
    }
    
    .md-stepper-horizontal-rmp .md-step.active .md-step-title {
    font-weight: 600;
    line-height: 1.2;
    color: #787878;
    }
    
    .md-stepper-horizontal-rmp .md-step.active.done .md-step-title,
    .md-stepper-horizontal-rmp .md-step.active.editable .md-step-title {
    font-weight: 400;
    }
    
    .md-stepper-horizontal-rmp .md-step .md-step-optional {
    font-size: 9px;
    }
    
    .md-stepper-horizontal-rmp .md-step.active .md-step-optional {
    color: rgba(0, 0, 0, .54);
    }
    
    .md-stepper-horizontal-rmp .md-step .md-step-bar-left,
    .md-stepper-horizontal-rmp .md-step .md-step-bar-right {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 3px solid #e7e7e7;
    }
    
    .md-stepper-wrapper .md-stepper-wrapper .md-stepper-horizontal-rmp .md-step .md-step-bar-left,
    .md-stepper-wrapper .md-stepper-wrapper .md-stepper-horizontal-rmp .md-step .md-step-bar-right {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 3px solid #cacaca;
    }
    
    .md-stepper-wrapper .md-stepper-horizontal-rmp .md-step .md-step-bar-left,
    .md-stepper-wrapper .md-stepper-horizontal-rmp .md-step .md-step-bar-right {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 3px solid #e7e7e7;
    }
    
    .md-stepper-horizontal-rmp .md-step .md-step-bar-logs-left,
    .md-stepper-horizontal-rmp .md-step .md-step-bar-logs-right {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 3px solid #e7e7e7;
    }
    
    .md-stepper-horizontal-rmp .md-step [ class^="md-step-bar-"].current {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 3px solid #0096db;
    }
    
    .md-stepper-wrapper .md-stepper-horizontal-rmp .md-step [ class^="md-step-bar-"].current {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 3px solid #0086ff;
    }
    
    .md-stepper-horizontal-rmp .md-step [ class^="md-step-bar-"].disable {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 3px solid #80808096;
    }
    
    .md-stepper-horizontal-rmp .md-step [ class^="md-step-bar-"].success {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 3px solid #62E187;
    }
    
    .md-stepper-wrapper .md-stepper-horizontal-rmp .md-step [ class^="md-step-bar-"].success {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 3px solid #30CE83 !important;
    }
    
    .md-stepper-wrapper .md-stepper-horizontal-rmp .md-step.active .md-step-circle.success {
    border: 3px solid transparent !important;
    }
    
    .md-stepper-horizontal-rmp .md-step [ class^="md-step-bar-"].skipped {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 3px solid #AEAEAE;
    }
    
    .md-stepper-horizontal-rmp .md-step [ class^="md-step-bar-"].fail {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 3px solid #ff8969;
    }
    
    .md-stepper-horizontal-rmp .md-step [ class^="md-step-bar-"].purple {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 3px solid #c294f1;
    }
    
    .md-stepper-horizontal-rmp .md-step [ class^="md-step-bar-"].yellow {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 3px solid #ffc436;
    }
    
    .md-stepper-wrapper .md-stepper-horizontal-rmp .md-step .md-step-bar-left,
    .md-stepper-wrapper .md-stepper-horizontal-rmp .md-step .md-step-bar-right {
    border-top: 3px solid #cacaca;
    }
    
    /* new logs design css */
    .md-stepper-horizontal-rmp .md-step [ class^="md-step-bar-logs"].current {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 3px solid #0096db;
    }
    
    .md-stepper-horizontal-rmp .md-step [ class^="md-step-bar-logs"].default {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 2px solid #407BCA
    }
    
    .md-stepper-horizontal-rmp .md-step [ class^="md-step-bar-logs"].disable {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 3px solid #80808096;
    }
    
    .md-stepper-horizontal-rmp .md-step [ class^="md-step-bar-logs"].success {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 2px solid #62E187;
    }
    
    .md-stepper-horizontal-rmp .md-step [ class^="md-step-bar-logs"].skipped {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 2px solid #AEAEAE;
    }
    
    .md-stepper-horizontal-rmp .md-step [ class^="md-step-bar-logs"].fail {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 2px solid #E1941D;
    }
    
    .md-stepper-horizontal-rmp .md-step [ class^="md-step-bar-logs"].purple {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 3px solid #c294f1;
    }
    
    .md-stepper-horizontal-rmp .md-step [ class^="md-step-bar-logs"].yellow {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 3px solid #ffc436;
    }
    
    
    
    
    
    .md-stepper-horizontal-rmp .md-step .md-step-bar-right {
    right: 0;
    left: 50%;
    margin-left: 15px;
    }
    
    .md-stepper-horizontal-rmp .md-step .md-step-bar-logs-right {
    right: 0;
    left: 50%;
    margin-left: 30px;
    }
    
    
    .md-stepper-horizontal-rmp .md-step .md-step-bar-left {
    left: 0;
    right: 50%;
    margin-right: 15px;
    }
    
    .md-stepper-horizontal-rmp .md-step .md-step-bar-logs-left {
    left: 0;
    right: 50%;
    margin-right: 30px;
    }
    
    .md-stepper-wrapper .md-stepper-horizontal-rmp .md-step .md-step-bar-right {
    right: 0;
    left: 55%;
    margin-left: 15px;
    }
    
    .md-stepper-wrapper .md-stepper-horizontal-rmp .md-step .md-step-bar-left {
    left: 0;
    right: 55%;
    margin-right: 15px;
    }
    
    
    
    .md-step-log {
    padding: 24px 24px 0px !important;
    }
    
    /* ------------ */
    .md-step-log-new {
    padding: 16px 24px 0px !important;
    }
    
    .md-stepper-horizontal-rmp-new {
    width: 100%;
    align-items: start;
    display: flex;
    justify-content: center;
    }
    
    .md-stepper-horizontal-rmp-new::-webkit-scrollbar {
    display: none;
    }
    
    
    .bg-white .md-stepper-horizontal-rmp {
    background-color: #fff;
    }
    .new-overview-card .md-stepper-horizontal-rmp {
    background-color: #fff;
    }
    .horizontal-tab-scroll .md-stepper-horizontal-rmp {
    max-width: 960px;
    overflow-x: scroll;
    }
    
    @media screen and (max-width:1200px) {
    .horizontal-tab-scroll .md-stepper-horizontal-rmp {
    width: 90rem;
    overflow-x: scroll;
    margin: auto;
    }
    }
    
    
    .stepper-div .md-stepper-horizontal-rmp {
    overflow-x: scroll;
    }
    
    .steplogswrapper .loading-div .md-stepper-horizontal-rmp {
    max-width: 1250px;
    overflow-x: scroll;
    }
    
    
    .stepper-div .md-stepper-horizontal-rmp {
    overflow-x: scroll;
    }
    
    @media screen and (min-width: 1440px) and (max-width: 1920px) {
    
    /* Adjust styles for Mac Pro and similar devices */
    body {
    font-size: 16px;
    }
    
    .md-stepper-horizontal-rmp .md-step.active .md-step-title {
    font-size: 13px;
    }
    
    .heading-primary {
    font-size: 20px;
    }
    
    .sub-heading-primary {
    font-size: 14px;
    }
    
    .input-label {
    font-size: 14px;
    font-weight: 500;
    color: #2f2f2f;
    }
    
    .side-nav-dialog .MuiDialog-scrollPaper {
    margin-right: 0px;
    justify-content: flex-start;
    margin-left: 291px;
    }
    
    
    }
    
    @media screen and (min-width: 1921px) {
    
    /* Adjust styles for large screens */
    body {
    font-size: 18px;
    }
    
    .md-stepper-horizontal-rmp .md-step.active .md-step-title {
    font-size: 16px;
    }
    }
    
    
    .md-stepper-horizontal-rmp .tab-container {
    display: flex;
    width: 100%;
    height: 75px;
    /* padding: 4px, 0px, 0px, 0px; */
    background: #FFFFFF;
    box-shadow: 0px 4px 24px 0px #0000001F;
    }