.heading-section-env{
    display: flex;
    align-self: center;

}
.main-heading-env {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.2;
}
.subheading-dark{
    color:#949595!important
}
.subheading-lite{
    color:#b3b7b7!important
}
.sub-heading-text {
    font-size: 13px;
    color: #666;
}
.avatar {
    border-radius: 8px;
    padding: 10px;
    height: 50px;
    width: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5784c8;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
}
.detailedcard{
    border-radius: 8px;
    box-shadow:rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px;
    background: #f9f9f9;
    border: 1px solid #dedede
}
.detailedcard-header{
    background-color: #fff; 
    border-radius: 8px 8px 0px 0px;
    display: flex;
    padding:20px 20px 0px 20px;
    justify-content: space-between;
}
.headerul li:hover{
  cursor: pointer;
  pointer-events: all;
}
.detailedcard ul.headerul{
    list-style: none;
    display: flex;
    align-items: center;
}
.detailedcard ul.headerul li{
    padding: 15px 20px;
    font-size: 12px;
    color: #666;
    line-height: 1;
    text-transform: uppercase;
}
.detailedcard ul.headerul li.active{
    color: #0096db;
    border-bottom: 3px solid #0096db;
}
.divider{
    height: 1px;
    background-color: #dedede;
    width: 100%;
}
.detailedcard__body{
    padding: 0px 20px 1px;
}
.main-text__body{
    display: flex;
    align-self: center;
    justify-content: space-between;
}

span[ class^="flaticon" ]{
    color: #124d9b;
}
.main-text__body span.flaticon-clock-with-white-face::before{
    color: #124d9b;
    font-size: 24px!important;
}
.footer__header .left-content{
    display: flex;
    align-items: baseline;
}
.line-h-0{
  line-height: 0;
}
.line-h-15{
  line-height: 1.5!important;
}
.main-text__body .left-content{
  display: flex;
  align-items: center;
}
.main-text__body {
  padding: 15px 0px
}
.main_text{
    line-height: 1;
    font-size: 13px;
    margin-left: 5px;
}
.sub_text{
    line-height: 1;
    font-size: 10px;
    margin-left: 5px;
    color: #666;
}
.sub_text_dark_grey{
    font-size: 11px;
    color: #949595;
    line-height: 1;
}
.sub_text_lite_grey{
    font-size: 11px;
    color:#b3b7b7;
    line-height: 1;
}
.right-content{
    display: flex;
    align-items: center;
}
.border-navy{
    border: 2px solid #124d9b;
}
.btn-round{
    background-color: transparent;
}
.text-content{
    margin-right: 10px;
    line-height: 1;
}
.text-content div{
    line-height: 1;
}
button.btn-round .flaticon-refresh-button-1::before, button.btn-round .flaticon-replay-arrow::before {
    color: #0096db!important;
}
.btn-round:hover .MuiSvgIcon-root{
  color: #fff!important;
}
.datacard_section{
    display: grid;
    grid-template-columns: 23% 23% 23% 23%;
    gap: 20px;
    margin: auto;
    padding: 20px 0px;
    justify-content: space-between;
}
.datacard_section .data-card{
    display: grid;
    justify-content: center;
    grid-template-columns: auto auto;
}
.bold-text__main{
    color: #939fce;
}
.card-small__text{
    font-size: 13px;
    color: #000;
    line-height: 1;
    margin-left: 10px;
}
.datacard_section .red-text{
    color: #ff6f6f;
}
.datacard_section .data-card:first-child .data-card-icon{
    background-color: #0096db;
}
.datacard_section .data-card:nth-child(2) .data-card-icon{
    background-color: #69e09c;
}
.datacard_section .data-card:nth-child(3) .data-card-icon{
    background-color: #ff6f6f;
}
.datacard_section .data-card:nth-child(4) .data-card-icon{
    background-color: #208ea2;
}
.data-card .data-card-icon > span[ class^="flaticon" ]::before{
    font-size: 24px!important;
}
.charting-section{
    display: grid;
    grid-template-columns: 30% 68%;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 20px;
}
.doughnut-chart{
    border-radius: 8px;
    border: 1px solid #dedede;
    width: 100%;
    background-color: #fff;
}
.doughnut-chart-header{
    border-radius: 8px 8px 0px 0px;
    padding: 15px 20px;
    background-color: #fbfbfb;
    color: #878787;
    font-size: 12px;
    border-bottom: 1px solid #dedede;
}
.data-chip{
    margin: .5rem;
    display: flex;
    position: relative;
    align-items: baseline;
}
.data-chip p{
    font-size: 11px;
    color: #000;
}
.bullet-error{
	border-radius: 50%;
	height: 10px;
	width: 10px;
	display: inline-block;
	margin: 0rem .25rem;
	box-sizing: content-box;
	
}
.green{
	background-color: #69e09c;
}
.red{
	background-color: #ff6238;
}
.chartContainer{
    position: relative;
    height: 250px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.stats {
    margin: 10px 0px;
    width: 100%;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
  }
  .total-count{
    left: 14rem;
    position: absolute;
    top: 11rem;
    text-align: center;
  }
  .count-text{
      font-size: 12px;
      line-height: 1;
      color: #555555;
  }
  .count{
      font-size: 32px;
      line-height: 1;
      font-weight: 400;
      color: #555555;
  }
  
.bar-graph{
    border-radius: 8px;
    border: 1px solid #dedede;
    width: 100%;
    height: 100%;
    background-color: #fff;
}
.bar-graph-header{
    border-bottom: 1px solid #dedede;
    background-color: #fbfbfb;
    border-radius: 8px 8px 0px 0px;
    padding: 0px 20px;
}
.bar-graph-header ul{
    list-style: none;
}
.bar-graph-header ul li{
    font-size: 12px;
    color: #878787;
    padding: 15px 10px;
}
.bar-graph-header ul li.active{
    color: #0096db;
    border-bottom: 3px solid #0096db;
}
.container-bar-graph{
    position: relative;
    height: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}
@media screen and(min-width: 1280px ){
    .total-count{
        left: 11rem!important;
      }
}
.detailedcard__footer{
    padding: 20px 0px;
}
.footer__header{
    margin: 10px 0px;
}
.footer__body{
    display: grid;
    grid-template-columns: 44% 25% auto;
    column-gap: 20px;
}
.infra-usage{
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #dedede;
}
.infra-usage__header{
    background-color: #fbfbfb;
    border-radius: 8px 8px 0px 0px;
    padding: 15px 20px;
    font-size: 12px;
    color: #878787;
    border-bottom: 1px solid #dedede;
}
.infra-usage .progress-container{
    padding: 20px 10px;
    justify-content: center;
}
.monitoring-tools{
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #dedede;
    height: 100%;
}
.monitoring-tools__header{
    background-color: #fbfbfb;
    border-radius: 8px 8px 0px 0px;
    padding: 15px 20px;
    font-size: 12px;
    color: #878787;
    border-bottom: 1px solid #dedede;
}
.monitoring-tools .monitor-urls{
    display: flex;
    align-items: center;
    justify-content: center;
}
.monitoring-tools .progress-bar {
    width: 70%;
    margin-left: 25px;
}
/*
horizonatal bar graph
*/
.skills {
    width: 80%;
    max-width: 960px;
    margin-left: 20px;
    position: relative;
  }
  
  .lines {
    height: 60%;
    position: relative;
  }
  
  .line {
    height: inherit;
    width: 2px;
    position: absolute;
    background: rgba(238, 238, 238, 0.6);
  }
  .line.l--0 {
    left: 0;
  }
  .line.l--25 {
    left: 25%;
  }
  .line.l--50 {
    left: 50%;
  }
  .line.l--75 {
    left: 75%;
  }
  .line.l--100 {
    left: calc(100% - 1px);
  }
  
  .line__label {
    display: block;
    width: 100px;
    text-align: center;
    position: absolute;
    bottom: -20px;
    right: -50px;
  }
  .line__label.title {
    text-transform: uppercase;
    font-weight: bold;
  }
  
  .charts {
    width: 100%;
    height: 100%;
    
  }
  
  .chart {
    margin: 30px 0 0;
  }
  .chart:first-child {
    margin: 0;
  }
  
  .chart__title {
    display: block;
    margin: 0 0 10px;
    font-weight: bold;
    opacity: 0;
    animation: 1s anim-lightspeed-in ease forwards;
  }
  .chart--prod .chart__title {
    animation-delay: 3.3s;
  }
  .chart--design .chart__title {
    animation-delay: 4.5s;
  }
  
  .chart--horiz {
    overflow: hidden;
  }
 .count-left{
     position: absolute;
 }
  .chart__bar {
    height: 30px;
    margin-bottom: 10px;
    background: linear-gradient(to left, #4cb8c4, #3cd3ad);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    position: relative;
    opacity: 0;
    animation: 1s anim-lightspeed-in ease forwards;
  }
  .chart--dev .chart__bar:nth-of-type(11) {
    animation-delay: 2.7s;
  }
  .chart--dev .chart__bar:nth-of-type(10) {
    animation-delay: 2.5s;
  }
  .chart--dev .chart__bar:nth-of-type(9) {
    animation-delay: 2.3s;
  }
  .chart--dev .chart__bar:nth-of-type(8) {
    animation-delay: 2.1s;
  }
  .chart--dev .chart__bar:nth-of-type(7) {
    animation-delay: 1.9s;
  }
  .chart--dev .chart__bar:nth-of-type(6) {
    animation-delay: 1.7s;
  }
  .chart--dev .chart__bar:nth-of-type(5) {
    animation-delay: 1.5s;
  }
  .chart--dev .chart__bar:nth-of-type(4) {
    animation-delay: 1.3s;
  }
  .chart--dev .chart__bar:nth-of-type(3) {
    animation-delay: 1.1s;
  }
  .chart--dev .chart__bar:nth-of-type(2) {
    animation-delay: 0.9s;
  }
  .chart--dev .chart__bar:nth-of-type(1) {
    animation-delay: 0.7s;
  }
  .chart--prod .chart__bar:nth-of-type(2) {
    animation-delay: 4.2s;
  }
  .chart--prod .chart__bar:nth-of-type(1) {
    animation-delay: 4s;
  }
  .chart--design .chart__bar:nth-of-type(3) {
    animation-delay: 5.6s;
  }
  .chart--design .chart__bar:nth-of-type(2) {
    animation-delay: 5.4s;
  }
  .chart--design .chart__bar:nth-of-type(1) {
    animation-delay: 5.2s;
  }
  
  .chart__label {
    padding-left: 10px;
    line-height: 30px;
    color: white;
  }
  
  @keyframes anim-lightspeed-in {
    0% {
      transform: translateX(-200%);
      opacity: 1;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  /*
  Service Overview card
  */
  .overviewCard{
        background-color: #fff;
        border-radius: 8px;
        box-shadow:rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px;
        margin: 20px auto;
        border: 1px solid #dedede;
        overflow: hidden;
  }
  .overviewCard-blank {
    border-radius: 8px;
    margin: 20px auto;
}
.overviewCard__body-blank{
  padding:20px;
  border-radius: 0px 0px 8px 8px;
}
  .overviewCard__header{
    border-radius: 8px 8px 0px 0px;
    padding: 15px 20px;
    background-color: #fbfbfb;
    color: #878787;
    font-size: 12px;
    border-bottom: 1px solid #dedede;
  }
  .overviewCard__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .overviewCard__body{
    padding:20px;
    background-color: #fff;
    border-radius: 0px 0px 8px 8px;
  }
  .overviewCard__body > div > .md-stepper-horizontal{
        background-color: #fff;
  }
  .text-overview{
    text-transform: uppercase;
    font-size: 12px;
  }
  button.edit__icon{
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
  .text__box{
    display: flex;
    padding: 2px 0px;
    align-items: center;
  }
  .text__box-main{
    font-size: 12px;
    color: #000;
  }
  .m-width-90{
    
    min-width: 90px;
  }
  /* .font-11{
    font-size: 11px!important;
  } */
  .text__box-sub{
    font-size: 12px;
    color: #666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .service__details{
    display: flex;
  }
  .text__box-sub .chip-default{
    font-size: 11px;
    justify-content: center;
    align-items: center;
    display: inline-block;
    line-height: 2;
    border-radius: 4px;
    padding: 0px 8px;
    margin: 5px;
    text-align: center;
  }
  .align-left{
      margin-right: 10px;
  }
  .align-right{
    margin-left: 10px;
}
.ci__details{
  display: flex;
}
.ci--left__panel{
  width: 25%;
  height: 100%;
  padding: 10px 20px;
}
.top__details{
  display: flex;
  justify-content: space-between;
}
.ci--right__panel{
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  padding-top: 10px;
  border-left: 1px solid #dedede;
}
.overviewCard__body__ci{
    background-color: #fff;
    border-radius: 0px 0px 8px 8px;
}
.general-information__ci{
  
}
.ci__chip__section {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  flex-wrap: wrap;
}
.ci__chip__section .chip-default-fixed:first-child{
    margin-left: 0px;
}
.icon-ci{
  width: 40px;
  margin-left: auto;
  line-height: none;
}
.icon-ci img{
  width: 100%
}
.ci__names{
  margin: 20px 0px;
}
.code-quality{
}
.text-uppercase{
  text-transform: uppercase!important;
}
.lite-grey{
  color: #9e9e9e;
}
.dark-grey{
  color: #646464;
}
.mid-grey{
  color: #878787;
}
.pd-rl-20{
  padding: 15px 20px;
}
.text__box__grid{
  display: grid;
  grid-template-columns: auto auto;
  gap:10px
}
.text__box__grid .text__box-main{
  justify-self: flex-start;
}
.code-quality .ci__names{
  margin: 10px 0px
}
.code-quality .ci__chip__section{
    justify-content: start;
}
.code-quality .ci__chip__section .chip-default-fixed{
  margin-left: 10px;
}
.code-quality .ci__chip__section .chip-default-fixed:first-child{
  margin-left: 0px;
}
.pd-b-10{
  padding-bottom: 10px;
}
.cd_deatils_card__header{
    border-radius: 8px 8px 0px 0px;
    padding: 0px 20px;
    background-color: #fbfbfb;
    color: #878787;
    font-size: 12px;
    border-bottom: 1px solid #dedede;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mr-30{
  margin-right: 20px;
}
.content-left{
  justify-self: flex-end;
  display: flex;
  align-items: center;
}
.btn_icon{
  background-color: transparent;
  display: inline-block;
  border: none;
  margin-left:5px;
  margin-right: 5px;
  color: #0096db;
  font-size: 11px;
}
.btn_icon:last-child{
  margin-right: 0px;
  margin-left: 10px;
}
button.btn_icon span[ class^="flaticon" ] {
  color: #0096db;
  margin: 0px 3px;
}
button.btn_icon span[ class^="flaticon" ]::before{
  font-size: 11px!important;
}
.vertical-border{
  width: 1px;
  display: block;
  background-color: #dedede;
  margin: -2rem 3px;
}
.dropdown-data > div{
  margin-bottom: 0px!important;
  width: 180px;
  justify-items: flex-end;
  box-shadow: none!important;
}
.dropdown-data > div select.select{
  box-shadow: none!important;
    width: 180px!important;
    height: 30px;
    margin: 0px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #ccc!important;
    padding: 0px 8px;
}
.dropdown-data select.select:focus{
  outline: 1px solid #124d9b;
}
.overviewCard-small{
  border-radius: 8px;
  border: 1px solid #dedede;
  background-color: #fff;
  height: fit-content;
}
.overviewCard-small__header{
  border-radius: 8px 8px 0px 0px;
  padding: 15px 10px;
  background-color: #fbfbfb;
  color: #878787;
  font-size: 12px;
  border-bottom: 1px solid #dedede;
  text-align: left;
}
.overviewCard-small__container{
  position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
}
.overviewCard-small__container .text__box__grid {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 10px;
}
.flex__item{
  margin-right: 8rem;
}
.flex__item:last-child{
  margin-right: 0px;
}
.chart__bar:last-child {
  margin-bottom: 0px;
}
.grid-template-view{
  display: flex;
  background-color: #fff;
  border-radius: 0px 0px 8px 8px;
  flex-wrap: wrap;
}
.d-flex{
  display: flex !important;
}
.no-data__msg{
  font-size: 11px;
  color: rgb(255, 137, 105);
  line-height: 2.5;
}
.details-label>.label-env select.select {
  border: none!important;
  box-shadow: none!important;
  width: 60px!important;
  height: 30px;
  padding: 0px 8px;
}
.select {
  /* background: #fff url(/images/down-arrow.png) no-repeat right center; */
  cursor: pointer;
  background-size: 10px 10px;
  background-origin: content-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.overviewCard__header-cd {
  border-radius: 8px 8px 0px 0px;
  padding: 0px 20px;
  background-color: #fbfbfb;
  color: #878787;
  font-size: 12px;
  border-bottom: 1px solid #dedede;
}
.overview-heading{
  font-size: 11px;
  color: #878787;
  text-transform: uppercase;
  line-height: 1;
}
.overview-single-card{
  border: 1px solid #CED1DB;
  border-radius: 4px;
  width: 100%;
  margin: 10px 0px;
  position: relative;
  box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.04);
  overflow: hidden;
}

.overview-card-section{
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  gap: 10px;
  /* margin: 10px 0px; */
  justify-content: space-between;
}
.overview-card-updated{
  display: grid;
  grid-template-columns: 70% 1fr;
  gap: 10px;
}
.grid-template-3{
  grid-template-columns: 32% 32% 32%;

}
.section-service-overview{
    border-top: 1px solid #CED1DB;
    padding: 10px 0px;
}
.overview-card-heading-section{
  position: relative;
  padding:10px;
  height: 40px;
  background-color: #fbfbfb;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #CED1DB;
}
.overview-card-heading{
  font-size: 11px;
  color: #646464;
  line-height: 1;
}
.overview-card-value{
  padding:10px;
  height: auto;
  display: grid;
}
.overview-card-value-env-var{
  padding:10px;
  height: 150px;
  display: block;
}
.overview-card-section-grid-3{
  display: grid;
  grid-template-columns: 24% 50% 24%;
  gap: 10px;
}
.overview-card-section-grid-5 {
  display: grid;
  grid-template-columns: 19% 19% 19% 19% 19%;
  grid-gap: 10px;
  gap: 10px;
  margin: 10px 0px;
  justify-content: space-between;
}
.overview-card-section-grid-3 .overview-single-card{
  width: 100%;
}
.overview-card-value-text{
  font-size: 11px;
  color: #666;
  line-height: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto 0px auto;
}
.overview-chip-yes{
  background-color: #62e19e;
  font-size: 10px;
  line-height: 1;
  padding: .5rem;
  color: #fff; 
  border-radius: 4px;
}
.overview-chip-no{
  background-color: #f3a1a1;
  font-size: 10px;
  line-height: 1;
  padding: .5rem;
  color: #fff; 
  border-radius: 4px;
}
.chip-overview{
  padding: .5rem;
  color: #fff;
  border-radius: 4px;
  display: inline-block;
  max-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 11px;
  text-overflow: ellipsis;
  line-height: 1;
}
.chip-overview-min {
  padding: .5rem;
  color: #fff;
  border-radius: 4px;
  display: inline-block;
  min-width: 50px;
  margin: 2px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 11px;
  text-overflow: ellipsis;
  line-height: 1;
}
.color-lite-grey{
  background-color: #babec2;
}
.color-dark-grey{
  background-color: #848484;
}
.overview-card-value-text-complete{
  font-size: 11px;
    color: #666;
    line-height: 1.5;
    text-transform: capitalize;
    margin: auto 0px auto;
}
.grid-temp-2{
  grid-template-columns: auto auto;
}
.rep-count-text{
  font-size: 11px;
  color: #949595;
}
.overview-card-value-text span.MuiSlider-root{
  margin-left: 2rem;
}
.grid-view{
  display: grid;
  grid-template-columns: auto auto;
}
.grid-view-4{
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.grid-view p{
  font-size: 11px;
  line-height: 1.2;
}
.grid-temp-2-widthfull{
  grid-template-columns: 25% 74%;
}
.grid-temp-2-widthfull > .overview-single-card {
      width: 100%;
}
.grid-view-4  p{
  line-height: 1.5;
}
.heading-key-value{
  display: grid;
  align-self: baseline;
  grid-template-columns: 45% 45%;
  justify-items: flex-start;
  padding-bottom: 5px;
  border-bottom: 2px solid #000;
  gap:0px 10px;
}
.heading-key-value p {
  font-size: 11px;
  line-height: 1.5;
}
.data-key-value-pair{
  display: grid;
  align-items: center;
  grid-template-columns: 45% 45%;
  justify-items: flex-start;
  border-bottom: 1px solid #CED1DB;
  height: 30px;
  gap:0px 10px;
}
.data-key-value-pair:last-child{
  border: none;
}
.data-key-value-pair p{
  font-size: 11px;
  color: #666;
  padding: 5px;
}
.overview-card-heading-section .flaticon-black-check-box-with-white-check{
  color: #62E187;
}
.overview-card-heading-section .flaticon-black-check-box-with-white-check::before{
  font-size: 18px!important;
} 
.text-overview-with-image{
  display: flex;
  justify-content: space-between;
}
.text-overview-with-image img{
  width: auto;
  height: 25px;
}
.text-with-icon{
  display: flex;
  align-items: center;
}
.text-with-icon p{
  font-size: 11px;
  line-height: 1;
  margin-left: 5px;
  color:#646464
}
.text-with-icon p span{
  margin-left:5px;
  color: #a5a5a5;
}
.text-with-icon >span.flaticon-users-social-symbol {
  color: #5d6978!important;
}
.no-data__msg-env-var {
  font-size: 11px;
  color: rgb(255, 137, 105);
  /* justify-self: center; */
  margin: auto;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

}
.text-ellipses{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}
.details-label>.label-env select.select {
  /* border: none!important; */
  box-shadow: none!important;
  width: 130px!important; 
  height: 30px;
  margin: 0px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #ccc!important;
  padding: 0px 5px;
}
.env-variable-box{
  display: block;
  overflow-y: scroll;
  height: 90%;
}
.env-variable-box::-webkit-scrollbar{
  display: none;
}
p.no-data__msg{
line-height: 1;
}
.grid-view-2{
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
}
.grid-temp-3-widthfull{
  grid-template-columns: 49% 50%;
}
.overview-card-value-access {
  padding: 10px;
  /* height: fit-content; */
  display: grid;
  /* grid-template-columns: auto auto; */
}
.label-dropdown{
  font-size: 11px!important;
}
.md-step-circle > .MuiSvgIcon-root{
  font-size: 1.8rem;
}
.height-12{
  height: 12rem;
}
.Y7{
  -webkit-font-smoothing: antialiased;
    font-size: 10px;
    letter-spacing: .3px;
    background-color: #737373;
    border-radius: 4px;
    color: #fff;
    display: inline;
    padding: 3px 5px;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}
.env-subenv{
  font-size: 11px;
    width: fit-content;
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
}
.env-subenv .input-component{
  margin-bottom: 0px;
  width: 120px;
}
.env-subenv .input-component .select {
  width: 100%;
  height: 32px;
  display: block;
  margin: 0px;
  line-height: 2;
  border-radius: 4px;
  border: 1px solid #b7b7b7;
  background-color: #fff;
  padding: 0px 4px;
  color: #5e5e5e;
  font-size: 12px;
  font-weight: 400;
}
.env-subenv .color-dark-grey{
  color:#666;
  background-color: transparent;
}
.display-flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.monitoring-card{
  padding: 0px 0px 0px;
  border-radius: 8px;
  border: 1px solid #eed;
  background-color: #fff;
  margin: 20px 0px;
}
.grid-3-template{
  display: grid;
  grid-template-columns: 33% 33% 33%;
  align-items: center;
  gap: 5px;
  justify-content: center;
}
.recent-deployment-cart-section{
  display: flex;
  align-items: center;
  justify-content: center;
}
.recent-deployment-cart-section canvas{
  width: 478px!important;
  height: 239px!important;
  position: relative;
}
.content-center-chart{
  padding-top: 20px!important;
}
@media screen and(min-width: 1280px ){
  .total-count{
      left: 11rem!important;
    }
    .recent-deployment-cart-section canvas{
  width: 478px!important;
  height: 239px!important;
  position: relative;
}
}
.processing-arrow{
  border: 3px solid #e3e6e8;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.processing-arrow .flaticon-right-arrow-forward::before{
  font-size: 20px!important;
  color: #666;
  margin: auto;
  text-align:center;
}
.content-2{
  position: absolute;
  bottom: 5rem;
  width: 100%;
  text-align: center;
}
.content-1{
  position: absolute;
  bottom: 9.5rem;
  left: 7.5rem;
  width: 160px;
  text-align: center;
}
.content-1 .replicaset-count{
  background-color: #99aebd;
    color: #fff;
    height: 60px;
    width: 60px;
    line-height: 60px;
    border-radius: 50%;
    text-align: center;
    margin: auto;
    display: block;
} 
.content-1 div div{
  margin-bottom: 0px!important;
}
.content-1 div div:first-child{
  display: none;
}
@media screen and ( max-width:1280px){
  .content-center-chart {
    width: 100%;
    display: grid;
    padding: 0px 20px;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 22% 54% 25%!important;
}
 
 
}
.left-pods-count{
  background-color: #99aebd;
  color: #fff;
  font-size: 36px;
  height: 60px;
  width: 60px;
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
  margin: auto;
  margin-bottom: 10px;
}
.pods-count{
  font-size: 12px;
  color: #000;
  margin-bottom: 15px;
}
.pods-count span{
  color: #666;
}
.hint-click{
  font-size: 11px;
  color: #878787;
  text-align: center;
}
.bottom-data .heading-main{
  color: #526174;
  font-size: 16px;
  margin-top: 15px;
}
.artifact-name-blue{
  border-radius: 4px;
  background-color: #54b4f4;
  color:#fff;
}
.artifact-name p{
  font-size: 11px;
}
.bottom-section{
  display: flex;
  align-content: center;
  justify-content: center;
}
.artifact-name{
  margin: 5px;
}
.bottom-section-data{
  margin: 5px;
  align-self: center;
}
.bottom-section-data div{
  line-height: 1;
}
.orange{
  background-color:#fa9930
}
.table-section table{
    text-align: left;
    width: 100%;
}
.table-section table tr th{
  padding: 5px 0px;
  border-bottom: 2px solid #4f4f4f;
}
.table-section table tr td{
  padding: 10px 0px;
  border-bottom: 1px solid #c5c5c5;
  color: #808080;
}
.red-text{
    color: #ff0500;
}
.chart-with-content{
  max-width: 500px;
  position: relative;
}
.table-section .table-head, .table-section .table-body{
  display: grid;
  border-bottom: 1px solid #c5c5c5;
  grid-template-columns: 45% 13% 10% 15% 15%;
  align-items: center;
  padding: 10px 0px;
  gap: 10px;
  justify-content: start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.scrollable-section{
  height: 250px;
  overflow-y: scroll;
}
.max-height-200{
  max-height: 200px;
  overflow-y: scroll;
}
.table-section-8 .table-head, .table-section-8 .table-body{
  display: grid;
  border-bottom: 1px solid #c5c5c5;
  grid-template-columns:8% 8% 8% 8% 20% 8% 15% 15%;
  align-items: center;
  padding: 10px 0px;
  gap: 10px;
  justify-content: start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.table-section-8 .table-body div, .table-section-8 .table-head div{
  margin: 0px 5px;
  font-size: 11px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.table-message{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.table-section .table-body:last-child{
  border-bottom: none;
}
[contenteditable] {
  outline: 0px solid transparent;
}
.table-body-error{
  display: flex;
  width: 100%;
  height: 250px;
  justify-content: center;
  align-items: center;
}
.table-body-error p{
  color:rgb(255, 137, 105)
}
