@import url('//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css');

.isa_info, .isa_success, .isa_warning, .isa_error {
margin: 0px 0px;
padding:12px;
font-size: 12px;
}
.isa_info {
    color: #00529B;
    /* background-color: #BDE5F8; */
}
.isa_success {
    color: #4F8A10;
    background-color: #DFF2BF;
}
.isa_warning {
    color: #9F6000;
    background-color: #FEEFB3;
}
.isa_error {
    color: #D8000C;
}
.border-blue{
    
    border: 2px solid #8ea9c1;
}
.line-height-one{
    line-height: 1;
}
.border-red{
    border: 2px solid #e9797e!important;
}
.isa_info i, .isa_success i, .isa_warning i, .isa_error i {
    margin:10px 22px;
    font-size:2em;
    vertical-align:middle;
}

.custom-box{
    width: 380px;
    height: -moz-fit-content;
    height: fit-content;
    align-self: flex-start;
    border-radius: 4px;
    display: flex;
    align-items: center;
}
.custom-box-new{
    width: 100% !important;
    height: -moz-fit-content;
    height: fit-content;
    align-self: flex-start;
    border-radius: 4px;
    display: flex;
    align-items: center;
}
.d-block{
    display: block;
}
.rounded-box{
    border: 1px solid;
    border-radius: 25px;
}
.des-custom-box{
    margin: auto;
    padding:0px 22px;
    
}
.des-custom-box ul{
    list-style: none;
}
.des-custom-box ul li{
    overflow-wrap: break-word;
}
.btn-with-icon{
    background-color: #fff;
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: #0096db;
    border: none;
    cursor: pointer;
}
.btn-with-icon:hover{
    background-color: #ffffff9e;
} 
.btn-with-icon:hover .MuiSvgIcon-root{
    color: #0297dc!important;
}
.btn-not-allowed.btn-with-icon:hover .MuiSvgIcon-root{
    color: #838383!important;
}
.btn-round{
    box-shadow: 0.877px 1.798px 8px 0px rgba(0, 0, 0, 0.06);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right:10px ; 
}

.btn-without-border{
    font-size: 20px;
    color: #505050;
    border: 1px solid transparent;
    max-width: 36px;
    max-height: 36px;
    border-radius: 6px;
    background-color: transparent;
    padding: 8px;
}

.btn-without-border:hover {
    border-color: #E6E6E6;
}

.btn-round > .flaticon-refresh-button-1 , .btn-round > .flaticon-replay-arrow {
    margin: 0px;
    line-height: 2;
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before, [class^="flaticon-"]:after, [class*=" flaticon-"]:after {
    font-family: Flaticon, sans-serif;
    font-size: 14px;
    font-style: normal;
    margin-left: 0px!important; 
    -webkit-font-smoothing: antialiased;
}
.heading-section-service > .main-heading{
    font-size: 24px;
    font-weight: 300;
    line-height: 1;
}
.heading-section-service > .sub-heading-text{
    font-size: 13px;
    color: #666;
}
.main-container{
    background-color: #fff;
    /* border: 1px solid #dedede; */
    border-radius: 8px;
    box-shadow: 0px 8px 24px 0px #0000000A, 0px 4px 4px 0px #00000014;
    margin-bottom: 2rem;
    overflow: hidden;
}
.head-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-bottom: 10px; */
}
.dropdown {
    width: 11%;
}
.main-container-head{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
}

.main-container-head-card{
    display: flex;
    justify-content: space-between;
    padding: 12px 24px 12px 12px;
    align-items: center;
    border: 2px solid transparent;
}


.main-card-head-hover:hover{
    border-color: #CACACA;
    border-radius: 8px;
}

.left-content{
    display: flex;
    align-items: center;
}
.avatar{ border-radius : 8px; padding:10px; height:50px; width:50px; position: relative; display:flex; justify-content:center; align-items: center; background-color:#5784c8; font-size:18px; font-weight:700; color:#fff;}
.avatar-size-40 {border-radius:5px ; height: 40px; width: 40px; font-size: 18px; font-weight: bold;}
.alignment{
    position: absolute;
    right: -7px!important;
    top: -7px!important;
}
.service-name{
    color: #0086ff;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;

}
.service-name a{
    color: #0086ff;
}
.service-name a:visited{
    color: #0086ff;
}

.service-name-card{
  color: #0086ff;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

.service-name-card a{
    color: #0086ff;
}
.service-name-card a:visited{
    color: #0086ff;
}

.text-icon-section{
    margin-left: 10px;
}
.owner-name{
    font-size: 12px;
    display: flex;
    align-items: center;
    line-height: 1;
    color: #c9c9c9;
}
.owner{
    color: #666;
    margin-left: .5rem;
}

.owner-new{
    color: #2f2f2f,
}
a.anchor{
    font-size: 12px;
    color: #0096db;
    display: inline-block;
    margin: 0px 5px;
}
.main-container-body{
    margin: 0rem 0rem 0rem;
}
.main-container-body .heading{
    font-size: 12px;
    color: #000;
    text-transform: uppercase;
}
.service-lising-detailed-card{
    border-radius: 4px;
    margin:0px 0px;
}
.service-lising-detailed-card.card{
    border: none;
    /* border-top: 1px solid #ebedee;  */
    border-radius: 0;
}
.service-listing ul.headerul.env-tabs li.active{
    border-top: 1px solid #ebedee;
    border-right: 1px solid #ebedee;
    border-left: 1px solid #ebedee;
}
.service-lising-detailed-card .headerul{
    background-color: #f6f6f6;
    border-radius: 4px 4px 0px 0px;
    border-top: 1px solid #ebedee;
    border-left: 1px solid #ebedee;
    border-right: 1px solid #ebedee;

}
.service-lising-detailed-card .headerul li.active{
    color: #124d9b!important;
    border-bottom-color: #124d9b;

}
.environment-card{
    background-color: #fbfbfb;
    border: 1px solid #dedede;
    box-shadow:rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px ;
    border-radius: 4px;
}
.environment-tab{
    background-color: #f6f6f6;
    padding: 0px 20px;
}
.environment-tab ul{
    list-style: none;
}
.environment-tab ul li{
    display: inline-block;
    padding: 15px;
    margin-right: 25px;
    font-size: 12px;
    position: relative;
    color: #666;
    line-height: 1;
    cursor: pointer;
    text-transform: uppercase;
}
.environment-tab ul li.active{
    border-bottom: 3px solid #0096db;
    color: #0096db;
}
.divider{
    height: 1px;
    width: 100%;
    background-color: #dedede;
}
.details-tab{
    
    
}


.details-label .label-env {
    border: none;
}
.details-label-service-card{
    display: grid;
    background-color: #fff;
    align-items: center;
    grid-template-areas:' option-1 option-2 option-3'; 
    padding: 10px 20px;
    border-bottom: 1px solid #ebedee;
    gap: 1%;
    grid-template-columns: 30% 30% 1fr;
}
.option-1 {
    grid-area: option-1;
}
.option-2 {
    grid-area: option-2;
}
.details-label{
    display: grid;
    background-color: #fff;
    align-items: center;
    grid-template-columns: 50% 50%;
    padding: 10px 20px;
    border-bottom: 1px solid #ebedee;
}
.label-env > div > .details-label{
    padding: 0px 20px;
    border: none;
    width: 160px;
}
.details-label{
    font-size: 12px;
}
.tab-label{
    color: #32383b;
    display: flex;
    align-items: center;
}
.tab-label-inner{
    margin-bottom: 1rem;
    font-size: 11px;
    color: #000;
    line-height: 1;
    display: flex;
    align-items: center;
}
.icon-with-text{
    display: flex;
    align-items: center;
    margin-left: 1rem;
}
.deatils-of{
    /* display: grid; */
    /* grid-template-areas:
    'build deployment monitoring'
    'buttons buttons buttons'
    'data data data'; */
    background-color: #fbfbfb;
    padding: 10px 10px 0px;
    border-radius: 0px 0px 4px 4px;
    /* gap: 1% 3%; */
    /* grid-template-columns: 50% 50% ; */
}

.deatils-of > div {
    justify-self: center; /* Center the div horizontally within the grid cell */
    align-self: center; /* Center the div vertically within the grid cell */
}

.deatils-of .actions{
    margin-left: -10px;
    margin-right: -10px;
    background-color: #fff;
    padding: 15px;
    border-top: 1px solid #ebedee;
}
.actions .card{
    border: 1px solid #efefef;
    border-radius: 4px;
}
.actions .card .card-header {
    height: auto;
    background-color: #fefefe;
    border-bottom: 1px solid #efefef;
}
.actions .card .card-body .card-header{
    border-bottom: none;
}
.revampedpopupcard{
    margin: 10px;
}
.revampedpopupcard .card{
    border: 1px solid #efefef;
    border-radius: 4px;
}
.revampedpopupcard .card .card-header {
    height: auto;
    background-color: #fefefe;
    border-bottom: 1px solid #efefef;
    padding-bottom: 10px;
    border-top: none;
}
.revampedpopupcard .card .card-body .card-header{
    border-bottom: none;
}
.build-section{grid-area: build;}
.deployment-section{grid-area: deployment ;}
.hardware-usage {grid-area:monitoring ;}
.popup-card{grid-area: data;}
.actions{grid-area: buttons;}
.chip-section p{
    font-size: 11px;
    color: #666;
}
.monitoring-box{
    margin-top: 20px;
}
.monitor-urls{
   display: flex;
}
.flaticon-work-briefcase{
    font-size: 15px;
    color: #0096db;
}
.monitor-urls >span{
    margin: 0px 10px;
}
.monitor-urls > .btn{
    margin: 0px 10px;
}
.text-section{
    margin: 10px 0px;
    line-height: 1;
}
.text-section a{
    font-size: 11px;
    color: #0096db;
    margin: 0px 3px 0px 3px;
}
.text-section .color-dark{
    font-weight: 400!important;
    margin-bottom: 0px;

}
.chip-default-service-listing {
    font-size: 11px;
    /* justify-content: center; */
    /* align-items: center; */
    display: inline-block;
    line-height: 1.5;
    border-radius: 25px;
    padding: 3px 8px;
    margin: 2px;
    text-align: center;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.13);
}
.build-section{
    padding: 10px 10px 0px;
    position: relative;
}
.build-section .text-section{
    margin: 5px 0px;
}
.color-dark{
    font-size: 11px;
    color: #454b54;
    line-height: 1;
    margin-bottom: .5rem;
}
.hardware-usage .progress-container{
    padding: 0px;
}
.progress-container {
    padding: 20px 0px;
    justify-content: start;
}
.progress-container > .progress:nth-child(2) .text-progress{
    
}
.float-section{
    display: flex;
    align-items: center;
    margin: 5px 0px;
}
.float-section div{
    margin-right: 5px ;
}
.MuiTooltip-tooltip{
    font-size: 10px!important;
    color: #fff!important;
    background-color: #000!important;
}
.color-lite{
    font-size: 11px;
    color: #a3a3a3!important;
    line-height: 1.2;
}
.color-lite span{
    word-break: break-all;
}
.triggred-by span{
    color: #000;
}
.triggred-by strong{
    color: #000;
}
.deployment-section{
    padding: 10px 10px 0px;
}
.hardware-usage{
    padding: 10px 10px 0px;
}
.actions{
    display: flex;
    align-items: flex-end;
    margin-top: 15px;
}
.actions .btn-small-icon:first-child{
margin-left: 0px!important;
}
.actions button{
    margin:0px 5px;
}
.btn-small-icon{
    background-color: #0095da;
    color: #fff;
    padding: 5px 10px;
    margin: 3px!important;
    border-radius: 4px;
    font-size: 11px;
}

.btn-small-icon [ class^="flaticon" ]{
    margin: 0px 4px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius:50% ;
    height: 20px;
    width: 20px;
    color: #0095da;
}
.btn-icon {
    background-color: #fff;
    border-radius:50% ;
    height: 20px;
    width: 20px;
    color: #0095da;
}
.btn-small-icon [ class^="flaticon" ]::before{
    margin: 0px!important;
    font-size: 12px!important;
}
.actions button:first-child{
    margin-left: 0px;
}
.overviewCard__body .actions{
    margin-top: 0px;
    padding-bottom: 10px;
}
.label-env{
    display: flex;
    align-items: center;
    width: 100%;
    height: 46px;
    border-bottom: 1px solid #ebedee;
    border-left: 1px solid #ebedee;
    border-right: 1px solid #ebedee;
}
.justify-bt{
    
    justify-content: space-between !important;
}
.label-env  select.select{
    border: none!important;
    box-shadow: none!important;
    width: 100px!important;
    height: 30px;
    padding: 0px 8px;
}
.label-env div{
    margin-bottom: 0px!important;
}
.label-env label span.flaticon-right-arrow-forward::before{
    font-size: 11px!important;
}
.label-env> label{
     font-size: 12px;
     color: #000;
}
.CircularProgressbar .CircularProgressbar-path {
    stroke-linecap: square!important;
}
/*
Progress bar
*/
.progress-bar {
    background-color: #f5f5f5;
    border-radius: 3px;
    box-shadow: none;
    position: relative;
  }
  .progress-bar.progress-xs {
    height: 5px;
    margin-top: 5px;
  }
  .progress-bar.progress-sm {
    height: 10px;
    margin-top: 5px;
  }
  .progress-bar.progress-lg {
    height: 25px;
  }
  .progress-bar.vertical {
    position: relative;
    width: 20px;
    height: 200px;
    display: inline-block;
    margin-right: 10px;
  }
  .progress-bar.vertical > .progress-bar__bar {
    width: 100% !important;
    position: absolute;
    bottom: 0;
  }
  .progress-bar.vertical.progress-xs {
    width: 5px;
    margin-top: 5px;
  }
  .progress-bar.vertical.progress-sm {
    width: 10px;
    margin-top: 5px;
  }
  .progress-bar.vertical.progress-lg {
    width: 30px;
  }
  
  .progress-bar__bar {
    background-color: #2196F3;
    box-shadow: none;
  }
  .progress-bar__bar.text-left {
    text-align: left;
  }
  .progress-bar__bar.text-left span {
    margin-left: 10px;
  }
  .progress-bar__bar.text-right {
    text-align: right;
  }
  .progress-bar__bar.text-right span {
    margin-right: 10px;
  }
  
  @-webkit-keyframes progress-bar-stripes {
    from {
      background-position: 40px 0;
    }
    to {
      background-position: 0 0;
    }
  }
  @keyframes progress-bar-stripes {
    from {
      background-position: 40px 0;
    }
    to {
      background-position: 0 0;
    }
  }
  .progress-bar.active .progress-bar__bar,
  .progress-bar__bar.active {
    -webkit-animation: progress-bar-stripes 2s linear infinite;
    -o-animation: progress-bar-stripes 2s linear infinite;
    animation: progress-bar-stripes 2s linear infinite;
  }
  
  .progress-striped .progress-bar__bar,
  .progress-bar-striped {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 40px 40px;
  }
  
  .progress-bar-danger {
    background-color: #ff8969;
  }
  
  .progress-bar-warning {
    background-color: #ffd62a;
  }
  
  .progress-bar-success {
    background-color: #69e09c;
  }
  .replication_preview{
      padding: 20px 0px 0px ;
      width: 100%;
  }
.replication__count{
    font-size: 11px;
    position: absolute;
    right: -40px;
    top: -2px;
    border: 1px solid #dedede;
    line-height: 1;
    padding: 5px 10px;
    margin-left: 10px;
    border-radius: 4px;
    
  }
  .border-for-headerul{
        background-color: #f6f6f6;
        border-top: 1px solid #e4e6e8;
        border-radius: 4px 4px 0px 0px;
        border-left: 1px solid #e4e6e8;
        border-right: 1px solid #e4e6e8;
  }
  .text-section-d-flex{
    display: flex;
    align-items: baseline;
    margin: 10px 0px;
  }
  .text-section-d-flex .color-lite{
        margin-left: 5px;
        line-height: normal;
  }
.popup-card {
    background-color: #fff;
    border-radius: 4px;
    bottom: 0;
    width: 100%;
    border: 1px solid #efefef;
    margin-top: 10px;
    height: auto;
    box-shadow: rgb(0 0 0 / 6%) 0.877px 1.798px 21px 0px;
}
  .popup-card-head{
    padding: 10px;
    height: 40px;
    border-bottom: 1px solid #dedede;
    position: relative;
    display: flex;
    align-items: center;
      
  }
    .popup-card-main-heading {
        font-size: 13px;
        color: #000;
        line-height: 1;
        justify-content: center;
        display: flex;
        align-items: center;
        font-weight: 400;
    }
 .popup-card-sub-heading{
     display: inline-block;
     line-height: 1;
 }
 .popup-card-head .service-name{
    margin: 0px;
    display: inline-block;
    margin-right: 10px;
    line-height: 1;
 }
 .bold-white{
     font-size: 11px;
     font-weight: 400;
     color: #fff;
     line-height: 1;
 }
 .lite-white{
     font-size: 11px;
     font-weight: 300;
     color: #fff;
     line-height: 1;
 }
 .progress-popup-card {
    padding:0;
    width:90%;
    height:8px;
    overflow:hidden;
    background:#e5e5e5;
    border-radius:6px;
  }
  .section-1{
      width: 50%;
  }
  .section-2 {
    align-self: center;
    margin-bottom: 2px;
}
.section-3 {
    align-self: center;
    margin-top: 8px;
}
  .section-popupcard-progressbar{
      width: 50%;
  }
  .bar-popup-card {
    position:relative;
    float:left;
    min-width:1%;
    height:100%;
    background:#69e09c;
  }
  
  .percent-popup-card {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    margin:0;
    font-size:12px;
    color:white;
  }
  .percentage-count{
      font-size: 13px;
      font-weight: bold;
      color: #fff;
  }
  .status-tag{
    width: 11rem;
    height: 3.5rem;
    display: flex;
    font-size: 11px;
    justify-content: center;
    align-items: center;
    line-height: 1;
    color: #fff;
    background-color: #ffd62a;
    border-radius: 36px;
  }
  .section-2 .flaticon-show-more-button-with-three-dots{
      color: #bcbccb;
  }
  .popup-card-body{
      background-image:#ccc;
      border-radius: 8px;
      /* padding: 20px 10px 20px; */
  }
  .popup-card-header{
      padding: 5px;
      background-color: #f4f4f4;
      font-size: 12px;
      text-transform: lowercase;
      border-bottom: 1px solid #dedede;
  }
  .section-two{
    padding: 0px 10px;
    margin: 0px auto;
  }
  .section-two-heading{
      font-size: 13px;
      font-weight: 500;
      color: #485974;
  }
  .round{
      width: 30px;
      height: 30px;
      border: 2px solid #ebedee;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
  }
  .stage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px;
}
span.stage-name {
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    color: #485974;
}
.border-color-red{

}
.section-one > div {
    margin-bottom: 0px!important;
    width: 130px;
}
.btn-build{
    padding: 8px 6px;
    width:90px;
    box-shadow: 1px 1px 5px 1px #ccc;
    color: #fff;
    background-color: #0095da;
    font-size: 12px;
    font-weight: 500;
    border: none;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-build span.flaticon-play-rounded-button {
    color: #fff;
    margin-right: 0px;
}
.btn-build span.flaticon-play-rounded-button:before, .flaticon-play-rounded-button:after {
    font-size: 15px!important;
}
.section-one{
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
}
.section-input{
    display: grid;
    grid-template-columns: 8% 38% 38% 10%;
    grid-gap: 10px;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}
.section-input-grid-5{
    display: grid;
    grid-template-columns: 7% 26% 26% 22% 10%;
    grid-gap: 10px;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}
.section-input-grid-5 .card-header{
    border: none;
    background-color: transparent;
    border-radius: 0px;
    width: 195px;
}
.section-input-temp-5 {
    display: grid;
    grid-template-columns: 8% 25% 25% 25% 9%;
    gap: 1%;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.section-input .input-component{
	margin-bottom: 0rem;
}
.section-input .input-component input{
    height: 40px;
    padding: 0px 14px;
}
.self-align-end{
    align-self: self-end;
}
.section-input .input-component .select {
    width: 100%;
    height: 40px;
    display: block;
    margin: 0px;
    line-height: 2;
    border-radius: 4px;
    border: 1px solid #b7b7b7;
    background-color: #fff;
    padding: 0px 14px;
    color: #5e5e5e;
    font-size: 12px;
    font-weight: 400;
}
.input-label-chip { 
    color: #828282;
font-size: 12px;
font-weight: 400;}

.hint-text-chip {
    color: #888;
    font-size: 10px;
    font-weight: 400;
    margin-left: .5rem;
}

.error-msg-service-card {
    font-size: 11px;
    font-weight: 400;
    margin: auto;
    border-radius: 5px;
    width: 100%;
    vertical-align: middle;
    text-align: center;
    border: 2px solid #f9a7a9;
    background-color: rgba(217, 83, 79, 0.1);
    color: red;
    line-height: 3;
    height: fit-content;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
}
.pd-tb{
    padding-top: 0px!important;
    padding-bottom: 0px!important;
}
.pd-bottom{
    padding-bottom: 0px!important;
}
.details-tab>.label-env select.select {
    /* border: none!important; */
    box-shadow: none!important;
    width: 120px!important;
    height: 30px;
    margin: 0px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #ccc!important;
    padding: 0px 8px;
}
.blank-div-button{
    font-size: 12px;
    color: #0086ff;
    display: inline-block;
    /* justify-content: center; */
    /* align-items: center; */
    text-align: center;
    border: 2px solid #0086ff;
    height: 35px;
    line-height: 2.5;
    width: 140px;
    margin: 0px 10px;
    border-radius: 4px;
}
.blank-div-button:hover{
    background-color: #0086ff;
    color: #fff;
    transition: ease-in-out 2ms;
}
.overview-no-env{
    display: contents;
    color:#666

}
.number-input-with-btn >  input[type="number"] {
    outline: none;
    -moz-appearance: textfield;
}
.number-input-with-btn input[type=number]::-webkit-inner-spin-button, 
.number-input-with-btn input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    appearance: none;
}
/*SearchBar*/
.search-for{
    
}
.main-box-search-for .gb_Lf {
    margin-right: 96px;
}
.main-box-search-for{
    height: 46px;
    padding: 0;
    margin-left: 56px;
    margin-right: 49px;
    overflow: hidden;
}
.service-lising-detailed-card .details-label{
    padding: 0px;
    grid-template-columns: 1fr 300px;
    align-items: flex-end;
}
.service-lising-detailed-card .details-label .sub-section{
    display: grid;
    grid-template-columns: 1fr 50px;
    align-items: center;
    margin-bottom: 8px;
}
.service-lising-detailed-card .details-label .sub-section .input-component {
    /* margin-bottom: 0px; */
}
.service-lising-detailed-card .details-label .headerul{
    background-color: #fff;
    border: none;
}
.display-serach-bar{
    display: grid;
    align-items: center;
    height: 84px;
    transition:  height .5s, padding-top .5s, padding-bottom .5s;
    overflow: hidden;
    grid-template-columns: 1fr 220px;
    gap: 20px;
    background: #f6f8f8;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
}
.hide-search-bar{
    height: 0px;
    transition:  height .5s, padding-top .5s, padding-bottom .5s;
    padding-top: 0;
    padding-bottom: 0;
}
.logs_search_bar .input-component{
    margin-bottom: 0px;
}
.logs_search_bar .input-component input{
    background-color: #eceeee;
    border: none;
    outline: none;
}
.logs_search_bar .input-component input::placeholder{
    color: #aaacac!important;
    font-size: 14px;
}
.logs_search_bar .input-component input:focus{
    border: none!important;
    outline: none!important;
}
.flip-card{
    transition: transform 0.8s;
    transform-style: preserve-3d;
}
.flipped-screen{
    transition: transform 0.8s;
    /* transform-style: preserve-3d; */
    transform: rotateY(180deg);
    transform-style: preserve-3d;
}
.flipped-screen-logsview{
    color: #cdcdcd;
    width: 100%;
    height: 75vh;
    display: grid;
    overflow: auto;
    position: relative;
    font-size: 12px;
    background-color: #2b2b2b;
    grid-template-columns: 50px 1fr;
}
.flipped-screen-logsview::-webkit-scrollbar{
    width: .8em!important;
    height: 3px
}
.left-block-flipped {
    background-color: #3f4b4e;
    border-right: 10px solid #343f41;
    color: #668189;
    text-align: center
}
.logs-para-flipped {
    height: 75vh;
    overflow: scroll;
    width: 100%
}
.right-block-flipped{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%
}
.grid-temp-log-line-flipped{
    grid-template-columns: 50px 1fr
}
.greish-flipped{
    background-color: #3f4b4e;
    border-right: 10px solid #343f41;
    color: #cdcdcd;
    text-align: center;
    width: 50px
}
.flipped-screen-header{
    height: 85px;
    display: flex;
    align-items: center;
}
.btn-close-icon{
    font-size: 42px;
    color: #5e5e5e;
    font-weight: 100;
}


.error-msg-403 {
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    vertical-align: middle;
    height: 150px !important;
    text-align: center;
    border: 2px solid #f9a7a9;
    background-color: rgba(217, 83, 79, 0.1);
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
}

.error-msg-403 .error-msg{
    font-size: 11px;
    font-weight: 400;
    margin: auto;
    color: red;
    line-height: 3;

}