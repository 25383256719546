
ul.headerul{
    list-style: none;
    display: flex;
    white-space: nowrap;
    align-items: center;
}
ul.headerul li{
    padding: 15px 20px;
    font-size: 12px;
    color: #666;
    line-height: 1;
    text-transform: uppercase;
}

ul.appheaderul li{
    padding: 15px 10px;
    font-size: 12px;
    color: #666;
    line-height: 1;
    text-transform: uppercase;
}

ul.headerul li.active{
    color: #0096db;
    border-bottom: 3px solid #0086ff;
}

ul.capsuleTab{
    background-color: #fff;
    border-radius: 30px;
    padding: 5px 7px;
    filter: drop-shadow(0.877px 1.798px 13.5px rgba(0,0,0,0.05));
}
ul.capsuleTab li{
    color: #000;
    padding: 10px 20px !important;
}
ul.capsuleTab li.active{
    background-color: #0096db;
    border-radius: 30px;
    color: #fff;
    border-bottom: none;
}

.progress-container{
    padding: 20px 10px;
    justify-content: center;
}
.MuiCheckbox-root{
    color: #0086ff!important;
}
.sonar .varient-two-input{
    grid-template-columns: 30% 30%!important;
}
